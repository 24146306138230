import {
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  AddIcon,
  CalendarIcon,
  EditIcon,
  HamburgerIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { filter, flatMap, groupBy, values } from "lodash";

type LayoutProps = {
  children: React.ReactNode;
};

type LookupType = {
  path: string;
  title: string;
  menuRelevant: boolean;
  group: string;
  icon?: React.ReactElement;
};

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const navigate = useNavigate();
  const titleLookup: LookupType[] = [
    {
      path: "/plan",
      title: "Ernährungsplan",
      menuRelevant: true,
      icon: <CalendarIcon />,
      group: "Ernährungsplan",
    },
    {
      path: "/food/edit/\\d+",
      title: "Lebensmittel bearbeiten",
      menuRelevant: false,
      group: "Essen",
    },
    {
      path: "/purchase/create/\\d+",
      title: "Produkt erstellen",
      menuRelevant: false,
      group: "Essen",
    },
    {
      path: "/purchase/edit/\\d+",
      title: "Produkt bearbeiten",
      menuRelevant: false,
      group: "Essen",
    },
    {
      path: "/dish/edit/\\d+",
      title: "Gericht bearbeiten",
      menuRelevant: false,
      group: "Essen",
    },
    {
      path: "/food",
      title: "Lebensmittel suchen",
      menuRelevant: true,
      icon: <SearchIcon />,
      group: "Essen",
    },
    {
      path: "/food/create",
      title: "Lebensmittel erstellen",
      menuRelevant: true,
      icon: <AddIcon />,
      group: "Essen",
    },
    {
      path: "/purchase",
      title: "Produkt suchen",
      menuRelevant: true,
      icon: <SearchIcon />,
      group: "Essen",
    },
    {
      path: "/dish",
      title: "Gericht suchen",
      menuRelevant: true,
      icon: <SearchIcon />,
      group: "Essen",
    },
    {
      path: "/dish/create",
      title: "Gericht erstellen",
      menuRelevant: true,
      icon: <AddIcon />,
      group: "Essen",
    },
    {
      path: "/person/edit/\\d+",
      title: "Person bearbeiten",
      menuRelevant: false,
      group: "Person",
    },
    {
      path: "/",
      title: "Startseite",
      menuRelevant: false,
      group: "Startseite",
    },

    {
      path: "/shop",
      title: "Geschäfte",
      menuRelevant: true,
      icon: <EditIcon />,
      group: "Einkaufen",
    },
    {
      path: "/department",
      title: "Abteilungen",
      menuRelevant: true,
      icon: <EditIcon />,
      group: "Einkaufen",
    },
    {
      path: "/shopping-list/create",
      title: "Einkaufsliste erstellen",
      menuRelevant: true,
      icon: <AddIcon />,
      group: "Einkaufen",
    },
    {
      path: "/shopping-list",
      title: "Einkaufsliste",
      menuRelevant: true,
      icon: <EditIcon />,
      group: "Einkaufen",
    },
    {
      path: "/need",
      title: "Bedarf bearbeiten",
      menuRelevant: true,
      icon: <EditIcon />,
      group: "Person",
    },
    {
      path: "/person",
      title: "Person suchen",
      menuRelevant: true,
      icon: <SearchIcon />,
      group: "Person",
    },
    {
      path: "/person/create",
      title: "Person erstellen",
      menuRelevant: true,
      icon: <AddIcon />,
      group: "Person",
    },
  ];

  const location = useLocation();

  const title = titleLookup.find((o) =>
    location.pathname.match(new RegExp("^" + o.path + "$")),
  )?.title;

  return (
    <>
      <header
        style={{
          position: "sticky",
          zIndex: "5",
          top: 0,
          backgroundColor: "var(--chakra-colors-chakra-body-bg)",
        }}
      >
        <HStack>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant={"ghost"}
            />
            <MenuList>
              {flatMap(
                values(
                  groupBy(
                    filter(titleLookup, (t) => t.menuRelevant),
                    (t) => t.group,
                  ),
                ),
                (tList, index) => {
                  return (
                    <React.Fragment key={index}>
                      {index > 0 && <MenuDivider />}
                      {tList.map((t) => (
                        <MenuItem
                          key={t.path}
                          onClick={() => navigate(t.path)}
                          icon={t.icon}
                        >
                          {t.title}
                        </MenuItem>
                      ))}
                    </React.Fragment>
                  );
                },
              )}
            </MenuList>
          </Menu>
          <Heading size={"md"}>{title}</Heading>
        </HStack>
      </header>
      {props.children}
    </>
  );
};
