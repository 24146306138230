import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Link,
  Tag,
  TagProps,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useImmer } from "use-immer";
import {
  addDays,
  formatIsoString,
  parseDate,
  todayForPlan,
} from "../utils/date-utils";
import { PersonLookup, PurchaseLookup } from "../data/lookups";
import { compareMealInds, mealInds } from "../utils/meal-ind-utils";
import { Intake } from "../data/intake";
import { flatMap, groupBy, keys, values } from "lodash";
import {
  JustIntake,
  MealWithIntakes,
  Plan,
  PlanCell,
  PlanCellCoordinates,
  PlanCellWithIndex,
  PlanModification,
} from "../data/plan";
import {
  computePlanCellCoordinates,
  computePlanCellCoordinatesFromPlanCellWithIndex,
  formatPlanCellDescription,
  getMealKeyForMeal,
  isNewCell,
  isNewOrEdited,
  isNullIndex,
  mapPlanCellToNutriValues,
  MealKey,
} from "../utils/plan-utils";
import { MealWithIngredients } from "../data/meal-with-ingredients";
import { Person } from "../data/person";
import {
  AvailabilityByPurchaseIdLookupContext,
  DepartmentLookupContext,
  DrawerHistoryContext,
  FoodLookupContext,
  FoodLookupWithRefreshContext,
  MealPlannerContext,
  MealPlannerContextType,
  PersonDishByDishLookupContext,
  PersonLookupContext,
  PurchaseLookupContext,
  PurchaseLookupWithRefreshContext,
  ShopLookupWithRefreshContext,
} from "../contexts";
import { CookingDrawer, useCookingDrawer } from "../components/cooking-drawer";
import { isDeleted, isEdited } from "../utils/edit-utils";
import { getNeedForDate } from "../utils/person-utils";
import { eachDayOfInterval } from "date-fns";
import { wrapSave } from "../components/buttons/save-button";
import { axiosInstance } from "../keycloak";
import { LoadingComponent } from "../components/loading-component";
import { useUnloadAlert } from "../components/use-unload-alert";
import { AddPlanCellDrawer } from "../components/add-plan-cell-drawer";
import {
  useAvailabilityByPurchaseIdLookupLoader,
  useDepartmentLoader,
  useFoodLookupLoader,
  usePersonDishLookupLoader,
  usePurchaseLookupLoader,
  useShopLookupLoader,
} from "../loaders";
import { deepEquals } from "../utils/equal-utils";
import { EditPlanCellDrawer } from "../components/edit-plan-cell-drawer";
import { EditMealDrawer } from "../components/edit-meal-drawer";
import { Plan2PlateIconButton } from "../components/buttons/plan2-plate-icon-button";
import { AllNutriValues } from "../components/all-nutri-values";
import { FloatingButton } from "../components/buttons/floating-button";
import { usePlan2PlateDisclosure } from "../components/plan2-plate-drawer";
import { useDrawerHistory } from "../components/drawer-history";
import { SettingsDrawer } from "../components/settings-drawer";

interface TableData {
  [day: string]: {
    [meal: string]: { [personId: number]: PlanCell[] };
  };
}

function getPlanCell(
  tableData: TableData,
  planCellCoordinates: PlanCellCoordinates,
) {
  if (planCellCoordinates.index == undefined) {
    throw new Error("Index für Lookup benötigt");
  }
  return getPlanCells(tableData, planCellCoordinates)[
    planCellCoordinates.index
  ];
}

function setPlanCell(
  tableData: TableData,
  planCellCoordinates: PlanCellCoordinates,
  planCell: PlanCell,
) {
  if (planCellCoordinates.index == undefined) {
    throw new Error("Index für Lookup benötigt");
  }
  getPlanCells(tableData, planCellCoordinates)[planCellCoordinates.index] =
    planCell;
}

function getPlanCells(
  tableData: TableData,
  { date, mealInd, personId }: PlanCellCoordinates,
) {
  if (!tableData[date]) {
    return [];
  }
  return tableData[date][mealInd][personId];
}

function getPlanCellsForDateAndMeal(
  tableData: TableData,
  date: string,
  mealInd: string,
) {
  return flatMap(tableData[date][mealInd], (forInd) => values(forInd));
}

interface Props {}

function isPresentOrFutureDate(date: string) {
  return todayForPlan() <= parseDate(date);
}

function PlanCellTag(props: {
  planCell: PlanCell;
  mealIndex: number;
  purchaseLookup: PurchaseLookup;
  onClick: TagProps["onClick"];
}) {
  const description = formatPlanCellDescription(
    props.planCell,
    props.purchaseLookup,
  );
  const isWithoutIntakes =
    props.planCell.indicator == "MealWithIntakes" && !props.planCell.intakes;
  const color = isNewOrEdited(props.planCell)
    ? "#ffc857"
    : isWithoutIntakes
      ? "initial"
      : undefined;
  const textDecoration = isDeleted(props.planCell) ? "line-through" : undefined;
  const tagColorWithoutIntakes = useColorModeValue("#6e6c6c", "#b5b5b5");
  const border = isWithoutIntakes
    ? "1px dashed " + tagColorWithoutIntakes
    : undefined;
  const textColor = isWithoutIntakes ? tagColorWithoutIntakes : "initial";
  return (
    <Tag
      height={"fit-content"}
      marginRight={"5px"}
      marginBottom={"5px"}
      backgroundColor={color}
      textDecoration={textDecoration}
      onClick={props.onClick}
      border={border}
      color={textColor}
    >
      {description}
    </Tag>
  );
}

const MealTable: React.FC<Props> = () => {
  const [startDate, setStartDate] = useState(todayForPlan());
  const [endDate, setEndDate] = useState(addDays(todayForPlan(), 0));

  const relevantDates = useMemo(
    () =>
      endDate.getTime() < startDate.getTime()
        ? []
        : eachDayOfInterval({
            start: startDate,
            end: endDate,
          }).map(formatIsoString),
    [startDate, endDate],
  );
  const [personLookup, updatePersonLookup] = useImmer<PersonLookup>({});

  const [tableData, updateTableData] = useImmer<TableData>({});
  const [hasNewData, updateHasNewData] = useImmer<boolean>(false);
  const [shouldReload, updateShouldReload] = useImmer<boolean>(true);
  const [shouldReloadBaseData, updateShouldReloadBaseData] =
    useImmer<boolean>(true);

  const { pristine, setPristine } = useUnloadAlert();

  useEffect(() => {
    setPristine(!hasNewData);
  }, [hasNewData]);

  const toast = useToast();

  const scrollPosition = useRef(0);
  const { availabilityByPurchaseIdLookup, loadAvailabilities } =
    useAvailabilityByPurchaseIdLookupLoader();
  const { foodLookup, loadFood } = useFoodLookupLoader();
  const { purchaseLookup, loadPurchase } = usePurchaseLookupLoader();
  const { shopLookup, loadShop } = useShopLookupLoader();
  const { departmentLookup, loadDepartment } = useDepartmentLoader();
  const { loadPersonDishByDish, personDishByDishLookup, personDishLookup } =
    usePersonDishLookupLoader();

  const loadBaseData = useCallback(async () => {
    if (!shouldReloadBaseData) {
      return;
    }
    await loadAvailabilities();
    await loadFood();
    await loadPurchase();
    await loadShop();
    await loadDepartment();
    await loadPersonDishByDish();
    updateShouldReloadBaseData(false);
  }, [shouldReloadBaseData]);

  const loadTableData = useCallback(async () => {
    if (!shouldReload) {
      return;
    }
    try {
      let mealData: MealWithIngredients[];
      let intakeData: Intake[];
      if (!relevantDates.length) {
        mealData = [];
        intakeData = [];
      } else {
        mealData = (
          await axiosInstance.get<MealWithIngredients[]>(
            `/api/meals?startDate=${relevantDates[0]}&endDate=${relevantDates[relevantDates.length - 1]}`,
          )
        ).data;

        intakeData = (
          await axiosInstance.get<Intake[]>(
            `/api/intakes?startDate=${relevantDates[0]}&endDate=${relevantDates[relevantDates.length - 1]}`,
          )
        ).data;
      }

      const persons = (await axiosInstance.get<Person[]>(`/api/person`)).data;

      const intakeByMeal = groupBy(intakeData, "mealId");

      const personLookupNew: typeof personLookup = {};

      persons.forEach((person) => {
        personLookupNew[person.id] = person;
      });

      const newData: TableData = {};
      for (const relevantDate of relevantDates) {
        newData[relevantDate] = {};
        for (const mealInd of keys(mealInds)) {
          newData[relevantDate][mealInd] = {};
          for (const personIdAsString of keys(personLookupNew)) {
            const personId = Number(personIdAsString);
            newData[relevantDate][mealInd][personId] = [];
          }
        }
      }

      mealData.forEach((meal) => {
        const intakesForMeal = intakeByMeal[meal.id];
        const { mealDate, mealInd, personId } = meal;
        const mealWithIntake: MealWithIntakes = {
          indicator: "MealWithIntakes",
          meal: meal,
          intakes: intakesForMeal,
        };
        newData[mealDate][mealInd][personId].push(mealWithIntake);
      });
      intakeData
        .filter((intake) => intake.mealId == null)
        .forEach((intake) => {
          const { intakeDate, mealInd, personId } = intake;
          const unplannedIntake: JustIntake = {
            indicator: "JustIntake",
            ...intake,
          };
          newData[intakeDate][mealInd][personId].push(unplannedIntake);
        });

      updatePersonLookup(personLookupNew);
      updateTableData(newData);
      updateShouldReloadBaseData(false);
      updateShouldReload(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }, [
    updatePersonLookup,
    updateTableData,
    updateShouldReload,
    shouldReload,
    relevantDates,
  ]);

  function loadPreviousDay() {
    updateShouldReload(true);
    setStartDate(addDays(startDate, -1));
    setEndDate(addDays(endDate, -1));
  }

  function loadNextDay() {
    updateShouldReload(true);
    setStartDate(addDays(startDate, 1));
    setEndDate(addDays(endDate, 1));
  }

  const onConsume = (
    date: string,
    mealInd: string,
    mealKey: MealKey | undefined,
    personIdParam: number | undefined,
  ) => {
    const planCellsWithIndex: PlanCellWithIndex[] = flatMap(
      keys(personLookup),
      (personId) => {
        const planCellsForCoords = getPlanCells(tableData, {
          date,
          mealInd,
          personId: Number(personId),
        });
        return planCellsForCoords
          .map((planCell, index) => {
            return {
              planCell,
              index,
            };
          })
          .filter((pC) => {
            const coordinates =
              computePlanCellCoordinatesFromPlanCellWithIndex(pC);
            if (pC.planCell.indicator != "MealWithIntakes") {
              return (
                personIdParam == undefined ||
                coordinates.personId == personIdParam
              );
            }
            const meal = pC.planCell.meal;
            return (
              (mealKey == undefined ||
                deepEquals(getMealKeyForMeal(meal), mealKey)) &&
              (personIdParam == undefined || meal.personId == personIdParam)
            );
          });
      },
    );
    setSelectedPlanCells(planCellsWithIndex);
    onOpenEditMeal();
  };
  const drawerHistory = useDrawerHistory();

  const cookingDialogProps = useCookingDrawer(
    (date, meal, mealKey) => onConsume(date, meal, mealKey, undefined),
    drawerHistory,
  );

  useEffect(() => {
    function handleScroll() {
      scrollPosition.current = window.scrollY;
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const savePlan = async () => {
    const planCells = flatMap(
      flatMap(
        flatMap(values(tableData), (x) => values(x)),
        (x) => values(x),
      ),
      (x) => values(x),
    );

    const mealWithIntakes = planCells.filter(
      (planCell) => planCell.indicator == "MealWithIntakes",
    ) as MealWithIntakes[];
    const intakesForMeals = flatMap(
      mealWithIntakes,
      (planCell) => planCell.intakes || [],
    );
    const justIntakes = planCells.filter(
      (planCell) => planCell.indicator == "JustIntake",
    ) as JustIntake[];
    await axiosInstance.post<any, Plan, PlanModification>(`/api/plan`, {
      createdIntakes: [
        ...intakesForMeals.filter((intakeWithFlags) =>
          isNullIndex(intakeWithFlags.id),
        ),
        ...justIntakes.filter((planCell) => isNewCell(planCell)),
      ],
      deletedIntakes: [
        ...intakesForMeals
          .filter((intakeWithFlags) => isDeleted(intakeWithFlags))
          .map((intakeWithFlags) => intakeWithFlags),
        ...flatMap(
          mealWithIntakes.filter((planCell) => isDeleted(planCell)),
          (p) => p.intakes || [],
        ),
        ...justIntakes.filter((planCell) => isDeleted(planCell)),
      ],
      updatedIntakes: [
        ...intakesForMeals
          .filter((intakeWithFlags) => isEdited(intakeWithFlags))
          .filter((intakeWithFlags) => !isNullIndex(intakeWithFlags.id)),
        ...justIntakes.filter((planCell) => isEdited(planCell)),
      ],
      deletedMeals: mealWithIntakes
        .filter((planCell) => isDeleted(planCell))
        .map((p) => p.meal),
      updatedMeals: mealWithIntakes
        .filter((planCell) => isEdited(planCell))
        .map((p) => p.meal),
      createdMeals: mealWithIntakes
        .filter((planCell) => isNewCell(planCell))
        .map((p) => p.meal),
    });
    updateShouldReload(true);
    await loadTableData();
    updateHasNewData(false);
    return true;
  };

  const [planCellCoordinatesAdd, setPlanCellCoordinatesAdd] =
    useState<PlanCellCoordinates>();

  function handleClickMeal(planCell: PlanCell, planCellIndex: number) {
    setSelectedPlanCells([{ planCell, index: planCellIndex }]);
    onOpenEditPlanCell();
  }

  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const submitPlanCells = (planCells: PlanCellWithIndex[]) => {
    for (const planCell of planCells) {
      updateHasNewData(true);
      updateTableData((tableData) => {
        const planCellCoordinates =
          computePlanCellCoordinatesFromPlanCellWithIndex(planCell);
        if (!isNullIndex(planCell.index)) {
          if (isDeleted(planCell.planCell) && isNewCell(planCell.planCell)) {
            getPlanCells(tableData, planCellCoordinates).splice(
              planCell.index,
              1,
            );
            return;
          }
          const planCellFromData = getPlanCell(tableData, planCellCoordinates);
          const equal = deepEquals(planCellFromData, planCell.planCell);
          if (!equal) {
            setPlanCell(tableData, planCellCoordinates, {
              ...planCell.planCell,
              edited: true,
            });
          }
        } else {
          if (!isDeleted(planCell.planCell)) {
            getPlanCells(tableData, planCellCoordinates).push(
              planCell.planCell,
            );
          }
        }
      });
    }
  };

  const getTableDataForCoordinates = (coords: PlanCellCoordinates) => {
    return coords.index
      ? [getPlanCell(tableData, coords)]
      : getPlanCells(tableData, coords);
  };

  const mealPlannerContext: MealPlannerContextType = {
    submitPlanCells,
    onConsume,
    getTableDataForCoordinates,
  };
  const {
    isOpen: isOpenAddPlanCell,
    onOpen: onOpenAddPlanCell,
    onClose: onCloseAddPlanCell,
  } = usePlan2PlateDisclosure(drawerHistory, "add-plan-cell");
  const {
    isOpen: isOpenEditPlanCell,
    onOpen: onOpenEditPlanCell,
    onClose: onCloseEditPlanCell,
  } = usePlan2PlateDisclosure(drawerHistory, "edit-plan-cell");
  const {
    isOpen: isOpenEditMeal,
    onOpen: onOpenEditMeal,
    onClose: onCloseEditMeal,
  } = usePlan2PlateDisclosure(drawerHistory, "edit-meal");
  const {
    isOpen: isOpenSettings,
    onOpen: onOpenSettings,
    onClose: onCloseSettings,
  } = usePlan2PlateDisclosure(drawerHistory, "settings");
  const [selectedPlanCells, setSelectedPlanCells] =
    useState<PlanCellWithIndex[]>();

  return (
    <LoadingComponent
      loadData={[loadBaseData, loadTableData]}
      shouldReload={shouldReload}
    >
      <AvailabilityByPurchaseIdLookupContext.Provider
        value={{ availabilityByPurchaseIdLookup, loadAvailabilities }}
      >
        <MealPlannerContext.Provider value={mealPlannerContext}>
          <DrawerHistoryContext.Provider value={drawerHistory}>
            <FoodLookupContext.Provider value={foodLookup}>
              <FoodLookupWithRefreshContext.Provider
                value={{ foodLookup, loadFood }}
              >
                <ShopLookupWithRefreshContext.Provider
                  value={{ loadShop, shopLookup }}
                >
                  <DepartmentLookupContext.Provider value={departmentLookup}>
                    <PurchaseLookupContext.Provider value={purchaseLookup}>
                      <PurchaseLookupWithRefreshContext.Provider
                        value={{ loadPurchase, purchaseLookup }}
                      >
                        <PersonLookupContext.Provider value={personLookup}>
                          <PersonDishByDishLookupContext.Provider
                            value={{
                              loadPersonDishByDish,
                              personDishByDishLookup,
                              personDishLookup,
                            }}
                          >
                            <>
                              <CookingDrawer {...cookingDialogProps} />
                              <AddPlanCellDrawer
                                onClose={onCloseAddPlanCell}
                                open={isOpenAddPlanCell}
                                planCellCoordinates={planCellCoordinatesAdd}
                              />
                              <EditPlanCellDrawer
                                onClose={onCloseEditPlanCell}
                                open={isOpenEditPlanCell}
                                planCell={(selectedPlanCells || [])[0]}
                              />
                              <EditMealDrawer
                                onClose={onCloseEditMeal}
                                onSubmit={() => {}}
                                open={isOpenEditMeal}
                                planCells={selectedPlanCells}
                                mode={"intake"}
                              />
                              <SettingsDrawer
                                open={isOpenSettings}
                                onClose={onCloseSettings}
                              />
                              <Flex width="100%" justifyContent={"start"}>
                                {(hasNewData || saveIsLoading) && (
                                  <FloatingButton
                                    leftIcon={"check"}
                                    position={"fixed"}
                                    isLoading={saveIsLoading}
                                    zIndex={"1"}
                                    onClick={() => {
                                      wrapSave(
                                        savePlan,
                                        toast,
                                        setSaveIsLoading,
                                      );
                                    }}
                                    caption={"Speichern"}
                                  />
                                )}
                                <HStack
                                  justifyContent={"end"}
                                  backgroundColor={
                                    "var(--chakra-colors-chakra-body-bg)"
                                  }
                                  zIndex={1}
                                  position={"fixed"}
                                  className="stretch-stack-with-padding"
                                  paddingY={"0px"}
                                  left={0}
                                  top={"40px"}
                                >
                                  <Plan2PlateIconButton
                                    aria-label={"gear"}
                                    title={"Einstellungen"}
                                    icon={"gear"}
                                    onClick={() => {
                                      onOpenSettings();
                                    }}
                                  />
                                  <Plan2PlateIconButton
                                    aria-label={"back"}
                                    title={"Früher"}
                                    icon={"back"}
                                    onClick={loadPreviousDay}
                                  />
                                  <Plan2PlateIconButton
                                    aria-label={"calendarMode"}
                                    title={
                                      startDate.getTime() == endDate.getTime()
                                        ? "Wochenansicht"
                                        : "Tagesansicht"
                                    }
                                    icon={
                                      startDate.getTime() == endDate.getTime()
                                        ? "calendar"
                                        : "day"
                                    }
                                    onClick={() => {
                                      if (
                                        startDate.getTime() == endDate.getTime()
                                      ) {
                                        setEndDate(addDays(startDate, 7));
                                        updateShouldReload(true);
                                      } else {
                                        setEndDate(startDate);
                                        updateShouldReload(true);
                                      }
                                    }}
                                  />
                                  <Plan2PlateIconButton
                                    aria-label={"today"}
                                    title={"Heute"}
                                    icon={"today"}
                                    onClick={() => {
                                      if (
                                        startDate.getTime() == endDate.getTime()
                                      ) {
                                        setStartDate(todayForPlan());
                                        setEndDate(todayForPlan());
                                        updateShouldReload(true);
                                      } else {
                                        setStartDate(todayForPlan());
                                        setEndDate(addDays(todayForPlan(), 7));
                                        updateShouldReload(true);
                                      }
                                      window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                      });
                                    }}
                                  />
                                  <Plan2PlateIconButton
                                    aria-label={"forward"}
                                    title={"Später"}
                                    icon={"forward"}
                                    onClick={loadNextDay}
                                  />
                                </HStack>
                                <HStack
                                  paddingY={"10px"}
                                  position={"fixed"}
                                  top={"80px"}
                                  backgroundColor={
                                    "var(--chakra-colors-chakra-body-bg)"
                                  }
                                  zIndex={1}
                                  className="stretch-stack-with-padding"
                                  left={0}
                                  paddingLeft={"47px"}
                                  paddingRight={"32px"}
                                >
                                  {values(personLookup).map((person, index) => (
                                    <React.Fragment key={person.id}>
                                      {index > 0 && (
                                        <Divider
                                          key={"divider-" + person.id}
                                          borderColor="lightgray"
                                          borderWidth="1px"
                                          orientation={"vertical"}
                                          height={"initial"}
                                        />
                                      )}
                                      <Link
                                        fontSize={"large"}
                                        key={"link-" + person.id}
                                        width={"100%"}
                                        textAlign={"center"}
                                        href={`/person/edit/${person.id}`}
                                        height={"100%"}
                                      >
                                        {person.name}
                                      </Link>
                                    </React.Fragment>
                                  ))}
                                </HStack>
                                <VStack
                                  className="stretch-stack-with-padding"
                                  paddingTop={"0px"}
                                >
                                  <VStack>
                                    {keys(tableData)
                                      .sort()
                                      .map((date, dateIndex) => {
                                        const dateParsed = parseDate(date);
                                        const mealsForDate = tableData[date];
                                        return (
                                          <VStack
                                            marginLeft={"15px"}
                                            width={"100%"}
                                            alignItems={"stretch"}
                                            key={date}
                                          >
                                            <HStack
                                              paddingBottom={"10px"}
                                              position={"sticky"}
                                              top={"40px"}
                                              backgroundColor={
                                                "var(--chakra-colors-chakra-body-bg)"
                                              }
                                              zIndex={1}
                                              width={"fit-content"}
                                            >
                                              <Box width={"30px"}>
                                                <Text fontSize={"x-large"}>
                                                  {dateParsed.getDate()}
                                                </Text>
                                                <Text>
                                                  {new Intl.DateTimeFormat(
                                                    "de-DE",
                                                    {
                                                      weekday: "short",
                                                    },
                                                  ).format(dateParsed)}
                                                </Text>
                                              </Box>
                                            </HStack>
                                            <Box
                                              className={
                                                "relative-editor-box-without-shadow"
                                              }
                                              key={date}
                                            >
                                              <HStack alignItems={"start"}>
                                                <VStack width={"100%"}>
                                                  <HStack
                                                    alignItems={"stretch"}
                                                    width={"100%"}
                                                  ></HStack>
                                                  {keys(mealsForDate)
                                                    .sort(compareMealInds)
                                                    .map((mealInd) => {
                                                      const mealsForInd =
                                                        mealsForDate[mealInd];
                                                      return (
                                                        <Box
                                                          className="relative-flex-editor-box"
                                                          boxShadow={"sm"}
                                                          paddingBottom={"5px"}
                                                          key={mealInd}
                                                        >
                                                          <Tooltip
                                                            label={`${mealInds[mealInd].name}-Kochdialog`}
                                                            placement="bottom"
                                                          >
                                                            <Plan2PlateIconButton
                                                              position={
                                                                "absolute"
                                                              }
                                                              top={"-16px"}
                                                              left={"-20px"}
                                                              aria-label={
                                                                "cookingdialog"
                                                              }
                                                              title={
                                                                "Kochdialog"
                                                              }
                                                              variant={"ghost"}
                                                              icon={
                                                                mealInds[
                                                                  mealInd
                                                                ].icon
                                                              }
                                                              onClick={() => {
                                                                const meals =
                                                                  getPlanCellsForDateAndMeal(
                                                                    tableData,
                                                                    date,
                                                                    mealInd,
                                                                  ).filter(
                                                                    (
                                                                      planCell,
                                                                    ) =>
                                                                      planCell.indicator ==
                                                                      "MealWithIntakes",
                                                                  ) as MealWithIntakes[];
                                                                const withoutIntake =
                                                                  meals.filter(
                                                                    (meal) =>
                                                                      meal.intakes ==
                                                                      undefined,
                                                                  );
                                                                const nonEmptyMeals =
                                                                  withoutIntake.filter(
                                                                    (meal) =>
                                                                      meal.meal
                                                                        .mealIngredients
                                                                        .length >
                                                                      0,
                                                                  );
                                                                if (
                                                                  nonEmptyMeals.length >
                                                                  0
                                                                ) {
                                                                  const unsavedMeals =
                                                                    nonEmptyMeals.filter(
                                                                      (m) =>
                                                                        isNewCell(
                                                                          m,
                                                                        ),
                                                                    );
                                                                  if (
                                                                    unsavedMeals.length >
                                                                    0
                                                                  ) {
                                                                    toast({
                                                                      title:
                                                                        "Ungespeicherte Gerichte",
                                                                      description:
                                                                        "Es gibt ungespeicherte Gerichte in diesem Zeitraum",
                                                                      status:
                                                                        "info",
                                                                      isClosable:
                                                                        true,
                                                                    });
                                                                  } else {
                                                                    cookingDialogProps.cook(
                                                                      nonEmptyMeals,
                                                                    );
                                                                  }
                                                                } else {
                                                                  toast({
                                                                    title:
                                                                      "Keine Gerichte",
                                                                    description:
                                                                      "Es gibt keine zu kochenden Gerichte für diesen Zeitraum",
                                                                    status:
                                                                      "info",
                                                                    isClosable:
                                                                      true,
                                                                  });
                                                                }
                                                              }}
                                                            />
                                                          </Tooltip>
                                                          <HStack
                                                            flex={1}
                                                            marginTop={"8px"}
                                                          >
                                                            {keys(
                                                              personLookup,
                                                            ).map(
                                                              (
                                                                personIdAsString,
                                                                index,
                                                              ) => {
                                                                const personId =
                                                                  Number(
                                                                    personIdAsString,
                                                                  );
                                                                return (
                                                                  <React.Fragment
                                                                    key={
                                                                      personIdAsString
                                                                    }
                                                                  >
                                                                    {index >
                                                                      0 && (
                                                                      <Divider
                                                                        key={
                                                                          "divider-" +
                                                                          personIdAsString
                                                                        }
                                                                        borderColor="lightgray"
                                                                        borderWidth="1px"
                                                                        orientation={
                                                                          "vertical"
                                                                        }
                                                                      />
                                                                    )}
                                                                    <VStack
                                                                      key={
                                                                        "stack-" +
                                                                        personIdAsString
                                                                      }
                                                                      height={
                                                                        "100%"
                                                                      }
                                                                      flex={1}
                                                                      alignItems={
                                                                        "stretch"
                                                                      }
                                                                      justifyContent={
                                                                        "space-between"
                                                                      }
                                                                    >
                                                                      <Flex
                                                                        flexWrap={
                                                                          "wrap"
                                                                        }
                                                                        flex={1}
                                                                      >
                                                                        {mealsForInd[
                                                                          personId
                                                                        ].map(
                                                                          (
                                                                            planCell,
                                                                            mealIndex,
                                                                          ) => {
                                                                            return (
                                                                              <PlanCellTag
                                                                                key={
                                                                                  mealIndex
                                                                                }
                                                                                planCell={
                                                                                  planCell
                                                                                }
                                                                                mealIndex={
                                                                                  mealIndex
                                                                                }
                                                                                onClick={() =>
                                                                                  handleClickMeal(
                                                                                    planCell,
                                                                                    mealIndex,
                                                                                  )
                                                                                }
                                                                                purchaseLookup={
                                                                                  purchaseLookup
                                                                                }
                                                                              />
                                                                            );
                                                                          },
                                                                        )}
                                                                      </Flex>
                                                                      <Flex
                                                                        gap={5}
                                                                        flex={1}
                                                                        justifyContent={
                                                                          "center"
                                                                        }
                                                                        alignItems={
                                                                          "end"
                                                                        }
                                                                      >
                                                                        <Plan2PlateIconButton
                                                                          aria-label={
                                                                            "more"
                                                                          }
                                                                          title={
                                                                            "Mehr anzeigen"
                                                                          }
                                                                          icon={
                                                                            "dotsHorizontal"
                                                                          }
                                                                          onClick={() => {
                                                                            setPlanCellCoordinatesAdd(
                                                                              {
                                                                                date,
                                                                                mealInd,
                                                                                personId,
                                                                              },
                                                                            );
                                                                            onOpenAddPlanCell();
                                                                          }}
                                                                        />
                                                                      </Flex>
                                                                    </VStack>
                                                                  </React.Fragment>
                                                                );
                                                              },
                                                            )}
                                                          </HStack>
                                                        </Box>
                                                      );
                                                    })}
                                                  <HStack
                                                    alignItems={"stretch"}
                                                    width={"100%"}
                                                  >
                                                    <HStack width={"100%"}>
                                                      {keys(personLookup).map(
                                                        (
                                                          personIdAsString,
                                                          index,
                                                        ) => {
                                                          const personId =
                                                            Number(
                                                              personIdAsString,
                                                            );
                                                          const personNeeds =
                                                            personLookup[
                                                              personId
                                                            ].needs;
                                                          const currentNeed =
                                                            getNeedForDate(
                                                              personNeeds,
                                                              date,
                                                            );
                                                          const planCellsForDay =
                                                            flatMap(
                                                              flatMap(
                                                                tableData[date],
                                                                (x) =>
                                                                  values(x),
                                                              ),
                                                              (x) => values(x),
                                                            )
                                                              .filter(
                                                                (planCell) =>
                                                                  computePlanCellCoordinates(
                                                                    planCell,
                                                                  ).personId ==
                                                                  personId,
                                                              )
                                                              .filter(
                                                                (planCell) =>
                                                                  !isDeleted(
                                                                    planCell,
                                                                  ),
                                                              );

                                                          const nutriValues =
                                                            flatMap(
                                                              planCellsForDay,
                                                              (pc) =>
                                                                mapPlanCellToNutriValues(
                                                                  pc,
                                                                  foodLookup,
                                                                  purchaseLookup,
                                                                ),
                                                            );

                                                          return (
                                                            <React.Fragment
                                                              key={
                                                                personIdAsString
                                                              }
                                                            >
                                                              {index > 0 && (
                                                                <Divider
                                                                  key={
                                                                    "divider-" +
                                                                    personIdAsString
                                                                  }
                                                                  borderColor="lightgray"
                                                                  borderWidth="1px"
                                                                  orientation={
                                                                    "vertical"
                                                                  }
                                                                />
                                                              )}
                                                              <AllNutriValues
                                                                nutriValues={
                                                                  nutriValues
                                                                }
                                                                needForDate={
                                                                  currentNeed
                                                                }
                                                                mode={
                                                                  "vertical"
                                                                }
                                                              />
                                                            </React.Fragment>
                                                          );
                                                        },
                                                      )}
                                                    </HStack>
                                                  </HStack>
                                                </VStack>
                                              </HStack>
                                            </Box>
                                            <Divider
                                              borderColor="lightgray"
                                              borderWidth="1px"
                                              orientation={"horizontal"}
                                            />
                                          </VStack>
                                        );
                                      })}
                                  </VStack>
                                </VStack>
                              </Flex>
                            </>
                          </PersonDishByDishLookupContext.Provider>
                        </PersonLookupContext.Provider>
                      </PurchaseLookupWithRefreshContext.Provider>
                    </PurchaseLookupContext.Provider>
                  </DepartmentLookupContext.Provider>
                </ShopLookupWithRefreshContext.Provider>
              </FoodLookupWithRefreshContext.Provider>
            </FoodLookupContext.Provider>
          </DrawerHistoryContext.Provider>
        </MealPlannerContext.Provider>
      </AvailabilityByPurchaseIdLookupContext.Provider>
    </LoadingComponent>
  );
};

export default MealTable;
