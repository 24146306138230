import { Plan2PlateDrawer, Plan2PlateDrawerHeader } from "./plan2-plate-drawer";
import React, { useMemo, useState } from "react";
import {
  Checkbox,
  Divider,
  FormLabel,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Plan2PlateSelect } from "./plan2-plate-select";
import {
  ActivityLevelEnum,
  activityLevels,
  FormulaEnum,
  formulas,
  GenderEnum,
  genders,
  getValue,
  GoalEnum,
  goals,
} from "../data/bmr-computation";
import { SimpleInput } from "./simple-input";
import { log10, round } from "mathjs";
import { values } from "lodash";
import PopoverInfo from "./popover-info";
import { SaveButton } from "./buttons/save-button";

export const ComputeNeedDrawer = (props: {
  open: boolean;
  onClose: () => void;
  onSubmit: (bmr: number) => void;
}) => {
  const [goal, setGoal] = useState<GoalEnum>(GoalEnum.Lose);
  const [formula, setFormula] = useState<FormulaEnum>(FormulaEnum.Oxford);
  const [useMetric, setUseMetric] = useState(true);

  const [gender, setGender] = useState<GenderEnum>(GenderEnum.Diverse);
  const [age, setAge] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [weight, setWeight] = useState<number | undefined>(undefined);

  const [bodyFatPercentage, setBodyFatPercentage] = useState<
    number | undefined
  >(undefined);
  const [bodyFatPercentageUnknown, setBodyFatPercentageUnknown] =
    useState<boolean>(false);
  const [waist, setWaist] = useState<number | undefined>(undefined);
  const [neck, setNeck] = useState<number | undefined>(undefined);
  const [hip, setHip] = useState<number | undefined>(undefined);

  const [activityLevel, setActivityLevel] = useState<ActivityLevelEnum>(
    ActivityLevelEnum.Moderate,
  );

  const formulasWithBodyFatPercentage = [
    FormulaEnum.Cunningham,
    FormulaEnum.KatchMcArdle,
  ];

  const canBodyFatPercentageBeComputed =
    bodyFatPercentage || (waist && neck && (gender == GenderEnum.Male || hip));

  const canBeComputed =
    age &&
    height &&
    weight &&
    (!formulasWithBodyFatPercentage.includes(formula) ||
      canBodyFatPercentageBeComputed);

  const computedBmr = useMemo(() => {
    let bmr = undefined;

    if (canBeComputed) {
      // Umrechnung in kg und cm, falls das imperiale System verwendet wird
      const weightInKg = useMetric ? weight : weight * 0.453592;
      const heightInCm = useMetric ? height : height * 30.48;

      let computedBodyFatPercentage = bodyFatPercentage;
      if (!computedBodyFatPercentage) {
        if (gender == GenderEnum.Male) {
          // US Navy Methode für Männer
          computedBodyFatPercentage =
            86.01 * log10(waist! - neck!) - 70.041 * log10(height) + 36.76;
        } else {
          // US Navy Methode für Frauen
          computedBodyFatPercentage =
            163.205 * log10(waist! + hip! - neck!) -
            97.684 * log10(height) -
            78.387;
        }
      }

      switch (formula) {
        case FormulaEnum.Cunningham:
          const leanMassCunningham =
            weightInKg * (1 - computedBodyFatPercentage! / 100);
          bmr = 500 + 22 * leanMassCunningham;
          break;
        case FormulaEnum.HarrisBenedict:
          if (gender === GenderEnum.Male) {
            bmr =
              88.362 + 13.397 * weightInKg + 4.799 * heightInCm - 5.677 * +age;
          } else {
            bmr =
              447.593 + 9.247 * weightInKg + 3.098 * heightInCm - 4.33 * +age;
          }
          break;
        case FormulaEnum.KatchMcArdle:
          const leanMassKatch =
            weightInKg * (1 - computedBodyFatPercentage! / 100);
          bmr = 370 + 21.6 * leanMassKatch;
          break;
        case FormulaEnum.MifflinStJeor:
          if (gender === GenderEnum.Male) {
            bmr = 9.99 * weightInKg + 6.25 * heightInCm - 4.92 * +age + 5;
          } else {
            bmr = 9.99 * weightInKg + 6.25 * heightInCm - 4.92 * +age - 161;
          }
          break;
        case FormulaEnum.Oxford:
          if (gender === GenderEnum.Male) {
            if (age <= 3) {
              bmr = 28.2 * weightInKg + (859 * heightInCm) / 100 - 371;
            } else if (age <= 10) {
              bmr = 15.1 * weightInKg + (313 * heightInCm) / 100 + 306;
            } else if (age <= 18) {
              bmr = 15.6 * weightInKg + (266 * heightInCm) / 100 + 299;
            } else if (age <= 30) {
              bmr = 14.4 * weightInKg + (313 * heightInCm) / 100 + 113;
            } else if (age <= 60) {
              bmr = 11.4 * weightInKg + (541 * heightInCm) / 100 - 137;
            } else if (age > 60) {
              bmr = 11.4 * weightInKg + (541 * heightInCm) / 100 - 256;
            }
          } else {
            if (age <= 3) {
              bmr = 30.4 * weightInKg + (703 * heightInCm) / 100 - 287;
            } else if (age <= 10) {
              bmr = 15.9 * weightInKg + (210 * heightInCm) / 100 + 349;
            } else if (age <= 18) {
              bmr = 9.4 * weightInKg + (249 * heightInCm) / 100 + 462;
            } else if (age <= 30) {
              bmr = 10.4 * weightInKg + (615 * heightInCm) / 100 - 282;
            } else if (age <= 60) {
              bmr = 8.18 * weightInKg + (502 * heightInCm) / 100 - 11.6;
            } else {
              bmr = 11.4 * weightInKg + (541 * heightInCm) / 100 - 256;
            }
          }
          break;
        case FormulaEnum.Schofield:
          if (gender === GenderEnum.Male) {
            bmr =
              88.362 + 13.397 * weightInKg + 4.799 * heightInCm - 5.677 * age;
          } else {
            bmr =
              447.593 + 9.247 * weightInKg + 3.098 * heightInCm - 4.33 * age;
          }
          break;
        default:
          alert("Bitte eine gültige Formel auswählen");
          return undefined;
      }
    }
    return bmr;
  }, [
    canBeComputed,
    useMetric,
    weight,
    height,
    bodyFatPercentage,
    gender,
    waist,
    neck,
    hip,
    formula,
    age,
  ]);

  function computeWithActivity(bmr: number) {
    const activityLevelValue = getValue(activityLevels, activityLevel)!;
    return (
      bmr *
      (gender === GenderEnum.Male
        ? activityLevelValue?.maleFactor
        : activityLevelValue?.defaultFactor)
    );
  }

  function computeWithGoal(withActivity: number) {
    let factor: number = 1;
    switch (goal) {
      case GoalEnum.Maintain:
        factor = 0.85;
        break;
      case GoalEnum.Lose:
        factor = 0.75;
        break;
      case GoalEnum.Gain:
        factor = 1.2;
        break;
    }
    return withActivity * factor;
  }

  const withActivity =
    computedBmr != null ? computeWithActivity(computedBmr) : undefined;
  const withGoal =
    withActivity != null ? computeWithGoal(withActivity) : undefined;

  return (
    <Plan2PlateDrawer
      open={props.open}
      onClose={props.onClose}
      header={<Plan2PlateDrawerHeader heading={"Bedarf Rechner"} />}
      body={
        <VStack className="stretch-stack">
          <HStack>
            <Plan2PlateSelect
              width={"100%"}
              caption={"BMR-Formel"}
              value={getValue(formulas, formula)}
              onChange={(f) => {
                setFormula(f!.id);
              }}
              withoutSort={true}
              choices={values(formulas)}
            />
            <PopoverInfo
              headerText="BMR-Formel"
              bodyContent={
                <>
                  {values(formulas).map((f) => (
                    <React.Fragment key={f.id}>
                      <Text fontSize="sm" fontWeight="bold">
                        {f.description}
                      </Text>
                      <Text fontSize="sm">{f.text}</Text>
                    </React.Fragment>
                  ))}
                  <Divider
                    borderColor="lightgray"
                    borderWidth="1px"
                    orientation={"vertical"}
                    width={"100%"}
                  />
                  <Text fontSize="sm" fontWeight="bold">
                    Quellen
                  </Text>
                  <Text fontSize="sm">Henry, 2005</Text>
                  <Text fontSize="sm">
                    Comparison of Predictive Equations for Resting Metabolic
                    Rate in Healthy Nonobese and Obese Adults: A Systematic
                    Review, 2005
                  </Text>
                  <Text fontSize="sm">
                    Dietary Reference Values for Energy, 2011
                  </Text>
                </>
              }
            />
          </HStack>

          <Checkbox
            colorScheme="teal"
            isChecked={useMetric}
            onChange={(e) => setUseMetric(e.target.checked)}
          >
            Metrisches System
          </Checkbox>

          <HStack>
            <Plan2PlateSelect
              width={"150px"}
              caption={"Geschlecht"}
              value={getValue(genders, gender)}
              onChange={(g) => {
                setGender(g!.id);
              }}
              withoutSort={true}
              choices={values(genders)}
            />

            <SimpleInput
              caption={"Alter"}
              type={"number"}
              width={"150px"}
              value={age}
              onBlur={(e) => {
                setAge(Number(e.target.value || "0"));
              }}
            />
          </HStack>

          <HStack>
            <SimpleInput
              caption={"Größe " + (useMetric ? "(cm)" : "(ft)")}
              type={"number"}
              width={"150px"}
              value={height}
              onBlur={(e) => {
                setHeight(Number(e.target.value || "0"));
              }}
            />

            <SimpleInput
              caption={"Gewicht " + (useMetric ? "(kg)" : "(lbs)")}
              type={"number"}
              width={"150px"}
              value={weight}
              onBlur={(e) => {
                setWeight(Number(e.target.value || "0"));
              }}
            />
          </HStack>

          {formulasWithBodyFatPercentage.includes(formula) && (
            <HStack>
              <SimpleInput
                caption={"Körperfettanteil, %"}
                type={"number"}
                width={"150px"}
                value={bodyFatPercentage}
                disabled={bodyFatPercentageUnknown}
                onBlur={(e) => {
                  setBodyFatPercentage(Number(e.target.value || "0"));
                }}
              />
              <Checkbox
                colorScheme="teal"
                onChange={(e) => {
                  setBodyFatPercentageUnknown(e.target.checked);
                  if (!e.target.checked) {
                    setWaist(undefined);
                    setNeck(undefined);
                    setHip(undefined);
                  } else {
                    setBodyFatPercentage(undefined);
                  }
                }}
                isChecked={bodyFatPercentageUnknown}
              >
                ist unbekannt
              </Checkbox>
            </HStack>
          )}

          {formulasWithBodyFatPercentage.includes(formula) &&
            bodyFatPercentageUnknown && (
              <>
                <HStack>
                  <SimpleInput
                    caption={
                      (gender == GenderEnum.Male
                        ? "Bauchumfang "
                        : "Taillenumfang ") + (useMetric ? "(cm)" : "(ft)")
                    }
                    type={"number"}
                    width={"150px"}
                    value={waist}
                    onBlur={(e) => {
                      setWaist(Number(e.target.value || "0"));
                    }}
                  />
                  <PopoverInfo
                    headerText={
                      gender == GenderEnum.Male
                        ? "Bauchumfang "
                        : "Taillenumfang "
                    }
                    bodyContent={
                      <Text fontSize="sm">
                        {gender == GenderEnum.Male
                          ? "Über Bauchnabel"
                          : "Der schmalste Umfang zwischen Unterrippe und Bauchnabel"}
                      </Text>
                    }
                  />
                  <SimpleInput
                    caption={"Halsumfang " + (useMetric ? "(cm)" : "(ft)")}
                    type={"number"}
                    width={"150px"}
                    value={neck}
                    onBlur={(e) => {
                      setNeck(Number(e.target.value || "0"));
                    }}
                  />
                  <PopoverInfo
                    headerText="Nacken-/Halsumfang"
                    bodyContent={
                      <Text fontSize="sm">Unterhalb des Kehlkopfes</Text>
                    }
                  />
                </HStack>
                {gender != GenderEnum.Male && (
                  <HStack>
                    <SimpleInput
                      caption={"Hüftumfang " + (useMetric ? "(cm)" : "(ft)")}
                      type={"number"}
                      width={"150px"}
                      value={hip}
                      onBlur={(e) => {
                        setHip(Number(e.target.value || "0"));
                      }}
                    />
                    <PopoverInfo
                      headerText="Hüftumfang"
                      bodyContent={
                        <Text fontSize="sm">
                          Der breiteste Umfang unterhalb des Bauchnabels
                        </Text>
                      }
                    />
                  </HStack>
                )}
              </>
            )}

          <HStack>
            <Plan2PlateSelect
              width={"100%"}
              caption={"Aktivitätslevel"}
              value={getValue(activityLevels, activityLevel)}
              onChange={(a) => {
                setActivityLevel(a!.id);
              }}
              withoutSort={true}
              choices={values(activityLevels)}
            />
            <PopoverInfo
              headerText="Aktivitätslevel"
              bodyContent={
                <>
                  {values(activityLevels).map((al) => (
                    <React.Fragment key={al.id}>
                      <Text fontSize="sm" fontWeight="bold">
                        {al.description}
                      </Text>
                      <Text fontSize="sm">{al.text}</Text>
                    </React.Fragment>
                  ))}
                </>
              }
            />
          </HStack>

          <Plan2PlateSelect
            width={"100%"}
            caption={"Ziel"}
            value={getValue(goals, goal)}
            onChange={(g) => {
              setGoal(g!.id);
            }}
            withoutSort={true}
            choices={values(goals)}
          />

          <Divider orientation={"horizontal"} width={"100%"} />
          {computedBmr && (
            <FormLabel fontSize={"12px"} m={0}>
              Grundumsatz: {round(computedBmr, 2)} kcal
            </FormLabel>
          )}
          {withActivity && (
            <FormLabel fontSize={"12px"} m={0}>
              Energieverbrauch: {round(withActivity, 2)} kcal
            </FormLabel>
          )}
          {withGoal && (
            <FormLabel fontSize={"12px"} m={0}>
              Empfohlener Bedarf: {round(withGoal, 2)} kcal
            </FormLabel>
          )}
        </VStack>
      }
      footer={
        <SaveButton
          isDisabled={!canBeComputed}
          save={async () => {
            if (withGoal) {
              props.onSubmit(withGoal);
            }

            props.onClose();
            return false;
          }}
        />
      }
    />
  );
};
