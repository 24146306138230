import {
  MealWithIntakes,
  PlanCell,
  PlanCellCoordinates,
  PlanCellWithIndex,
} from "../data/plan";
import {
  MealIngredient,
  MealWithIngredients,
} from "../data/meal-with-ingredients";
import { isDeleted, isEdited } from "./edit-utils";
import { FoodLookup, PersonLookup, PurchaseLookup } from "../data/lookups";
import { mealInds } from "./meal-ind-utils";
import {
  PersonRecipeUnitModification,
  RecipeUnitModification,
} from "../data/dish-modification";
import { Intake } from "../data/intake";
import { emptyNutriValue, NutriValues } from "../data/nutri-values";
import { values } from "lodash";
import { formatPurchaseDescription } from "./purchase-utils";
import { formatGermanDateShort } from "./date-utils";
import { MealSourceId } from "../data/meal-source";
import { axiosInstance } from "../keycloak";

export function formatPlanCellDescription(
  planCell: PlanCell,
  purchaseLookup: PurchaseLookup,
) {
  return planCell.indicator == "MealWithIntakes"
    ? planCell.meal.dishDescription ||
        planCell.meal.foodDescription ||
        planCell.meal.purchaseDescription
    : formatPurchaseDescription(
        purchaseLookup[planCell.purchaseUnitPurchaseId],
      );
}

export function isNullIndex(index: number | undefined) {
  return index == -1 || index == undefined;
}

export function isNewCell(planCell: PlanCell) {
  return planCell.indicator == "MealWithIntakes"
    ? isNullIndex(planCell.meal.id)
    : isNullIndex(planCell.id);
}

export function isNewOrEdited(planCell: PlanCell) {
  return isNewCell(planCell) || isEdited(planCell);
}

export function computePlanCellCoordinatesFromMeal(
  meal: MealWithIngredients,
  index?: number,
) {
  return {
    date: meal.mealDate,
    mealInd: meal.mealInd,
    personId: meal.personId,
    index,
  };
}

export function computePlanCellCoordinates(
  planCell: PlanCell,
  index?: number,
): PlanCellCoordinates {
  return planCell.indicator == "MealWithIntakes"
    ? computePlanCellCoordinatesFromMeal(planCell.meal, index)
    : {
        date: planCell.intakeDate,
        mealInd: planCell.mealInd,
        personId: planCell.personId,
        index,
      };
}

export function sameCoordinates(
  a: PlanCellCoordinates,
  b: PlanCellCoordinates,
) {
  return (
    a.date == b.date &&
    a.mealInd == b.mealInd &&
    a.personId == b.personId &&
    a.index == b.index
  );
}

export function computePlanCellCoordinatesFromPlanCellWithIndex(
  planCellWithIndex: PlanCellWithIndex,
) {
  return computePlanCellCoordinates(
    planCellWithIndex.planCell,
    planCellWithIndex.index,
  );
}

function nutriValuesFromFood(
  mealIngredient: MealIngredient,
  foodLookup: FoodLookup,
  foodId: number,
) {
  const food = foodLookup[foodId];
  if (food == undefined) {
    return emptyNutriValue;
  }
  const foodUnit = food.foodUnits.find(
    (foodUnit) => foodUnit.id == mealIngredient.foodUnitId,
  )!;
  if (foodUnit == undefined) {
    return emptyNutriValue;
  }
  const foodUnitFactor =
    foodUnit.netWeight != undefined ? foodUnit.netWeight / 100 : 1;
  const nutriFactor = foodUnitFactor * mealIngredient.amount;
  return {
    fat: food.fat * nutriFactor,
    carbs: food.carbs * nutriFactor,
    protein: food.protein * nutriFactor,
    kcal: food.kcal! * nutriFactor,
  };
}

function nutriValuesFromPurchase(
  mealIngredient: MealIngredient,
  purchaseLookup: PurchaseLookup,
  purchaseId: number,
) {
  const purchase = purchaseLookup[purchaseId];
  if (purchase == undefined) {
    return emptyNutriValue;
  }
  const purchaseUnit = purchase.purchaseUnits.find(
    (purchaseUnit) => purchaseUnit.id == mealIngredient.purchaseUnitId,
  )!;
  if (purchaseUnit == undefined) {
    return emptyNutriValue;
  }
  const purchaseUnitFactor =
    purchaseUnit.netWeight != undefined ? purchaseUnit.netWeight / 100 : 1;
  const nutriFactor = purchaseUnitFactor * mealIngredient.amount;
  return {
    fat: purchase.fat * nutriFactor,
    carbs: purchase.carbs * nutriFactor,
    protein: purchase.protein * nutriFactor,
    kcal: purchase.kcal! * nutriFactor,
  };
}

export function nutriValuesFromMealIngredient(
  mealIngredient: MealIngredient,
  foodLookup: FoodLookup,
  purchaseLookup: PurchaseLookup,
) {
  return isNullIndex(mealIngredient.foodUnitFoodId)
    ? nutriValuesFromPurchase(
        mealIngredient,
        purchaseLookup,
        mealIngredient.purchaseUnitPurchaseId,
      )
    : nutriValuesFromFood(
        mealIngredient,
        foodLookup,
        mealIngredient.foodUnitFoodId,
      );
}

export function nutriValuesFromIntake(
  intake: Intake,
  purchaseLookup: PurchaseLookup,
) {
  const purchaseId = intake.purchaseUnitPurchaseId;
  const purchase = purchaseLookup[purchaseId];
  const purchaseUnit = purchase?.purchaseUnits.find(
    (purchaseUnit) => purchaseUnit.id == intake.purchaseUnitId,
  )!;
  if (purchaseUnit == undefined) {
    return {
      fat: 0,
      carbs: 0,
      protein: 0,
      kcal: 0,
    };
  }
  const purchaseUnitFactor =
    purchaseUnit.netWeight != undefined ? purchaseUnit.netWeight / 100 : 1;
  const nutriFactor = purchaseUnitFactor * (intake.amount || 0);
  return {
    fat: purchase.fat * nutriFactor,
    carbs: purchase.carbs * nutriFactor,
    protein: purchase.protein * nutriFactor,
    kcal: purchase.kcal! * nutriFactor,
  };
}

export function nutriValuesFromPersonRecipe(
  basiceRecipe: RecipeUnitModification,
  personRecipe: PersonRecipeUnitModification | undefined,
  foodLookup: FoodLookup,
) {
  if (!personRecipe) {
    return emptyNutriValue;
  }
  const foodId = basiceRecipe.foodId;
  const food = foodLookup[foodId];
  if (!food) {
    return emptyNutriValue;
  }
  const foodUnit = food.foodUnits.find(
    (foodUnit) => foodUnit.id == basiceRecipe.foodUnitId,
  )!;
  if (!foodUnit) {
    return emptyNutriValue;
  }
  const foodUnitFactor =
    foodUnit.netWeight != undefined ? foodUnit.netWeight / 100 : 1;
  const nutriFactor = foodUnitFactor * personRecipe.amount;
  return {
    fat: food.fat * nutriFactor,
    carbs: food.carbs * nutriFactor,
    protein: food.protein * nutriFactor,
    kcal: food.kcal! * nutriFactor,
  };
}

export function nutriValuesFromPortion(
  basiceRecipe: RecipeUnitModification,
  portionCount: number,
  foodLookup: FoodLookup,
) {
  if (!basiceRecipe.portionAmount) {
    return emptyNutriValue;
  }
  const foodId = basiceRecipe.foodId;
  const food = foodLookup[foodId];
  if (!food) {
    return emptyNutriValue;
  }
  const foodUnit = food.foodUnits.find(
    (foodUnit) => foodUnit.id == basiceRecipe.foodUnitId,
  )!;
  if (!foodUnit) {
    return emptyNutriValue;
  }
  const foodUnitFactor =
    foodUnit.netWeight != undefined ? foodUnit.netWeight / 100 : 1;
  const nutriFactor =
    (foodUnitFactor * basiceRecipe.portionAmount) / portionCount;
  return {
    fat: food.fat * nutriFactor,
    carbs: food.carbs * nutriFactor,
    protein: food.protein * nutriFactor,
    kcal: food.kcal! * nutriFactor,
  };
}

export function formatCoordinates(
  planCellCoordinates: PlanCellCoordinates,
  personLookup: PersonLookup,
) {
  return `${mealInds[planCellCoordinates.mealInd]?.name || ""} am ${formatGermanDateShort(planCellCoordinates.date)}
  für ${personLookup[planCellCoordinates.personId].name}`;
}

export const mapPlanCellToNutriValues = (
  planCell: PlanCell,
  foodLookup: FoodLookup,
  purchaseLookup: PurchaseLookup,
): NutriValues[] =>
  planCell.indicator == "MealWithIntakes"
    ? planCell.intakes != undefined
      ? planCell.intakes
          .filter((i) => !isDeleted(i))
          .map((i) => nutriValuesFromIntake(i, purchaseLookup))
      : planCell.meal.mealIngredients.map((mealIngredient) =>
          nutriValuesFromMealIngredient(
            mealIngredient,
            foodLookup,
            purchaseLookup,
          ),
        )
    : [planCell].map((c) => nutriValuesFromIntake(c, purchaseLookup));

export function createDefaultIntakes(
  planCell: MealWithIntakes,
  purchaseLookup: PurchaseLookup,
) {
  const selectablePurchases = values(purchaseLookup);
  return planCell.meal.mealIngredients.map((mealIngredient) => {
    const defaultPurchase = isNullIndex(mealIngredient.foodUnitFoodId)
      ? purchaseLookup[mealIngredient.purchaseUnitPurchaseId]
      : selectablePurchases.find(
          (pu) => pu.foodId == mealIngredient.foodUnitFoodId && pu.isDefault,
        )!;
    const defaultPurchaseUnit = defaultPurchase.purchaseUnits.find(
      (pu) => pu.foodUnitId == mealIngredient.foodUnitId,
    )!;
    return {
      id: -1,
      intakeDate: planCell.meal.mealDate,
      mealInd: planCell.meal.mealInd,
      amount: mealIngredient.amount,
      personId: planCell.meal.personId,
      mealId: planCell.meal.id,
      purchaseUnitId: defaultPurchaseUnit.id,
      purchaseUnitDescription: defaultPurchaseUnit.description,
      purchaseUnitPurchaseId: defaultPurchase.id,
      purchaseUnitPurchaseDescription: defaultPurchase.description,
    };
  });
}

export const computePlanCell =
  (planCellCoordinates: PlanCellCoordinates, mealSourceId: MealSourceId) =>
  async () => {
    const requestObj = {
      planCellCoordinates,
      mealSourceId,
    };
    const mealsWithIngredients = (
      await axiosInstance.post<MealWithIngredients[]>(
        `/api/mealFromEntity`,
        requestObj,
      )
    ).data;
    return mealsWithIngredients.map((mealWithIngredients) => {
      const mealWithIntakes: MealWithIntakes = {
        indicator: "MealWithIntakes",
        meal: mealWithIngredients,
      };
      return mealWithIntakes;
    });
  };
export type MealKey = { dishId: number; foodId: number; purchaseId: number };
export type MealKeyString =
  `{"dishId":${number | undefined},"foodId":${number | undefined}},"purchaseId":${number | undefined}}`;

export function getMealKeyForMeal(meal: MealWithIngredients) {
  return {
    dishId: meal.dishId,
    foodId: meal.foodId,
    purchaseId: meal.purchaseId,
  };
}

export function getMealKeyForMealOrUndefined(
  meal: MealWithIngredients | undefined,
): MealKey | undefined {
  if (!meal) {
    return undefined;
  }
  return getMealKeyForMeal(meal);
}

export function parseMealKey(keyString: MealKeyString): MealKey {
  return JSON.parse(keyString);
}

export function getMealKeyAsString(meal: MealWithIntakes): MealKeyString {
  const { dishId, foodId, purchaseId } = meal.meal;
  return JSON.stringify({
    dishId,
    foodId,
    purchaseId,
  }) as MealKeyString;
}

export const nutriValueSumReducer = (a: NutriValues, b: NutriValues) => {
  return {
    fat: a.fat + b.fat,
    carbs: a.carbs + b.carbs,
    protein: a.protein + b.protein,
    kcal: a.kcal + b.kcal,
  };
};
export type HistoryState = { id: string };
