import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useImmer } from "use-immer";
import { formatGermanDateShort, isTodayOrPrevious } from "../utils/date-utils";
import { mealInds } from "../utils/meal-ind-utils";
import {
  computePlanCellCoordinates,
  formatPlanCellDescription,
  getMealKeyAsString,
  isNullIndex,
  MealKey,
  MealKeyString,
  parseMealKey,
} from "../utils/plan-utils";
import { MealWithIntakes } from "../data/plan";
import {
  DrawerHistoryContextType,
  FoodLookupContext,
  PersonLookupContext,
  PurchaseLookupContext,
} from "../contexts";
import { entries, flatMap, groupBy } from "lodash";
import { NumberInput } from "./number-input";
import { MealIngredient } from "../data/meal-with-ingredients";
import { FastIntakeButton } from "./buttons/fast-intake-button";
import {
  Plan2PlateDrawer,
  Plan2PlateDrawerHeader,
  usePlan2PlateDisclosure,
} from "./plan2-plate-drawer";
import { getMealIngredientDescription } from "../utils/meal-utils";

interface CookingDrawerProps {
  meals: MealWithIntakes[] | undefined;
  isOpen: boolean;
  onClose: () => void;
  onConsume: (date: string, mealInd: string, mealKey: MealKey) => void;
  clearHistory: () => Promise<void>;
}

export function useCookingDrawer(
  onConsume: CookingDrawerProps["onConsume"],
  drawerHistoryContext: DrawerHistoryContextType,
): CookingDrawerProps & {
  cook: (planCells: MealWithIntakes[]) => Promise<void>;
} {
  const [planCells, updatePlanCells] = useImmer<MealWithIntakes[] | undefined>(
    undefined,
  );

  const { isOpen, onOpen, onClose } =
    usePlan2PlateDisclosure(drawerHistoryContext);

  async function cook(planCells: MealWithIntakes[]) {
    updatePlanCells(planCells);
    onOpen();
  }

  return {
    onConsume,
    cook,
    meals: planCells,
    isOpen,
    onClose,
    clearHistory: drawerHistoryContext.clearHistory,
  };
}

function getMealIngredientKey(mealIngredient: MealIngredient) {
  return isNullIndex(mealIngredient.foodUnitFoodId)
    ? `purchaseunit${mealIngredient.purchaseUnitId}`
    : `foodunit${mealIngredient.foodUnitId}`;
}

export const CookingDrawer = (props: CookingDrawerProps) => {
  const personLookup = useContext(PersonLookupContext)!;
  const foodLookup = useContext(FoodLookupContext)!;

  async function onConsume(date: string, mealInd: string, mealKey: MealKey) {
    await props.clearHistory();
    props.onConsume(date, mealInd, mealKey);
  }

  const purchaseLookup = useContext(PurchaseLookupContext)!;

  if (props.meals != undefined && props.meals.length > 0) {
    const { date, index, mealInd, personId } = computePlanCellCoordinates(
      props.meals[0],
    );
    const groupedByMealKey = groupBy(props.meals, (meal) =>
      getMealKeyAsString(meal),
    );

    const heading = `Kochdialog für ${mealInds[mealInd].name} am ${formatGermanDateShort(date)}`;

    return (
      <>
        <Plan2PlateDrawer
          onClose={props.onClose}
          open={props.isOpen}
          header={<Plan2PlateDrawerHeader heading={heading} />}
          body={
            <Accordion allowMultiple width="100%">
              {entries(groupedByMealKey).map(([mealKey, meals]) => {
                const description = formatPlanCellDescription(
                  meals[0],
                  purchaseLookup!,
                );
                const distinctMealIngredients = new Set(
                  flatMap(meals, (meal) =>
                    meal.meal.mealIngredients.map((mealIngredient) =>
                      getMealIngredientKey(mealIngredient),
                    ),
                  ),
                );
                const mealIngredients = flatMap(meals, (meal) =>
                  meal.meal.mealIngredients.map((mealIngredient) => {
                    return {
                      meal,
                      mealIngredient,
                    };
                  }),
                );

                const groupedByFoodUnit = groupBy(
                  mealIngredients,
                  (mealIngredient) =>
                    getMealIngredientKey(mealIngredient.mealIngredient),
                );

                return (
                  <AccordionItem key={mealKey}>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex={1}
                          textAlign={"left"}
                          fontWeight={"bold"}
                        >
                          {description}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel motionProps={{ animateOpacity: false }}>
                      <VStack>
                        <Accordion allowMultiple width="100%">
                          {Array.from(distinctMealIngredients).map(
                            (mealIngredientKey) => {
                              const mealIngredients =
                                groupedByFoodUnit[mealIngredientKey];
                              const foodDescription =
                                getMealIngredientDescription(
                                  mealIngredients,
                                  foodLookup,
                                  purchaseLookup,
                                );
                              return (
                                <AccordionItem key={mealIngredientKey}>
                                  <AccordionButton>
                                    <Box as="span" flex={1} textAlign={"left"}>
                                      {foodDescription}
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                  <AccordionPanel
                                    motionProps={{ animateOpacity: false }}
                                  >
                                    <Flex flexWrap={"wrap"} gap={"10px"}>
                                      {mealIngredients.map((mealIngredient) => {
                                        const personId =
                                          mealIngredient.meal.meal.personId;
                                        const person = personLookup[personId];
                                        return (
                                          <NumberInput
                                            key={[
                                              person.id,
                                              mealIngredient.mealIngredient.id,
                                            ].join()}
                                            fieldName={"amount"}
                                            fieldDescription={person.name}
                                            value={
                                              mealIngredient.mealIngredient
                                                .amount
                                            }
                                            updater={(k, v) => {
                                              return {} as any as MealIngredient;
                                            }}
                                            handleBlur={() => {}}
                                            disabled={true}
                                            width={"100px"}
                                          />
                                        );
                                      })}
                                    </Flex>
                                  </AccordionPanel>
                                </AccordionItem>
                              );
                            },
                          )}
                        </Accordion>
                        {isTodayOrPrevious(date) && (
                          <FastIntakeButton
                            onClick={() =>
                              onConsume(
                                date,
                                mealInd,
                                parseMealKey(mealKey as MealKeyString),
                              )
                            }
                          />
                        )}
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          }
        />
      </>
    );
  } else {
    return <></>;
  }
};
