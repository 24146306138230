import { useEffect, useState } from "react";

export const useUnloadAlert = () => {
  const [pristine, setPristine] = useState(true);
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!pristine) {
        event.preventDefault();
        event.returnValue = ""; // Dies ist notwendig, um den Dialog anzuzeigen
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [pristine]);
  return { pristine, setPristine };
};
