import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { DeleteButton } from "./buttons/delete-button";
import {
  KcalFormLabel,
  NutriBar,
  NutriBarWithKcalAndBullets,
  NutriBullets,
} from "./nutri-bar";
import { SimpleInput, SimpleInputProps } from "./simple-input";
import { CancelButton } from "./buttons/cancel-button";
import { SaveButton } from "./buttons/save-button";
import {
  Plan2PlateDrawer,
  Plan2PlateDrawerHeader,
  usePlan2PlateDisclosure,
} from "./plan2-plate-drawer";
import {
  IntakeWithEditedFlags,
  JustIntake,
  MealWithIntakes,
  PlanCell,
  PlanCellCoordinates,
  PlanCellWithIndex,
} from "../data/plan";
import {
  computePlanCell,
  computePlanCellCoordinatesFromPlanCellWithIndex,
  createDefaultIntakes,
  formatPlanCellDescription,
  isNullIndex,
  mapPlanCellToNutriValues,
  nutriValuesFromIntake,
  nutriValuesFromMealIngredient,
  sameCoordinates,
} from "../utils/plan-utils";
import { Updater, useImmer } from "use-immer";
import {
  DrawerHistoryContext,
  FoodLookupContext,
  FoodLookupWithRefreshContext,
  MealPlannerContext,
  PersonDishByDishLookupContext,
  PersonLookupContext,
  PurchaseLookupContext,
  PurchaseLookupWithRefreshContext,
} from "../contexts";
import { Plan2PlateSelect } from "./plan2-plate-select";
import { round } from "mathjs";
import { getNeedForDate } from "../utils/person-utils";
import { NeedTimeslice } from "../data/need";
import {
  flatMap,
  get,
  groupBy,
  keys,
  map,
  minBy,
  pickBy,
  sortBy,
  sum,
  uniq,
  values,
} from "lodash";
import { PlanMode } from "./search-for-plan-cell-drawer";
import { FoodUnit } from "../data/food-unit";
import Food, { BaseUnit } from "../data/food";
import { Purchase, PurchaseUnit } from "../data/purchase";
import { formatPurchaseDescription } from "../utils/purchase-utils";
import { Draft, produce } from "immer";
import { Plan2PlateIconButton } from "./buttons/plan2-plate-icon-button";
import { FoodLookup, PersonLookup, PurchaseLookup } from "../data/lookups";
import { mealInds } from "../utils/meal-ind-utils";
import { formatGermanDateShort } from "../utils/date-utils";
import { isDeleted } from "../utils/edit-utils";
import { AddButton } from "./buttons/add-button";
import {
  initialNewMealIngredient,
  MealIngredient,
} from "../data/meal-with-ingredients";
import { AllNutriValues } from "./all-nutri-values";
import { Person } from "../data/person";
import { showValidationMessage } from "./show-validation-message";
import { CreateDishDrawer } from "./create-dish-drawer";
import { DishModification, emptyDish } from "../data/dish-modification";
import { Plan2PlateAccordionHeading } from "./plan2-plate-accordion";
import { Plan2PlateIcon } from "./plan2-plate-icon";
import { getLowerCaseBaseUnit } from "./nutrition-input";
import { formatFoodDescription } from "../utils/food-utils";
import { CreatePurchaseDrawer } from "./create-purchase-drawer";
import { initialIntake, Intake } from "../data/intake";
import { getMealKey } from "../utils/meal-utils";
import { EditDishDrawer } from "./edit-dish-drawer";
import { loadDishModification } from "../loaders";

function formatUnitDescription<T>(
  unit: { description: string; netWeight?: number },
  baseUnit?: BaseUnit,
) {
  if (!baseUnit) {
    return unit.description;
  }
  const lowerCaseBaseUnit = getLowerCaseBaseUnit(baseUnit);
  const noSuffix = [`${lowerCaseBaseUnit}`, `100${lowerCaseBaseUnit}`].includes(
    unit.description,
  );
  const suffix = noSuffix ? "" : ` (${unit.netWeight}${lowerCaseBaseUnit})`;
  return `${unit.description}${suffix}`;
}

function FoodIngredientBox(props: {
  food: Food | undefined;
  onChangeFood: (f: Food | undefined) => void;
  foodChoices: Food[];
  foodUnit: FoodUnit | undefined;
  onChangeFoodUnit: (fu: FoodUnit | undefined) => void;
  amount: number;
  onChangeAmount: SimpleInputProps["onBlur"];
  onDelete: () => void;
  kcal: number;
  fat: number;
  carbs: number;
  protein: number;
  factor: number;
}) {
  return (
    <Box className="editor-box">
      <VStack alignItems={"stretch"} width={"100%"}>
        {props.food ? (
          <Box>{formatFoodDescription(props.food)}</Box>
        ) : (
          <Plan2PlateSelect
            width={"100%"}
            placeholder={"Lebensmittel"}
            value={props.food}
            onChange={props.onChangeFood}
            choices={props.foodChoices}
          />
        )}
        <HStack>
          <Plan2PlateSelect
            value={props.foodUnit}
            onChange={props.onChangeFoodUnit}
            choices={props.food?.foodUnits || []}
            placeholder={"Einheit"}
            width={"100%"}
            getDescription={(fu) => {
              return formatUnitDescription(fu, props.food?.baseUnit);
            }}
            autoselectSingle
          ></Plan2PlateSelect>
          <SimpleInput
            caption={props.foodUnit?.description}
            value={props.amount}
            onBlur={props.onChangeAmount}
            type={"number"}
            width="120px"
          />
          <DeleteButton onClick={props.onDelete} />
        </HStack>
        <NutriBarWithKcalAndBullets
          nutriValues={{
            ...props,
          }}
          factor={props.factor}
        />
      </VStack>
    </Box>
  );
}

function PurchaseIngredientBox(props: {
  purchase: Purchase;
  onChangePurchase: (f: Purchase | undefined) => void;
  purchaseUnit: PurchaseUnit | undefined;
  onChangePurchaseUnit: (fu: PurchaseUnit | undefined) => void;
  amount: number;
  onChangeAmount: SimpleInputProps["onBlur"];
  onDelete: () => void;
  kcal: number;
  fat: number;
  carbs: number;
  protein: number;
  factor: number;
  baseUnit?: BaseUnit;
}) {
  return (
    <Box className="editor-box">
      <VStack alignItems={"stretch"} width={"100%"}>
        <Box>{formatPurchaseDescription(props.purchase)}</Box>
        <HStack>
          <Plan2PlateSelect
            value={props.purchaseUnit}
            onChange={props.onChangePurchaseUnit}
            choices={props.purchase?.purchaseUnits || []}
            placeholder={"Einheit"}
            width={"100%"}
            getDescription={(fu) => {
              return formatUnitDescription(fu, props.baseUnit);
            }}
          ></Plan2PlateSelect>
          <SimpleInput
            caption={props.purchaseUnit?.description}
            value={props.amount}
            onBlur={props.onChangeAmount}
            type={"number"}
            width="120px"
          />
          <DeleteButton onClick={props.onDelete} />
        </HStack>
        <NutriBarWithKcalAndBullets
          nutriValues={{
            ...props,
          }}
          factor={props.factor}
        />
      </VStack>
    </Box>
  );
}

function getPurchasesWithSameFood(
  selectablePurchases: Purchase[],
  purchaseId: number,
) {
  const purchaseForIntake = selectablePurchases.find(
    (purchase) => purchase.id == purchaseId,
  );
  return selectablePurchases.filter(
    (purchase) => purchase.foodId == purchaseForIntake?.foodId,
  );
}

function IntakeBox(props: {
  purchase: Purchase | undefined;
  purchaseChoices?: Purchase[];
  purchaseUnit: PurchaseUnit | undefined;
  onChangePurchase?: (p: Purchase | undefined) => void;
  onChangePurchaseUnit: (fu: PurchaseUnit | undefined) => void;
  amount: number;
  onChangeAmount: (amount: number) => void;
  onDelete?: () => void;
  addPurchase: () => void;
  kcal: number;
  fat: number;
  carbs: number;
  protein: number;
  factor: number;
}) {
  const purchaseLookup = useContext(PurchaseLookupContext)!;
  const foodLookup = useContext(FoodLookupContext)!;

  const selectablePurchases = useMemo(() => {
    return props.purchase?.id
      ? getPurchasesWithSameFood(values(purchaseLookup), props.purchase?.id)
      : props.purchaseChoices!;
  }, [props.purchase?.id]);

  useEffect(() => {
    if (selectablePurchases.length == 1 && !props.purchase) {
      props.onChangePurchase!(selectablePurchases[0]);
    }
  }, [selectablePurchases, props.onChangePurchase]);

  const food = props.purchase?.foodId
    ? foodLookup[props.purchase?.foodId]
    : undefined;

  return (
    <Box className="editor-box">
      <VStack alignItems={"stretch"} width={"100%"}>
        <HStack>
          {props.purchase != undefined &&
          (selectablePurchases.length <= 1 ||
            props.onChangePurchase == undefined) ? (
            <Box width={"100%"}>
              {formatPurchaseDescription(props.purchase)}
            </Box>
          ) : props.purchase != undefined ? (
            <Plan2PlateSelect
              value={props.purchase}
              onChange={(p) => {
                if (!p) {
                  return;
                }
                if (props.purchaseUnit) {
                  const sameNetWeight = p.purchaseUnits.find(
                    (pu) => pu.netWeight == props.purchaseUnit!.netWeight,
                  );
                  if (sameNetWeight) {
                    props.onChangePurchaseUnit(sameNetWeight);
                  } else {
                    const singleElement =
                      p.purchaseUnits.length == 1
                        ? p.purchaseUnits[0]
                        : undefined;
                    if (singleElement && props.purchaseUnit) {
                      const ratio =
                        props.purchaseUnit.netWeight / singleElement.netWeight;
                      props.onChangePurchaseUnit(singleElement);
                      props.onChangeAmount(props.amount * ratio);
                    }
                  }
                }
                props.onChangePurchase!(p);
              }}
              choices={selectablePurchases}
              getDescription={formatPurchaseDescription}
              width={"100%"}
            ></Plan2PlateSelect>
          ) : (
            <Plan2PlateSelect
              placeholder={"Lebensmittel"}
              value={props.purchase}
              onChange={props.onChangePurchase!}
              choices={selectablePurchases}
              getDescription={formatPurchaseDescription}
              width={"100%"}
            />
          )}
          <Plan2PlateIconButton
            aria-label={"addPurchase"}
            title={"Produkt erstellen"}
            icon={"purchase"}
            onClick={props.addPurchase}
          />
        </HStack>

        <HStack>
          <Plan2PlateSelect
            value={props.purchaseUnit}
            onChange={props.onChangePurchaseUnit}
            choices={props.purchase?.purchaseUnits || []}
            placeholder={"Einheit"}
            width={"100%"}
            getDescription={(pu) =>
              food ? formatUnitDescription(pu, food.baseUnit) : ""
            }
            autoselectSingle
          ></Plan2PlateSelect>
          <SimpleInput
            caption={props.purchaseUnit?.description}
            value={props.amount}
            onBlur={(e) => {
              props.onChangeAmount(Number(e.target.value || "0"));
            }}
            type={"number"}
            width="120px"
          />
          {props.onDelete && <DeleteButton onClick={props.onDelete} />}
        </HStack>
        <KcalFormLabel kcal={props.kcal} />
        <NutriBar
          nutriValues={{
            fat: props.fat,
            carbs: props.carbs,
            protein: props.protein,
          }}
        />
        <NutriBullets
          nutriValues={{
            fat: props.fat,
            carbs: props.carbs,
            protein: props.protein,
          }}
          factor={props.factor}
        />
      </VStack>
    </Box>
  );
}

function NutriInformation(props: {
  planCells: PlanCellWithIndex[];
  foodLookup: FoodLookup;
  purchaseLookup: PurchaseLookup;
  personLookup: PersonLookup;
}) {
  const nutriValues = flatMap(
    props.planCells.filter((pc) => !isDeleted(pc.planCell)),
    (pC) =>
      mapPlanCellToNutriValues(
        pC.planCell,
        props.foodLookup,
        props.purchaseLookup,
      ),
  );

  let needForDate: NeedTimeslice | undefined;
  if (!!props.planCells.length) {
    const coordinates = computePlanCellCoordinatesFromPlanCellWithIndex(
      props.planCells[0],
    );
    const selectedPerson = props.personLookup[coordinates.personId];
    needForDate = getNeedForDate(selectedPerson.needs, coordinates.date);
  } else {
    needForDate = undefined;
  }

  return needForDate ? (
    <AllNutriValues
      nutriValues={nutriValues}
      needForDate={needForDate}
      mode={"horizontal"}
    />
  ) : (
    <></>
  );
}

export function EditMealDrawer(props: {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  planCells?: PlanCellWithIndex[];
  mode: PlanMode;
  updateParentCells?: Updater<PlanCell[]>;
}) {
  const toast = useToast();
  const [planCells, updatePlanCells] = useImmer(props.planCells || []);
  useEffect(() => {
    updatePlanCells(props.planCells || []);
  }, [props.open]);

  useEffect(() => {
    if (
      planCells.filter((pC) => !isDeleted(pC.planCell)).length == 0 &&
      props.open
    ) {
      onCancel();
    }
  }, [planCells]);
  const { foodLookup, loadFood } = useContext(FoodLookupWithRefreshContext);
  const { loadPurchase, purchaseLookup } = useContext(
    PurchaseLookupWithRefreshContext,
  );
  const personLookup = useContext(PersonLookupContext);

  const { submitPlanCells } = useContext(MealPlannerContext);

  const distinctPersons = uniq(
    planCells
      .filter((planCell) => !planCell.planCell.deleted)
      .map(computePlanCellCoordinatesFromPlanCellWithIndex)
      .map((c) => c.personId),
  );
  const personPart = distinctPersons
    .map((p) => personLookup![p].name)
    .join(" und ");
  const firstCoordinates = planCells.length
    ? computePlanCellCoordinatesFromPlanCellWithIndex(planCells[0])
    : undefined;
  const prefix = firstCoordinates
    ? `${mealInds[firstCoordinates.mealInd]?.name || ""} am ${formatGermanDateShort(firstCoordinates.date)}
  für ${personPart}`
    : "";
  const heading =
    !!planCells.length && personLookup
      ? `${prefix} ${props.mode == "plan" ? "planen" : "verzehren"}`
      : "";

  const mealsWithoutIntakes = planCells.filter(
    (planCell) =>
      props.mode == "intake" &&
      planCell.planCell.indicator == "MealWithIntakes" &&
      planCell.planCell.intakes == undefined,
  );
  if (mealsWithoutIntakes.length) {
    updatePlanCells((planCells) => {
      for (const planCell of planCells) {
        if (
          props.mode == "intake" &&
          planCell.planCell.indicator == "MealWithIntakes" &&
          planCell.planCell.intakes == undefined
        ) {
          const mealWithIntakes = planCell!.planCell as MealWithIntakes;
          mealWithIntakes.intakes = createDefaultIntakes(
            mealWithIntakes,
            purchaseLookup!,
          );
        }
      }
    });
  }

  const duplicateDishes = groupBy(
    planCells.filter(
      (planCell) =>
        !planCell.planCell.deleted &&
        planCell.planCell.indicator == "MealWithIntakes",
    ),
    (planCell) => {
      const meal = (planCell.planCell as MealWithIntakes).meal;
      return getMealKey(meal);
    },
  );

  const onCancel = () => {
    props.updateParentCells &&
      props.updateParentCells(
        planCells.map((pcI) => pcI.planCell).filter((pc) => !isDeleted(pc)),
      );
    props.onClose();
  };

  const { loadPersonDishByDish, personDishByDishLookup, personDishLookup } =
    useContext(PersonDishByDishLookupContext);
  const drawerHistoryContext = useContext(DrawerHistoryContext);

  const {
    isOpen: isOpenCreateDish,
    onOpen: onOpenCreateDish,
    onClose: onCloseCreateDish,
  } = usePlan2PlateDisclosure(drawerHistoryContext);

  const [editedDishPlanCellIndex, setEditedDishPlanCellIndex] = useState<
    number | undefined
  >();

  const [dishModification, setDishModification] = useState<
    DishModification | undefined
  >();

  const {
    isOpen: isOpenEditDish,
    onOpen: onOpenEditDish,
    onClose: onCloseEditDish,
  } = usePlan2PlateDisclosure(drawerHistoryContext);

  const [dishFromPlanCell, setDishFromPlanCell] = useState(
    emptyDish(personLookup!),
  );

  const defaultOpenItems = keys(
    pickBy(
      planCells,
      (pC) =>
        pC.planCell.indicator == "JustIntake" ||
        !!pC.planCell.intakes ||
        pC.planCell.meal.mealIngredients.find(
          (mi) =>
            mi.amount <= 0 ||
            (isNullIndex(mi.purchaseUnitId) && isNullIndex(mi.foodUnitId)),
        ),
    ),
  ).map(Number);

  const invalidItems = planCells.filter(
    (pC) =>
      !pC.planCell.deleted &&
      ((pC.planCell.indicator == "JustIntake" &&
        (pC.planCell.amount <= 0 || isNullIndex(pC.planCell.purchaseUnitId))) ||
        (pC.planCell.indicator == "MealWithIntakes" &&
          (!!pC.planCell.intakes?.find(
            (intake) =>
              intake.amount <= 0 || isNullIndex(intake.purchaseUnitId),
          ) ||
            pC.planCell.meal.mealIngredients.find(
              (mi) =>
                mi.amount <= 0 ||
                (isNullIndex(mi.purchaseUnitId) && isNullIndex(mi.foodUnitId)),
            )))),
  );

  function formatDescription(
    isDuplicate: boolean,
    currentPerson: Person | undefined,
    planCell: PlanCell,
  ) {
    return (
      (isDuplicate ? `${currentPerson?.name || ""}: ` : "") +
      formatPlanCellDescription(planCell, purchaseLookup)
    );
  }

  const {
    isOpen: isOpenCreatePurchase,
    onOpen: onOpenCreatePurchase,
    onClose: onCloseCreatePurchase,
  } = usePlan2PlateDisclosure(drawerHistoryContext);

  const [foodForPurchaseDrawer, setFoodForPurchaseDrawer] = useState<
    Food | undefined
  >();

  const [intakePathForPurchaseDrawer, setIntakePathForPurchaseDrawer] =
    useState<string | undefined>();

  const addPurchase = (intakePath: string) => {
    const intake = get(planCells, intakePath);
    const purchase = purchaseLookup[intake.purchaseUnitPurchaseId];
    const food = foodLookup[purchase.foodId];
    setFoodForPurchaseDrawer(food);
    setIntakePathForPurchaseDrawer(intakePath);
    onOpenCreatePurchase();
  };

  return (
    <>
      <EditDishDrawer
        dish={dishModification}
        onClose={onCloseEditDish}
        open={isOpenEditDish}
        onSubmit={async (d) => {
          if (editedDishPlanCellIndex != undefined) {
            await loadPersonDishByDish();
            const oldPlanCell = planCells[editedDishPlanCellIndex];
            const newPlanCell = await computePlanCell(
              computePlanCellCoordinatesFromPlanCellWithIndex(oldPlanCell),
              {
                id: d.id!,
                indicator: "DISH",
              },
            )();
            updatePlanCells((draft) => {
              draft[editedDishPlanCellIndex].planCell = newPlanCell[0];
            });
          }
        }}
      />
      <CreateDishDrawer
        initialDish={dishFromPlanCell}
        onClose={onCloseCreateDish}
        open={isOpenCreateDish}
        onSubmit={async (d) => {
          await loadPersonDishByDish();

          const newPlanCells = await computePlanCell(
            computePlanCellCoordinatesFromPlanCellWithIndex(
              (planCells || [])[0],
            ),
            {
              id: d.id!,
              indicator: "DISH",
            },
          )();
          updatePlanCells(
            newPlanCells.map((planCell) => {
              return {
                index: -1,
                planCell,
              };
            }),
          );
        }}
      />
      <CreatePurchaseDrawer
        onClose={onCloseCreatePurchase}
        open={isOpenCreatePurchase}
        onSubmit={(p) => {
          updatePlanCells((planCellDrafts) => {
            const currentIntake: IntakeWithEditedFlags = get(
              planCellDrafts,
              intakePathForPurchaseDrawer!,
            );
            currentIntake.purchaseUnitPurchaseId = p.id;
            currentIntake.purchaseUnitPurchaseDescription = p.description;
            currentIntake.purchaseUnitId = -1;
            currentIntake.purchaseUnitDescription = "";
            currentIntake.amount = 0;
          });
        }}
        food={foodForPurchaseDrawer}
      />
      <Plan2PlateDrawer
        onClose={onCancel}
        open={props.open}
        body={
          <VStack width={"100%"}>
            <Accordion
              width={"100%"}
              defaultIndex={defaultOpenItems}
              allowMultiple
            >
              {(planCells || []).map((planCellWithIndex, index) => {
                const planCell = planCellWithIndex.planCell;
                if (planCell.deleted) {
                  return;
                }

                const duplicates =
                  planCell.indicator == "MealWithIntakes"
                    ? duplicateDishes[`dish${planCell.meal.dishId}`] ||
                      duplicateDishes[`food${planCell.meal.foodId}`] ||
                      duplicateDishes[`purchase${planCell.meal.purchaseId}`] ||
                      []
                    : [];
                const coordinates =
                  computePlanCellCoordinatesFromPlanCellWithIndex(
                    planCellWithIndex,
                  );
                const currentPerson = personLookup![coordinates.personId];
                const description = formatDescription(
                  duplicates.length > 1,
                  currentPerson,
                  planCell,
                );
                const headingComponent = (
                  <Plan2PlateAccordionHeading description={description}>
                    <Plan2PlateIcon
                      icon={"pencil"}
                      onClick={async (e) => {
                        const dishModification = await loadDishModification(
                          (planCells[index].planCell as MealWithIntakes).meal
                            .dishId,
                        );
                        setDishModification(dishModification);
                        setEditedDishPlanCellIndex(index);
                        onOpenEditDish();
                        e.preventDefault();
                      }}
                    />
                    <Plan2PlateIcon
                      icon={"delete"}
                      onClick={(e) => {
                        updatePlanCells((draft) => {
                          draft[index].planCell.deleted = true;
                        });
                        e.preventDefault();
                      }}
                    />
                  </Plan2PlateAccordionHeading>
                );

                function updateMultipleCellsWithIntakes(
                  coordinatesForOtherPersons: PlanCellCoordinates[],
                  intakes: IntakeWithEditedFlags[],
                ) {
                  updatePlanCells((planCellsDraft) => {
                    for (const withIndexDraft of planCellsDraft || []) {
                      const arrayCoordinates =
                        computePlanCellCoordinatesFromPlanCellWithIndex(
                          withIndexDraft,
                        );
                      const update =
                        coordinatesForOtherPersons.find((c) =>
                          sameCoordinates(c, arrayCoordinates),
                        ) != undefined;
                      if (update) {
                        const mealWithIntake =
                          withIndexDraft.planCell as MealWithIntakes;
                        mealWithIntake.intakes = intakes.map((intake) => {
                          return {
                            ...intake,
                            personId: arrayCoordinates.personId,
                            mealId: mealWithIntake.meal.id,
                          };
                        });
                      }
                    }
                  });
                }

                const copyForOthers = () => {
                  if (planCell.indicator != "MealWithIntakes") {
                    return;
                  }
                  const mealsForOtherPersons = duplicates.filter(
                    (m) =>
                      (m.planCell as MealWithIntakes).meal.personId !=
                      coordinates.personId,
                  );
                  const coordinatesForOtherPersons = mealsForOtherPersons.map(
                    computePlanCellCoordinatesFromPlanCellWithIndex,
                  );
                  updateMultipleCellsWithIntakes(
                    coordinatesForOtherPersons,
                    planCell.intakes || [],
                  );
                };

                const distribute = () => {
                  if (planCell.indicator != "MealWithIntakes") {
                    return;
                  }
                  const newIntakes = produce((draft) => {
                    for (const intake of draft) {
                      intake.amount = round(
                        (intake.amount || 0) / duplicates.length,
                        2,
                      );
                    }
                  }, planCell.intakes || []);
                  const coordinates = duplicates.map(
                    computePlanCellCoordinatesFromPlanCellWithIndex,
                  );
                  updateMultipleCellsWithIntakes(coordinates, newIntakes());
                };

                function computeJustIntakeProps() {
                  if (planCell.indicator != "JustIntake") {
                    return undefined;
                  }
                  const intake = planCell as JustIntake;
                  const purchase =
                    purchaseLookup[intake.purchaseUnitPurchaseId];
                  const purchaseUnit = purchase.purchaseUnits.find(
                    (fu) => fu.id == intake.purchaseUnitId,
                  );
                  const { carbs, fat, kcal, protein } = nutriValuesFromIntake(
                    intake,
                    purchaseLookup,
                  );
                  const nutriBarFactor =
                    intake.amount * (purchaseUnit?.netWeight || 0);
                  return {
                    intake,
                    purchase,
                    purchaseUnit,
                    carbs,
                    fat,
                    kcal,
                    protein,
                    nutriBarFactor,
                  };
                }

                const justIntakeProps = computeJustIntakeProps();

                const intakePath = `[${index}].planCell`;
                const getCurrentIntake = (
                  pcs: Array<Draft<PlanCellWithIndex>>,
                ): IntakeWithEditedFlags => get(pcs, intakePath);

                let missingFoods: Food[];
                if (
                  planCell.indicator == "MealWithIntakes" &&
                  planCell.meal.dishId != undefined
                ) {
                  const personDish =
                    personDishByDishLookup[planCell.meal.dishId];
                  const foodsInDish = personDish.personRecipeUnitDtos.map(
                    (pr) => pr.basicRecipeFoodUnitFoodId,
                  );
                  if (props.mode == "plan") {
                    missingFoods = foodsInDish
                      .filter(
                        (foodId) =>
                          !planCell.meal.mealIngredients.find(
                            (mi) => mi.foodUnitFoodId == foodId,
                          ),
                      )
                      .map((id) => foodLookup[id]);
                  } else {
                    missingFoods = foodsInDish
                      .filter(
                        (foodId) =>
                          !planCell.intakes?.find(
                            (i) =>
                              purchaseLookup[i.purchaseUnitPurchaseId]
                                ?.foodId == foodId,
                          ),
                      )
                      .map((id) => foodLookup[id]);
                  }
                } else {
                  missingFoods = [];
                }

                return (
                  <AccordionItem key={index}>
                    <>
                      {headingComponent}
                      {planCell.indicator == "MealWithIntakes" &&
                      props.mode == "plan" ? (
                        <AccordionPanel
                          motionProps={{ animateOpacity: false }}
                          style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingTop: 5,
                          }}
                        >
                          <VStack>
                            {planCell.meal.mealIngredients.map(
                              (mi, miIndex) => {
                                if (isNullIndex(mi.purchaseUnitPurchaseId)) {
                                  const foodUnitFoodId = mi.foodUnitFoodId;
                                  const food = foodLookup[foodUnitFoodId];
                                  const foodUnit = food?.foodUnits.find(
                                    (fu) => fu.id == mi.foodUnitId,
                                  );
                                  const { carbs, fat, kcal, protein } =
                                    nutriValuesFromMealIngredient(
                                      mi,
                                      foodLookup,
                                      purchaseLookup,
                                    );
                                  return (
                                    <FoodIngredientBox
                                      key={food?.id || "undefined"}
                                      food={food}
                                      onChangeFood={(f) => {
                                        f?.id &&
                                          updatePlanCells((pcs) => {
                                            (
                                              pcs[index]
                                                .planCell as MealWithIntakes
                                            ).meal.mealIngredients[
                                              miIndex
                                            ].foodUnitFoodId = f?.id || -1;
                                          });
                                      }}
                                      foodChoices={missingFoods}
                                      foodUnit={foodUnit}
                                      onChangeFoodUnit={(pu) => {
                                        pu?.id &&
                                          updatePlanCells((pcs) => {
                                            (
                                              pcs[index]
                                                .planCell as MealWithIntakes
                                            ).meal.mealIngredients[
                                              miIndex
                                            ].foodUnitId = pu?.id || -1;
                                          });
                                      }}
                                      amount={mi.amount}
                                      onChangeAmount={(e) => {
                                        updatePlanCells((pcs) => {
                                          (
                                            pcs[index]
                                              .planCell as MealWithIntakes
                                          ).meal.mealIngredients[
                                            miIndex
                                          ].amount = Number(
                                            e.target.value || "0",
                                          );
                                        });
                                      }}
                                      onDelete={() => {
                                        updatePlanCells((pcs) => {
                                          (
                                            pcs[index]
                                              .planCell as MealWithIntakes
                                          ).meal.mealIngredients.splice(
                                            miIndex,
                                            1,
                                          );
                                        });
                                      }}
                                      kcal={kcal}
                                      fat={fat}
                                      carbs={carbs}
                                      protein={protein}
                                      factor={100}
                                    />
                                  );
                                } else {
                                  const purchaseUnitPurchaseId =
                                    mi.purchaseUnitPurchaseId;
                                  const purchase =
                                    purchaseLookup[purchaseUnitPurchaseId];
                                  const linkedFood =
                                    foodLookup[purchase.foodId];
                                  const purchaseUnit =
                                    purchase?.purchaseUnits.find(
                                      (fu) => fu.id == mi.purchaseUnitId,
                                    );
                                  const { carbs, fat, kcal, protein } =
                                    nutriValuesFromMealIngredient(
                                      mi,
                                      foodLookup,
                                      purchaseLookup,
                                    );
                                  return (
                                    <PurchaseIngredientBox
                                      key={purchase?.id || "undefined"}
                                      purchase={purchase}
                                      onChangePurchase={(f) => {
                                        f?.id &&
                                          updatePlanCells((pcs) => {
                                            (
                                              pcs[index]
                                                .planCell as MealWithIntakes
                                            ).meal.mealIngredients[
                                              miIndex
                                            ].purchaseUnitPurchaseId =
                                              f?.id || -1;
                                          });
                                      }}
                                      purchaseUnit={purchaseUnit}
                                      onChangePurchaseUnit={(fu) => {
                                        fu?.id &&
                                          updatePlanCells((pcs) => {
                                            (
                                              pcs[index]
                                                .planCell as MealWithIntakes
                                            ).meal.mealIngredients[
                                              miIndex
                                            ].purchaseUnitId = fu?.id || -1;
                                          });
                                      }}
                                      amount={mi.amount}
                                      onChangeAmount={(e) => {
                                        updatePlanCells((pcs) => {
                                          (
                                            pcs[index]
                                              .planCell as MealWithIntakes
                                          ).meal.mealIngredients[
                                            miIndex
                                          ].amount = Number(
                                            e.target.value || "0",
                                          );
                                        });
                                      }}
                                      onDelete={() => {
                                        updatePlanCells((pcs) => {
                                          (
                                            pcs[index]
                                              .planCell as MealWithIntakes
                                          ).meal.mealIngredients.splice(
                                            miIndex,
                                            1,
                                          );
                                        });
                                      }}
                                      kcal={kcal}
                                      fat={fat}
                                      carbs={carbs}
                                      protein={protein}
                                      factor={100}
                                      baseUnit={linkedFood.baseUnit}
                                    />
                                  );
                                }
                              },
                            )}
                            {!!missingFoods.length && (
                              <AddButton
                                onClick={() => {
                                  const newIngredient: MealIngredient = {
                                    ...initialNewMealIngredient,
                                  };
                                  updatePlanCells((pcs) => {
                                    (
                                      pcs[index].planCell as MealWithIntakes
                                    ).meal.mealIngredients.push(newIngredient);
                                  });
                                }}
                              />
                            )}
                          </VStack>
                        </AccordionPanel>
                      ) : planCell.indicator == "MealWithIntakes" &&
                        props.mode == "intake" ? (
                        <AccordionPanel
                          motionProps={{ animateOpacity: false }}
                          style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingTop: 5,
                          }}
                        >
                          <VStack>
                            {(planCell.intakes || []).map(
                              (intake, intakeIndex) => {
                                const purchaseUnitPurchaseId =
                                  intake.purchaseUnitPurchaseId;
                                const purchase: Purchase | undefined =
                                  purchaseLookup[purchaseUnitPurchaseId];
                                const purchaseUnit =
                                  purchase?.purchaseUnits.find(
                                    (fu) => fu.id == intake.purchaseUnitId,
                                  );
                                const { carbs, fat, kcal, protein } =
                                  nutriValuesFromIntake(intake, purchaseLookup);
                                const nutriBarFactor =
                                  intake.amount *
                                  (purchaseUnit?.netWeight || 0);
                                const intakePath = `[${index}].planCell.intakes[${intakeIndex}]`;

                                function getCurrentIntake(
                                  pcs: Array<Draft<PlanCellWithIndex>>,
                                ) {
                                  return get(pcs, intakePath);
                                }

                                const missingFoodIds = missingFoods.map(
                                  (f) => f.id,
                                );

                                const selectablePurchases = values(
                                  purchaseLookup,
                                ).filter((p) =>
                                  missingFoodIds.includes(p.foodId),
                                );

                                return isDeleted(intake) ? (
                                  <></>
                                ) : (
                                  <IntakeBox
                                    key={intakeIndex}
                                    purchase={purchase}
                                    purchaseChoices={selectablePurchases}
                                    purchaseUnit={purchaseUnit}
                                    onChangePurchase={(p) => {
                                      p?.id &&
                                        updatePlanCells((pcs) => {
                                          getCurrentIntake(pcs).edited = true;
                                          getCurrentIntake(
                                            pcs,
                                          ).purchaseUnitPurchaseId =
                                            p?.id || -1;
                                        });
                                    }}
                                    onChangePurchaseUnit={(pu) => {
                                      pu?.id &&
                                        updatePlanCells((pcs) => {
                                          getCurrentIntake(pcs).edited = true;
                                          getCurrentIntake(pcs).purchaseUnitId =
                                            pu?.id || -1;
                                        });
                                    }}
                                    amount={intake.amount}
                                    onChangeAmount={(amount) => {
                                      updatePlanCells((pcs) => {
                                        getCurrentIntake(pcs).edited = true;
                                        getCurrentIntake(pcs).amount =
                                          Number(amount);
                                      });
                                    }}
                                    onDelete={() => {
                                      updatePlanCells((pcs) => {
                                        const intakes = (
                                          pcs[index].planCell as MealWithIntakes
                                        ).intakes!;
                                        if (
                                          isNullIndex(intakes[intakeIndex].id)
                                        ) {
                                          intakes.splice(intakeIndex, 1);
                                        } else {
                                          intakes[intakeIndex].deleted = true;
                                        }
                                      });
                                    }}
                                    addPurchase={() => addPurchase(intakePath)}
                                    kcal={kcal}
                                    fat={fat}
                                    carbs={carbs}
                                    protein={protein}
                                    factor={nutriBarFactor}
                                  />
                                );
                              },
                            )}
                            {!!missingFoods.length && (
                              <AddButton
                                onClick={() => {
                                  const { date, mealInd, personId } =
                                    computePlanCellCoordinatesFromPlanCellWithIndex(
                                      planCellWithIndex,
                                    );
                                  const newIntake: Intake = {
                                    ...initialIntake(
                                      date,
                                      (planCell as MealWithIntakes).meal.id,
                                      mealInd,
                                      personId,
                                    ),
                                  };
                                  updatePlanCells((pcs) => {
                                    (
                                      pcs[index].planCell as MealWithIntakes
                                    ).intakes?.push(newIntake);
                                  });
                                }}
                              />
                            )}
                            {duplicates.length > 1 && (
                              <NutriInformation
                                planCells={planCells.filter(
                                  (pC) =>
                                    computePlanCellCoordinatesFromPlanCellWithIndex(
                                      pC,
                                    ).personId == coordinates.personId,
                                )}
                                foodLookup={foodLookup}
                                purchaseLookup={purchaseLookup}
                                personLookup={personLookup!}
                              />
                            )}
                            {duplicates.length > 1 && (
                              <HStack>
                                <Plan2PlateIconButton
                                  aria-label={"copyForOthers"}
                                  title={"Für andere Personen übernehmen"}
                                  icon={"copyValues"}
                                  onClick={copyForOthers}
                                />
                                <Plan2PlateIconButton
                                  aria-label={"distribute"}
                                  title={"Aufteilen"}
                                  icon={"splitValues"}
                                  onClick={distribute}
                                />
                              </HStack>
                            )}
                          </VStack>
                        </AccordionPanel>
                      ) : (
                        justIntakeProps && (
                          <AccordionPanel
                            motionProps={{ animateOpacity: false }}
                            style={{
                              paddingLeft: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingTop: 5,
                            }}
                          >
                            <VStack>
                              <IntakeBox
                                purchase={justIntakeProps.purchase}
                                purchaseUnit={justIntakeProps.purchaseUnit}
                                onChangePurchase={(p) => {
                                  p?.id &&
                                    updatePlanCells((pcs) => {
                                      getCurrentIntake(
                                        pcs,
                                      ).purchaseUnitPurchaseId = p?.id || -1;
                                    });
                                }}
                                onChangePurchaseUnit={(pu) => {
                                  pu?.id &&
                                    updatePlanCells((pcs) => {
                                      getCurrentIntake(pcs).purchaseUnitId =
                                        pu?.id || -1;
                                    });
                                }}
                                amount={justIntakeProps.intake.amount}
                                onChangeAmount={(amount) => {
                                  updatePlanCells((pcs) => {
                                    getCurrentIntake(pcs).amount =
                                      Number(amount);
                                  });
                                }}
                                addPurchase={() => addPurchase(intakePath)}
                                kcal={justIntakeProps.kcal}
                                fat={justIntakeProps.fat}
                                carbs={justIntakeProps.carbs}
                                protein={justIntakeProps.protein}
                                factor={justIntakeProps.nutriBarFactor}
                              />
                            </VStack>
                          </AccordionPanel>
                        )
                      )}
                    </>
                  </AccordionItem>
                );
              })}
            </Accordion>
            {distinctPersons.length == 1 && (
              <NutriInformation
                planCells={planCells}
                foodLookup={foodLookup}
                purchaseLookup={purchaseLookup}
                personLookup={personLookup!}
              />
            )}
          </VStack>
        }
        header={<Plan2PlateDrawerHeader heading={heading} />}
        footer={
          <HStack gap={3}>
            {props.mode == "plan" &&
              planCells.filter((p) => !p.planCell.deleted).length > 1 &&
              !invalidItems.length && (
                <Plan2PlateIconButton
                  aria-label={"create-dish"}
                  title={"Als Gericht anlegen"}
                  icon={"dish"}
                  onClick={() => {
                    const mealIngredients = flatMap(planCells, (pC) =>
                      pC.planCell.indicator == "MealWithIntakes"
                        ? pC.planCell.meal.mealIngredients
                        : [],
                    ).filter((mi) => isNullIndex(mi.purchaseUnitPurchaseId));
                    const insertData = values(
                      groupBy(mealIngredients, (mi) => mi.foodUnitFoodId),
                    ).map((miForFood) => {
                      const foodId = miForFood[0].foodUnitFoodId;
                      const food = foodLookup[foodId];
                      const getFoodUnit = (fuId: number) =>
                        food.foodUnits.find((fu) => fu.id == fuId)!;
                      const minUnit: FoodUnit = minBy(
                        map(miForFood, (mi) => getFoodUnit(mi.foodUnitId)),
                        (fu) => fu.netWeight,
                      )!;
                      const amount = sum(
                        miForFood.map((mi) => {
                          const unit = getFoodUnit(mi.foodUnitId);
                          const factor = unit.netWeight! / minUnit.netWeight!;
                          return mi.amount * factor;
                        }),
                      );
                      return {
                        amount,
                        foodId: food.id,
                        foodUnitId: minUnit.id,
                        name: formatFoodDescription(food),
                      };
                    });
                    const newDish: DishModification = {
                      ...emptyDish(personLookup!),
                      recipeUnitModificationDtos: sortBy(
                        insertData,
                        "name",
                      ).map((insert) => {
                        return {
                          id: -1,
                          foodUnitId: insert.foodUnitId,
                          foodId: insert.foodId,
                          personRecipeUnitModificationDtos: values(
                            personLookup,
                          ).map((person) => {
                            return {
                              id: undefined,
                              personId: person.id,
                              amount: insert.amount,
                            };
                          }),
                        };
                      }),
                    };
                    setDishFromPlanCell(newDish);
                    onOpenCreateDish();
                  }}
                />
              )}
            <CancelButton onClick={onCancel} />
            <SaveButton
              save={async () => {
                if (invalidItems.length) {
                  const descriptions = invalidItems
                    .map((item) =>
                      formatDescription(false, undefined, item.planCell),
                    )
                    .join(", ");
                  showValidationMessage(
                    toast,
                    `Unvollständige Einträge: ${descriptions}`,
                  );
                  return false;
                }
                submitPlanCells(planCells);
                updatePlanCells([]);
                drawerHistoryContext.clearHistory();
                return false;
              }}
            />
          </HStack>
        }
      />
    </>
  );
}
