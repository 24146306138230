import { useImmer } from "use-immer";
import { HistoryState } from "../utils/plan-utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function useDrawerHistory() {
  const [history, updateHistory] = useImmer<HistoryState[]>([]);
  const [windowHistoryStackSize, setWindowHistoryStackSize] = useImmer(
    window.history.length,
  );

  const pushHistory = (s: HistoryState) => {
    updateHistory((historyDraft) => {
      historyDraft.push(s);
    });
  };
  const popHistory = () => {
    updateHistory((historyDraft) => {
      historyDraft.pop();
    });
  };

  const navigate = useNavigate();

  const clearHistory = () => {
    return new Promise<void>((resolve, reject) => {
      function handlePopState(e: PopStateEvent) {
        if (e.state.usr?.id) {
          goBack();
        } else {
          window.removeEventListener("popstate", handlePopState);
          resolve();
        }
      }

      function goBack() {
        navigate(-1);
      }

      try {
        window.addEventListener("popstate", handlePopState);
        goBack();

        updateHistory((historyDraft) => {
          historyDraft.splice(0, historyDraft.length);
        });
      } catch (e) {
        reject(e);
      }
    });
  };
  const hasHistory = (s: HistoryState["id"]) => {
    return !!history.find((l) => l.id == s);
  };

  useEffect(() => {
    const handlePopState = (e: PopStateEvent) => {
      const isBackwards = window.history.length <= windowHistoryStackSize;
      if (isBackwards) {
        popHistory();
      }
      setWindowHistoryStackSize(window.history.length);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [history]);
  return {
    history,
    pushHistory,
    popHistory,
    clearHistory,
    hasHistory,
    setWindowHistoryStackSize,
  };
}
