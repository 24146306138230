import React, { useEffect } from "react";
import {
  useAvailabilityByIdLookupLoader,
  useDepartmentLoader,
  usePurchaseLookupLoader,
  useShopLookupLoader,
  useShoppingListLoader,
} from "../loaders";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { flatMap, groupBy, keys, sortBy } from "lodash";
import { useImmer } from "use-immer";
import {
  ShoppingList,
  ShoppingListWithEditedFlags,
} from "../data/shopping-list";
import { NumberInput } from "../components/number-input";
import { Draft, produce } from "immer";
import { WithDeletedAndEdited } from "../utils/edit-utils";
import { axiosInstance } from "../keycloak";
import {
  computePurchaseByDefaultUnitLookup,
  formatPurchaseUnitWithAmountDescription,
  getDefaultPurchaseUnit,
} from "../utils/purchase-utils";
import { LoadingComponent } from "../components/loading-component";

type GroupedShoppingList = {
  shopId: number;
  departmentId: number;
  shoppingLists: ShoppingListWithEditedFlags[];
};

const ShoppingListPage = () => {
  const { shoppingList, loadShoppingList } = useShoppingListLoader();
  const { availabilityByIdLookup, loadAvailabilities } =
    useAvailabilityByIdLookupLoader();
  const { departmentLookup, loadDepartment } = useDepartmentLoader();
  const { loadShop, shopLookup } = useShopLookupLoader();
  const { loadPurchase, purchaseLookup } = usePurchaseLookupLoader();
  const [shoppingListSorted, updateShoppingListSorted] = useImmer<
    GroupedShoppingList[]
  >([]);

  const purchaseByDefaultUnitLookup =
    computePurchaseByDefaultUnitLookup(purchaseLookup);

  useEffect(() => {
    if (
      keys(departmentLookup).length > 0 &&
      keys(shopLookup).length > 0 &&
      keys(purchaseLookup).length > 0
    ) {
      const dictionary = groupBy(shoppingList, (s) =>
        [s.availabilityShopId, s.availabilityDepartmentId].join(),
      );
      const dictionaryKeys = keys(dictionary);
      const sortedKeys = sortBy(dictionaryKeys, (key) => {
        return key.split(",");
      });
      const groupedShoppingList = sortedKeys.map((key) => {
        const [shopId, departmentId] = key.split(",");
        const shoppingLists = sortBy(
          dictionary[key],
          (s) => purchaseLookup[s.availabilityPurchaseId].description,
        );
        return {
          shopId: parseInt(shopId),
          departmentId: parseInt(departmentId),
          shoppingLists,
        };
      });

      updateShoppingListSorted(groupedShoppingList);
    }
  }, [departmentLookup, shopLookup, shoppingList, purchaseLookup]);

  useEffect(() => {
    const editedShoppingLists = flatMap(
      shoppingListSorted,
      (s) => s.shoppingLists,
    ).filter((s) => s.edited);
    if (editedShoppingLists.length > 0) {
      axiosInstance.put(`/api/shopping-list`, editedShoppingLists).then(() => {
        loadShoppingList();
      });
    }
  }, [shoppingListSorted]);

  return (
    <LoadingComponent
      loadData={[
        loadShoppingList,
        loadAvailabilities,
        loadDepartment,
        loadShop,
        loadPurchase,
      ]}
    >
      <VStack className="stretch-stack-with-padding">
        <Accordion allowMultiple>
          {shoppingListSorted.map(
            ({ shopId, departmentId, shoppingLists }, rootIndex) => {
              const shop = shopLookup[shopId];
              const department = departmentLookup[departmentId];
              const fullyPurchased = (
                shoppingList: ShoppingList & WithDeletedAndEdited,
              ) => shoppingList.amountPurchased >= shoppingList.amount;
              const fullyPurchasedItems = shoppingLists.filter(fullyPurchased);
              const fontWeight = !shoppingLists.every(fullyPurchased)
                ? "bold"
                : undefined;

              return (
                <AccordionItem key={[shopId, departmentId].join()}>
                  <h2>
                    <AccordionButton>
                      <Box
                        as="span"
                        flex={1}
                        textAlign={"left"}
                        fontWeight={fontWeight}
                      >
                        {shop.description} {department.description}{" "}
                        {`${fullyPurchasedItems.length}/${shoppingLists.length}`}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel motionProps={{ animateOpacity: false }}>
                    <VStack>
                      {shoppingLists.map((shoppingList, childIndex) => {
                        const amountPurchasedUpdater = (
                          k: "amountPurchased",
                          v: ShoppingList["amountPurchased"],
                        ) => {
                          const updater = (
                            shoppingListDraft: Draft<GroupedShoppingList[]>,
                          ) => {
                            const relevantChild =
                              shoppingListDraft[rootIndex].shoppingLists[
                                childIndex
                              ];
                            relevantChild.edited = true;
                            relevantChild[k] = v;
                          };
                          updateShoppingListSorted(updater);
                          return produce(shoppingListSorted, updater)[rootIndex]
                            .shoppingLists[childIndex];
                        };
                        const purchase =
                          purchaseLookup[shoppingList.availabilityPurchaseId];
                        const defaultPurchaseUnit =
                          getDefaultPurchaseUnit(purchase);
                        return (
                          <HStack
                            justifyContent={"space-between"}
                            width={"100%"}
                            key={shoppingList.id}
                          >
                            <Checkbox
                              colorScheme={"teal"}
                              isChecked={fullyPurchased(shoppingList)}
                              onChange={(e) => {
                                amountPurchasedUpdater(
                                  "amountPurchased",
                                  e.target.checked ? shoppingList.amount : 0,
                                );
                              }}
                            />
                            <Text>
                              {formatPurchaseUnitWithAmountDescription(
                                purchase,
                                defaultPurchaseUnit,
                                shoppingList.amount,
                              )}
                            </Text>
                            <NumberInput
                              fieldDescription={defaultPurchaseUnit.description}
                              width={"100px"}
                              fieldName={"amountPurchased"}
                              value={shoppingList.amountPurchased}
                              updater={amountPurchasedUpdater}
                            />
                          </HStack>
                        );
                      })}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              );
            },
          )}
        </Accordion>
      </VStack>
    </LoadingComponent>
  );
};

export default ShoppingListPage;
