import React from "react";
import { ReactComponent as Back } from "../icons/back.svg";
import { ReactComponent as Barcode } from "../icons/barcode.svg";
import { ReactComponent as Breakfast } from "../icons/breakfast.svg";
import { ReactComponent as Calculator } from "../icons/abacus.svg";
import { ReactComponent as Calendar } from "../icons/calendar.svg";
import { ReactComponent as Cancel } from "../icons/cancel.svg";
import { ReactComponent as Check } from "../icons/check.svg";
import { ReactComponent as Cook } from "../icons/cook.svg";
import { ReactComponent as CopyValues } from "../icons/copy-values.svg";
import { ReactComponent as Day } from "../icons/calendar-day.svg";
import { ReactComponent as Delete } from "../icons/delete.svg";
import { ReactComponent as Dinner } from "../icons/dinner.svg";
import { ReactComponent as Dish } from "../icons/dish.svg";
import { ReactComponent as DotsHorizontal } from "../icons/dots-horizontal.svg";
import { ReactComponent as FastIntake } from "../icons/fast-intake.svg";
import { ReactComponent as FilterRemove } from "../icons/filter-remove.svg";
import { ReactComponent as Food } from "../icons/food.svg";
import { ReactComponent as Forward } from "../icons/forward.svg";
import { ReactComponent as Gear } from "../icons/gear.svg";
import { ReactComponent as History } from "../icons/history.svg";
import { ReactComponent as Intake } from "../icons/intake.svg";
import { ReactComponent as Keto } from "../icons/keto.svg";
import { ReactComponent as LowCarb } from "../icons/low-carb.svg";
import { ReactComponent as LowFat } from "../icons/low-fat.svg";
import { ReactComponent as Lunch } from "../icons/lunch.svg";
import { ReactComponent as MealPlan } from "../icons/meal-plan.svg";
import { ReactComponent as Next } from "../icons/next.svg";
import { ReactComponent as Pencil } from "../icons/pencil.svg";
import { ReactComponent as PinDestination } from "../icons/pin-destination.svg";
import { ReactComponent as Plus } from "../icons/plus-circle.svg";
import { ReactComponent as Purchase } from "../icons/purchase.svg";
import { ReactComponent as Restriction } from "../icons/restriction.svg";
import { ReactComponent as ShopCart } from "../icons/shop-cart.svg";
import { ReactComponent as Snack } from "../icons/snack.svg";
import { ReactComponent as SplitValues } from "../icons/split-values.svg";
import { ReactComponent as Store } from "../icons/store.svg";
import { ReactComponent as Today } from "../icons/calendar-today.svg";
import { ReactComponent as Undo } from "../icons/undo.svg";
import { ReactComponent as Unit } from "../icons/unit.svg";
import { ReactComponent as Unit_1 } from "../icons/unit_1.svg";
import { ReactComponent as Unit_100 } from "../icons/unit_100.svg";

export const plan2plateIcons = {
  back: <Back />,
  barcode: <Barcode />,
  breakfast: <Breakfast />,
  calculator: <Calculator />,
  calendar: <Calendar />,
  cancel: <Cancel />,
  check: <Check />,
  cook: <Cook />,
  copyValues: <CopyValues />,
  day: <Day />,
  delete: <Delete />,
  dinner: <Dinner />,
  dish: <Dish />,
  dotsHorizontal: <DotsHorizontal />,
  fastIntake: <FastIntake />,
  filterRemove: <FilterRemove />,
  food: <Food />,
  forward: <Forward />,
  gear: <Gear />,
  history: <History />,
  intake: <Intake />,
  keto: <Keto />,
  lowCarb: <LowCarb />,
  lowFat: <LowFat />,
  lunch: <Lunch />,
  mealPlan: <MealPlan />,
  next: <Next />,
  pencil: <Pencil />,
  pinDestination: <PinDestination />,
  plus: <Plus />,
  purchase: <Purchase />,
  restriction: <Restriction />,
  shopCart: <ShopCart />,
  snack: <Snack />,
  splitValues: <SplitValues />,
  store: <Store />,
  today: <Today />,
  undo: <Undo />,
  unit: <Unit />,
  unit_1: <Unit_1 />,
  unit_100: <Unit_100 />,
};
