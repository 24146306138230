import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  forwardRef,
  HStack,
  Link,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { StringInput } from "./string-input";
import { getLowerCaseBaseUnit, NutritionInput } from "./nutrition-input";
import React, { ForwardedRef, useEffect, useRef, useState } from "react";
import { useUpdate } from "../utils/use-update";
import { Purchase, PurchaseCreation, PurchaseUnit } from "../data/purchase";
import { computeCalories } from "../utils/calories-util";
import Food from "../data/food";
import { Link as ReactRouterLink } from "react-router-dom";
import { NumberInput } from "./number-input";
import { map } from "lodash";
import { SaveButton } from "./buttons/save-button";
import { Plan2PlateIconButton } from "./buttons/plan2-plate-icon-button";
import { DeleteButton } from "./buttons/delete-button";
import { axiosInstance } from "../keycloak";
import { useUnloadAlert } from "./use-unload-alert";
import { deepEquals } from "../utils/equal-utils";
import { BarcodeScanner } from "./barcode-scanner";
import { showValidationMessage } from "./show-validation-message";
import { formatFoodDescription } from "../utils/food-utils";
import {
  AvailabilityDrawer,
  useAvailabilityDrawer,
} from "./availability-drawer";
import { isDeleted } from "../utils/edit-utils";
import { AvailabilityButton } from "./buttons/availability-button";

interface PurchaseUnitProps {
  purchase: Purchase;
  purchaseUnit: PurchaseUnit;
  onRemove: () => void;
  onUpdate: (updatedUnit: PurchaseUnit) => void;
  disabled: boolean;
  baseUnit: "g" | "ml";
}

function isInvalidDescription(editedUnit: PurchaseUnit) {
  return editedUnit.description.length > 10;
}

const PurchaseUnitsInput = forwardRef<PurchaseUnitProps, any>(
  (
    { purchaseUnit, onRemove, onUpdate, disabled, ...props },
    ref: ForwardedRef<any>,
  ) => {
    const {
      editedObject: editedUnit,
      updateEditedObjectField: updateEditedUnitField,
    } = useUpdate(purchaseUnit);

    const handleBlur = (newValue: PurchaseUnit) => {
      onUpdate(newValue);
    };

    return (
      <Box borderWidth="1px" borderRadius="lg" padding="4" boxShadow="md">
        <FormControl>
          <Stack spacing="4">
            <FormLabel htmlFor={`portion-${purchaseUnit.id}`} fontSize="lg">
              Portion
            </FormLabel>
            {props.purchase?.purchaseRelevant && (
              <Checkbox
                colorScheme="teal"
                paddingRight="20px"
                isChecked={purchaseUnit.isDefault}
                onChange={(e) => {
                  const newValue = updateEditedUnitField(
                    "isDefault",
                    e.target.checked,
                  );
                  handleBlur(newValue);
                }}
              >
                Einkauf-Einheit
              </Checkbox>
            )}
            <StringInput
              ref={ref}
              fieldName="description"
              fieldDescription="Bezeichnung"
              isInvalid={isInvalidDescription(editedUnit)}
              value={editedUnit.description}
              updater={updateEditedUnitField}
              handleBlur={handleBlur}
              width={"100%"}
              disabled={disabled}
            />
            <HStack>
              <NumberInput
                fieldName="netWeight"
                fieldDescription="Netto"
                fieldUnit={props.baseUnit}
                value={editedUnit.netWeight}
                updater={updateEditedUnitField}
                handleBlur={handleBlur}
                width={"180px"}
                disabled={disabled}
              />
              {!disabled && <DeleteButton onClick={onRemove} />}
            </HStack>
          </Stack>
        </FormControl>
      </Box>
    );
  },
);

export type PurchaseEditorProps = {
  initialPurchase: Purchase;
  isCreate: boolean;
  food: Food;
  afterSave?: (createdPurchase: Purchase) => void;
};

function hasDefaultUnit(editedPurchase: Purchase | undefined) {
  return editedPurchase?.purchaseUnits.find((pu) => pu.isDefault) != undefined;
}

export const PurchaseEditor = ({
  initialPurchase,
  isCreate,
  afterSave,
  food,
}: PurchaseEditorProps) => {

  const {foodId} = initialPurchase;

  const {
    editedObject: editedPurchase,
    updateEditedObjectField: updateEditedPurchaseField,
    updateEditedObject: updateEditedPurchase,
  } = useUpdate<Purchase>(initialPurchase);

  useEffect(() => {
    updateEditedPurchase(initialPurchase);
  }, [foodId]);

  const { pristine, setPristine } = useUnloadAlert();

  useEffect(() => {
    setPristine(deepEquals(initialPurchase, editedPurchase));
  }, [editedPurchase]);

  const [nutriValuesConfirmed, setNutriValuesConfirmed] = useState(false);

  const toast = useToast();

  const addPurchaseUnit = () => {
    const newPurchaseUnitId = editedPurchase.purchaseUnits.length + 1;
    updateEditedPurchase((purchaseDraft) => {
      purchaseDraft.purchaseUnits.push({
        foodUnitId: -1,
        id: newPurchaseUnitId,
        description: "",
        netWeight: 0,
        isDefault: false,
      });
    });
  };

  const addPredefinedPurchaseUnit =
    (amount: 1 | 100, unit: "g" | "ml") => () => {
      updateEditedPurchase((foodDraft) => {
        foodDraft.purchaseUnits.push({
          id: -1,
          description: `${amount == 1 ? "" : amount}${unit}`,
          netWeight: amount,
          foodUnitId: -1,
          isDefault: false,
        });
      });
    };

  const hasPurchaseUnitWithAmount = (amount: number) =>
    editedPurchase.purchaseUnits.find((fu) => fu.netWeight == amount) !=
    undefined;

  const removePurchaseUnit = (index: number) => {
    updateEditedPurchase((purchaseDraft) => {
      purchaseDraft.purchaseUnits.splice(index, 1);
    });
  };

  const updatePurchaseUnit = (
    updatedPurchaseUnit: PurchaseUnit,
    purchaseUnitIndex: number,
  ) => {
    updateEditedPurchase((purchaseDraft) => {
      purchaseDraft.purchaseUnits[purchaseUnitIndex] = updatedPurchaseUnit;
    });
  };

  const save = async () => {
    const withInvalidDescription =
      editedPurchase.purchaseUnits.filter(isInvalidDescription);
    if (withInvalidDescription.length > 0) {
      const descriptions = map(withInvalidDescription, "description");
      toast({
        title: `Folgende Portionen haben eine zu lange Bezeichnung (>10): ${descriptions.join()}`,
        status: "error",
        isClosable: true,
      });
      return false;
    } else if (
      !hasDefaultUnit(editedPurchase) &&
      editedPurchase.purchaseRelevant
    ) {
      showValidationMessage(toast, "Keine Einkauf-Einheit definiert");
      return false;
    } else if (
      editedPurchase.purchaseRelevant &&
      editedPurchase.availabilities.length == 0
    ) {
      toast({
        title: "Keine Verfügbarkeiten definiert",
        status: "error",
        isClosable: true,
      });
      return false;
    } else {
      const purchaseCreation: PurchaseCreation = editedPurchase;
      const axiosResponse = isCreate
        ? await axiosInstance.post<Purchase>(`/api/purchase`, purchaseCreation)
        : await axiosInstance.put<Purchase>(`/api/purchase`, editedPurchase);
      afterSave && afterSave(axiosResponse.data);
      return true;
    }
  };

  const handleBlur = () => {};

  const doComputeCalories = () => {
    const calories = computeCalories(editedPurchase);
    updateEditedPurchaseField("kcal", calories);
  };
  const foodLink = (
    <Link as={ReactRouterLink} to={`/food/edit/${food.id}`}>
      {formatFoodDescription(food)}
    </Link>
  );

  const lowerCaseBaseUnit = getLowerCaseBaseUnit(food.baseUnit);

  const newItemRef = useRef<any>(null);

  useEffect(() => {
    if (newItemRef.current) {
      newItemRef.current.focus();
    }
  }, [editedPurchase.purchaseUnits.length]);

  const availabilityDrawerProps = useAvailabilityDrawer(
    async (availabilities) => {
      updateEditedPurchase((purchaseDraft) => {
        purchaseDraft.availabilities = availabilities.filter(
          (a) => !isDeleted(a),
        );
      });
      return false;
    },
    false,
  );

  const editAvailabilities = () => {
    availabilityDrawerProps.editAvailabilities(
      editedPurchase.id,
      editedPurchase.availabilities,
    );
  };

  return (
    <>
      <AvailabilityDrawer {...availabilityDrawerProps} />
      <Box className="stretch-stack">
        <Stack spacing="4">
          {!editedPurchase.isDefault && (
            <StringInput
              fieldName="brand"
              width={"100%"}
              value={editedPurchase.brand}
              updater={updateEditedPurchaseField}
              handleBlur={handleBlur}
              fieldDescription="Marke"
            />
          )}
          <StringInput
            fieldName="description"
            width={"100%"}
            value={editedPurchase.description}
            updater={updateEditedPurchaseField}
            handleBlur={handleBlur}
            fieldDescription="Bezeichnung"
          />
          {isCreate && (
            <Checkbox
              disabled={!isCreate}
              colorScheme="teal"
              paddingRight="20px"
              isChecked={editedPurchase.purchaseRelevant}
              onChange={(e) => {
                updateEditedPurchaseField("purchaseRelevant", e.target.checked);
              }}
            >
              Einkaufsliste-Relevant
            </Checkbox>
          )}
          <HStack>
            <NumberInput
              fieldName="ean"
              width={"100%"}
              value={editedPurchase.ean}
              updater={updateEditedPurchaseField}
              handleBlur={handleBlur}
              fieldDescription="EAN"
            />
            <BarcodeScanner
              onBarcodeScan={(ean) => {
                if (!ean) {
                  showValidationMessage(toast, "EAN wurde nicht erkannt");
                  return;
                }

                return updateEditedPurchaseField("ean", ean);
              }}
            />
          </HStack>
          <Box className="relative-flex-editor-box">
            <Text
              position={"absolute"}
              top={"-10px"}
              fontSize={"xs"}
              backgroundColor={"var(--chakra-colors-chakra-body-bg)"}
            >
              Nährwerte pro 100{lowerCaseBaseUnit}
            </Text>
            <VStack alignItems={"start"}>
              <HStack>
                <NutritionInput
                  fieldName="kcal"
                  value={editedPurchase.kcal}
                  updater={updateEditedPurchaseField}
                  handleBlur={handleBlur}
                  fieldDescription="Kalorien"
                />
                <Plan2PlateIconButton
                  aria-label={"computeCalories"}
                  title={"Kcal berechnen"}
                  icon={"calculator"}
                  onClick={doComputeCalories}
                />
              </HStack>
              <HStack flexWrap={"wrap"}>
                <NutritionInput
                  fieldName="fat"
                  value={editedPurchase.fat}
                  updater={updateEditedPurchaseField}
                  handleBlur={handleBlur}
                  fieldDescription="Fett"
                />
                <NutritionInput
                  fieldName="carbs"
                  value={editedPurchase.carbs}
                  updater={updateEditedPurchaseField}
                  handleBlur={handleBlur}
                  fieldDescription="KH"
                />
                <NutritionInput
                  fieldName="protein"
                  value={editedPurchase.protein}
                  updater={updateEditedPurchaseField}
                  handleBlur={handleBlur}
                  fieldDescription="Protein"
                />
              </HStack>
            </VStack>
          </Box>
          {isCreate && !nutriValuesConfirmed && (
            <Button onClick={() => setNutriValuesConfirmed(true)}>
              Nährwerte bestätigen
            </Button>
          )}
          {(!isCreate || nutriValuesConfirmed) && (
            <>
              {editedPurchase.purchaseUnits.map(
                (purchaseUnit, purchaseUnitIndex) => (
                  <PurchaseUnitsInput
                    ref={
                      purchaseUnitIndex ==
                      editedPurchase.purchaseUnits.length - 1
                        ? newItemRef
                        : undefined
                    }
                    purchase={editedPurchase}
                    baseUnit={lowerCaseBaseUnit}
                    disabled={editedPurchase.isDefault}
                    key={purchaseUnit.id}
                    purchaseUnit={purchaseUnit}
                    onRemove={() => removePurchaseUnit(purchaseUnitIndex)}
                    onUpdate={(purchaseUnit) =>
                      updatePurchaseUnit(purchaseUnit, purchaseUnitIndex)
                    }
                  />
                ),
              )}
              <HStack width={"100%"} justifyContent={"center"}>
                {!editedPurchase.isDefault && (
                  <>
                    <Plan2PlateIconButton
                      aria-label={"addPredefinedFoodUnit"}
                      title={`1${lowerCaseBaseUnit} hinzufügen`}
                      isDisabled={hasPurchaseUnitWithAmount(1)}
                      icon={"unit_1"}
                      onClick={addPredefinedPurchaseUnit(1, lowerCaseBaseUnit)}
                    />
                    <Plan2PlateIconButton
                      aria-label={"addPredefinedFoodUnit"}
                      title={`100${lowerCaseBaseUnit} hinzufügen`}
                      isDisabled={hasPurchaseUnitWithAmount(100)}
                      icon={"unit_100"}
                      onClick={addPredefinedPurchaseUnit(
                        100,
                        lowerCaseBaseUnit,
                      )}
                    />
                    <Plan2PlateIconButton
                      aria-label={"addPurchaseUnit"}
                      title={"Portion hinzufügen"}
                      icon={"unit"}
                      onClick={addPurchaseUnit}
                    />
                  </>
                )}
                <AvailabilityButton onClick={editAvailabilities} />
                <SaveButton isDisabled={pristine} save={save} />
              </HStack>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};
