import { Plan2PlateDrawer, Plan2PlateDrawerHeader } from "./plan2-plate-drawer";
import { ShopEditor } from "./shop-editor";
import React from "react";

export const EditShopsDrawer = (props: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Plan2PlateDrawer
      open={props.isOpen}
      onClose={props.onClose}
      header={<Plan2PlateDrawerHeader heading={"Geschäfte bearbeiten"} />}
      body={<ShopEditor onSave={props.onClose} />}
    />
  );
};
