export function formatIsoString(now: Date) {
  return now.toLocaleDateString("sv-SE");
}

export function todayForPlan() {
  return today();
}

export function today() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

export function parseDate(s: string) {
  const result = new Date(s);
  result.setHours(0, 0, 0, 0);
  return result;
}

export function formatGermanDate(datum: string) {
  const date = parseDate(datum);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export function formatGermanDateShort(datum: string) {
  const date = parseDate(datum);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
  });
}

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function isTodayOrPrevious(date: string) {
  return todayForPlan() >= parseDate(date);
}

export function differenceInDays(from: Date, to: Date) {
  return Math.floor((to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24));
}
