import React, { useCallback, useEffect, useState } from "react";
import {
  HStack,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { axiosGet } from "../loaders";
import { nutriLookup, NutriValues } from "../data/nutri-values";
import { ceil, floor } from "lodash";
import { Plan2PlateIconButton } from "./buttons/plan2-plate-icon-button";

export type NutriSliderContext = "food" | "purchase" | "dish";
export const useNutriSlider = (
  context: NutriSliderContext,
): NutriSliderProps => {
  const [minFat, setMinFat] = useState(0);
  const [maxFat, setMaxFat] = useState<number | undefined>(undefined);
  const [minCarbs, setMinCarbs] = useState(0);
  const [maxCarbs, setMaxCarbs] = useState<number | undefined>(undefined);
  const [minProtein, setMinProtein] = useState(0);
  const [maxProtein, setMaxProtein] = useState<number | undefined>(undefined);
  const [minKcal, setMinKcal] = useState(0);
  const [maxKcal, setMaxKcal] = useState<number | undefined>(undefined);
  return {
    context,
    minFat,
    setMinFat,
    maxFat,
    setMaxFat,
    minCarbs,
    setMinCarbs,
    maxCarbs,
    setMaxCarbs,
    minProtein,
    setMinProtein,
    maxProtein,
    setMaxProtein,
    minKcal,
    setMinKcal,
    maxKcal,
    setMaxKcal,
  };
};
export type NutriSliderProps = {
  context: NutriSliderContext;
  personId?: number;
  minFat: number;
  setMinFat: (v: number) => void;
  maxFat: number | undefined;
  setMaxFat: (v: number | undefined) => void;
  minCarbs: number;
  setMinCarbs: (v: number) => void;
  maxCarbs: number | undefined;
  setMaxCarbs: (v: number | undefined) => void;
  minProtein: number;
  setMinProtein: (v: number) => void;
  maxProtein: number | undefined;
  setMaxProtein: (v: number | undefined) => void;
  minKcal: number;
  setMinKcal: (v: number) => void;
  maxKcal: number | undefined;
  setMaxKcal: (v: number | undefined) => void;
};
export const NutriSlider = (props: NutriSliderProps) => {
  const [globalMaxFat, setGlobalMaxFat] = useState<number | undefined>(
    undefined,
  );
  const [globalMaxCarbs, setGlobalMaxCarbs] = useState<number | undefined>(
    undefined,
  );
  const [globalMaxProtein, setGlobalMaxProtein] = useState<number | undefined>(
    undefined,
  );
  const [globalMaxKcal, setGlobalMaxKcal] = useState<number | undefined>(
    undefined,
  );
  const [minFat, setMinFat] = useState(0);
  const [maxFat, setMaxFat] = useState<number | undefined>(undefined);
  const [minCarbs, setMinCarbs] = useState(0);
  const [maxCarbs, setMaxCarbs] = useState<number | undefined>(undefined);
  const [minProtein, setMinProtein] = useState(0);
  const [maxProtein, setMaxProtein] = useState<number | undefined>(undefined);
  const [minKcal, setMinKcal] = useState(0);
  const [maxKcal, setMaxKcal] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (maxFat == undefined) {
      setMaxFat(globalMaxFat);
    }
    if (maxCarbs == undefined) {
      setMaxCarbs(globalMaxCarbs);
    }
    if (maxProtein == undefined) {
      setMaxProtein(globalMaxProtein);
    }
    if (maxKcal == undefined) {
      setMaxKcal(globalMaxKcal);
    }
  }, [globalMaxFat, globalMaxCarbs, globalMaxProtein, globalMaxKcal]);

  const loadData = useCallback(async () => {
    const personParam = props.personId ? `?personId=${props.personId}` : "";
    const maxNutri = await axiosGet<NutriValues>(
      `/api/${props.context}/max-nutri${personParam}`,
    )();
    setGlobalMaxFat(maxNutri.fat);
    setGlobalMaxCarbs(maxNutri.carbs);
    setGlobalMaxProtein(maxNutri.protein);
    setGlobalMaxKcal(maxNutri.kcal);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    setMinFat(props.minFat);
  }, [props.minFat]);

  useEffect(() => {
    setMaxFat(props.maxFat);
  }, [props.maxFat]);

  useEffect(() => {
    setMinCarbs(props.minCarbs);
  }, [props.minCarbs]);

  useEffect(() => {
    setMaxCarbs(props.maxCarbs);
  }, [props.maxCarbs]);

  useEffect(() => {
    setMinProtein(props.minProtein);
  }, [props.minProtein]);

  useEffect(() => {
    setMaxProtein(props.maxProtein);
  }, [props.maxProtein]);

  useEffect(() => {
    setMinKcal(props.minKcal);
  }, [props.minKcal]);

  useEffect(() => {
    setMaxKcal(props.maxKcal);
  }, [props.maxKcal]);

  const rangeSlider = (
    label: React.JSX.Element,
    maxValue: number | undefined,
    minCurrent: number,
    maxCurrent: number | undefined,
    setMin: (value: number) => void,
    setMax: (value: number | undefined) => void,
    setMinEnd: (value: number) => void,
    setMaxEnd: (value: number | undefined) => void,
    sliderColor: string,
  ) =>
    maxValue == undefined ? (
      <Text>Loading...</Text>
    ) : (
      <HStack width={"100%"} alignItems={"end"}>
        <Text width={"100px"}>{label}</Text>
        <RangeSlider
          aria-label={["min", "max"]}
          max={maxValue}
          defaultValue={[0, maxValue]}
          marginTop={"20px"}
          onChange={(val) => {
            setMin(val[0]);
            setMax(val[1]);
          }}
          onChangeEnd={(val) => {
            setMinEnd(val[0]);
            setMaxEnd(val[1]);
          }}
          value={[minCurrent, maxCurrent || maxValue]}
        >
          <RangeSliderTrack>
            <RangeSliderFilledTrack backgroundColor={sliderColor} />
          </RangeSliderTrack>
          <RangeSliderThumb index={0} backgroundColor={sliderColor}>
            <Text fontSize={"xs"} marginBottom={"35px"}>
              {floor(minCurrent)}
            </Text>
          </RangeSliderThumb>
          <RangeSliderThumb index={1} backgroundColor={sliderColor}>
            <Text fontSize={"xs"} marginBottom={"35px"}>
              {maxCurrent ? ceil(maxCurrent) : maxValue ? ceil(maxValue) : ""}
            </Text>
          </RangeSliderThumb>
        </RangeSlider>
      </HStack>
    );

  const resetValues = () => {
    props.setMinKcal(0);
    props.setMaxKcal(globalMaxKcal);
    props.setMinFat(0);
    props.setMaxFat(globalMaxFat);
    props.setMinCarbs(0);
    props.setMaxCarbs(globalMaxCarbs);
    props.setMinProtein(0);
    props.setMaxProtein(globalMaxProtein);
  };
  return (
    <VStack>
      {rangeSlider(
        <>Kcal</>,
        globalMaxKcal,
        minKcal,
        maxKcal,
        setMinKcal,
        setMaxKcal,
        props.setMinKcal,
        props.setMaxKcal,
        nutriLookup["kcal"].color,
      )}
      {rangeSlider(
        <>Fett</>,
        globalMaxFat,
        minFat,
        maxFat,
        setMinFat,
        setMaxFat,
        props.setMinFat,
        props.setMaxFat,
        nutriLookup["fat"].color,
      )}
      {rangeSlider(
        <>KH</>,
        globalMaxCarbs,
        minCarbs,
        maxCarbs,
        setMinCarbs,
        setMaxCarbs,
        props.setMinCarbs,
        props.setMaxCarbs,
        nutriLookup["carbs"].color,
      )}
      {rangeSlider(
        <>Protein</>,
        globalMaxProtein,
        minProtein,
        maxProtein,
        setMinProtein,
        setMaxProtein,
        props.setMinProtein,
        props.setMaxProtein,
        nutriLookup["protein"].color,
      )}
      <Plan2PlateIconButton
        aria-label={"removeFilter"}
        title={"Zurücksetzen"}
        icon={"filterRemove"}
        onClick={resetValues}
      />
    </VStack>
  );
};
