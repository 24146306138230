type EnumValueType = {
  id: number;
  description: string;
};

type EnumValueWithTextType = EnumValueType & {
  text: string;
};

export const getValue = <
  EnumType extends string | number,
  ValueType extends EnumValueType,
>(
  record: Record<EnumType, ValueType>,
  key: EnumType | undefined,
): ValueType | undefined => {
  return key != undefined ? record[key] : undefined;
};

export enum FormulaEnum {
  Cunningham,
  HarrisBenedict,
  KatchMcArdle,
  MifflinStJeor,
  Oxford,
  Schofield,
}

export const formulas: Record<FormulaEnum, EnumValueWithTextType> = {
  [FormulaEnum.Cunningham]: {
    id: 0,
    description: "Cunningham",
    text: "Berücksichtigt die fettfreie Körpermasse, ideal für Sportler",
  },
  [FormulaEnum.HarrisBenedict]: {
    id: 1,
    description: "Harris Benedict",
    text: "Traditionelle Formel basierend auf Gewicht, Größe, Alter und Geschlecht",
  },
  [FormulaEnum.KatchMcArdle]: {
    id: 2,
    description: "Katch-McArdle",
    text: "Nutzt die fettfreie Körpermasse, gut für sportliche Personen",
  },
  [FormulaEnum.MifflinStJeor]: {
    id: 3,
    description: "Mifflin St Jeor",
    text: "Modernisierte Harris-Benedict, genauer für Übergewichtige",
  },
  [FormulaEnum.Oxford]: {
    id: 4,
    description: "Oxford",
    text: "Basierend auf Gewicht, Größe, Alter und Geschlecht; verschiedene Gleichungen für unterschiedliche Altersgruppen",
  },
  [FormulaEnum.Schofield]: {
    id: 5,
    description: "Schofield",
    text: "Berücksichtigt Gewicht, Alter und Geschlecht, häufig in wissenschaftlichen Studien verwendet",
  },
};

export enum GenderEnum {
  Male,
  Female,
  Diverse,
}

export const genders: Record<GenderEnum, EnumValueType> = {
  [GenderEnum.Male]: { id: 0, description: "Männlich" },
  [GenderEnum.Female]: { id: 1, description: "Weiblich" },
  [GenderEnum.Diverse]: { id: 2, description: "Divers" },
};

export enum ActivityLevelEnum {
  Sedentary,
  Light,
  Moderate,
  VeryActive,
  ExtraActive,
}

type ActivityLevelType = EnumValueWithTextType & {
  defaultFactor: number;
  maleFactor: number;
};

export const activityLevels: Record<ActivityLevelEnum, ActivityLevelType> = {
  [ActivityLevelEnum.Sedentary]: {
    id: 0,
    description: "Sitzend",
    text: "Kaum Bewegung außerhalb normaler Routine",
    defaultFactor: 1.2,
    maleFactor: 1.2,
  },
  [ActivityLevelEnum.Light]: {
    id: 1,
    description: "Leicht aktiv",
    text: "Bis zu 3 Mal pro Woche leichte sportliche Aktivitäten oder gelegentliche körperliche Betätigung",
    defaultFactor: 1.375,
    maleFactor: 1.5,
  },
  [ActivityLevelEnum.Moderate]: {
    id: 2,
    description: "Mäßig aktiv",
    text: "3 bis 5 Mal pro Woche Sport oder tägliche Arbeit mit der körperlichen Anstrengung",
    defaultFactor: 1.55,
    maleFactor: 1.7,
  },
  [ActivityLevelEnum.VeryActive]: {
    id: 3,
    description: "Sehr aktiv",
    text: "6 bis 7 Tage die Woche intensives Training oder einen Beruf mit hoher",
    defaultFactor: 1.725,
    maleFactor: 2,
  },
  [ActivityLevelEnum.ExtraActive]: {
    id: 4,
    description: "Extrem aktiv",
    text: "täglich mehrere Stunden intensives Training oder extrem körperlich anstrengende Arbeit",
    defaultFactor: 1.9,
    maleFactor: 2.2,
  },
};

export enum GoalEnum {
  Maintain,
  Lose,
  Gain,
}

export const goals: Record<GoalEnum, EnumValueType> = {
  [GoalEnum.Maintain]: { id: 0, description: "Gewicht halten" },
  [GoalEnum.Lose]: { id: 1, description: "Abnehmen" },
  [GoalEnum.Gain]: { id: 2, description: "Zunehmen" },
};
