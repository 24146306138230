import Food from "../data/food";
import { HStack, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { CreateFoodDrawer } from "./create-food-drawer";
import {
  Plan2PlateDrawer,
  Plan2PlateDrawerHeader,
  usePlan2PlateDisclosure,
} from "./plan2-plate-drawer";
import { SearchInput } from "./search-input";
import { AddButton } from "./buttons/add-button";
import {
  DrawerHistoryContext,
  FoodLookupWithRefreshContext,
} from "../contexts";
import InfiniteScroll from "react-infinite-scroll-component";
import { useImmer } from "use-immer";
import {
  computeResultType,
  getMealSourceKey,
  MealSource,
} from "../data/meal-source";
import { useNutriSlider } from "./nutri-slider";
import { getPerformSearch } from "../utils/search-utils";
import { baseUrl } from "../config";
import { DetailSearchResult, isRecent } from "./detail-search-result";

export function SearchForFoodDrawer(props: {
  onClose: () => void;
  open: boolean;
  selectFood: (food: Food) => void;
}) {
  const drawerHistoryContext = useContext(DrawerHistoryContext);
  const {
    isOpen: isOpenCreateFood,
    onOpen: onOpenCreateFood,
    onClose: onCloseCreateFood,
  } = usePlan2PlateDisclosure(drawerHistoryContext);
  const { foodLookup, loadFood } = useContext(FoodLookupWithRefreshContext);

  useEffect(() => {
    if (!props.open) {
      setSearchTerm("");
      updateSearchResults([]);
      setSearchResultCount(0);
    }
  }, [props.open]);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, updateSearchResults] = useImmer<MealSource[]>([]);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const nutriSliderProps = useNutriSlider("dish");
  const { performSearch, fetchMoreData } = getPerformSearch(
    undefined,
    nutriSliderProps,
    searchTerm,
    searchResults,
    updateSearchResults,
    setSearchResultCount,
    `${baseUrl}/api/mealsource/search`,
    10,
    "FOOD",
  );

  return (
    <>
      <CreateFoodDrawer
        onClose={onCloseCreateFood}
        open={isOpenCreateFood}
        onSubmit={(createdFood) => {
          props.selectFood(createdFood);
          props.onClose();
        }}
      />
      <Plan2PlateDrawer
        onClose={props.onClose}
        open={props.open}
        header={<Plan2PlateDrawerHeader heading={"Suche nach Lebensmitteln"} />}
        body={
          <>
            <HStack width={"100%"}>
              <SearchInput
                value={searchTerm}
                setSearchTerm={setSearchTerm}
                performSearch={performSearch}
                placeholder={"Suche nach Lebensmitteln"}
              />
              <AddButton onClick={onOpenCreateFood} />
            </HStack>
            <VStack
              id={"search-for-plan-cell-drawer-scroll-stack"}
              overflow={"auto"}
              width={"100%"}
              alignItems={"stretch"}
            >
              <InfiniteScroll
                scrollableTarget={"search-for-plan-cell-drawer-scroll-stack"}
                dataLength={searchResults.length}
                next={fetchMoreData}
                hasMore={searchResults.length < searchResultCount}
                loader={<h4>Loading...</h4>}
                style={{ width: "100%" }}
              >
                <VStack width={"100%"}>
                  {searchResults.map((searchResult) => {
                    const indicator = searchResult.indicator;
                    return (
                      <DetailSearchResult
                        key={getMealSourceKey(searchResult)}
                        extraTags={searchResult.extraTags}
                        title={searchResult.description}
                        resultType={computeResultType(searchResult)}
                        recent={isRecent(searchResult)}
                        nutriValues={searchResult}
                        showNumbers={indicator == "DISH"}
                        onClick={async () => {
                          if (searchResult.indicator == "MEALS_FOR_DAY") {
                            return;
                          }
                          const food = foodLookup[searchResult.id];
                          props.selectFood(food);
                          props.onClose();
                        }}
                      />
                    );
                  })}
                </VStack>
              </InfiniteScroll>
            </VStack>
          </>
        }
      />
    </>
  );
}
