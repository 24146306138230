export interface MealIngredient {
  id: number;
  amount: number;
  foodUnitDescription: string;
  foodUnitFoodDescription: string;
  foodUnitFoodId: number;
  foodUnitId: number;
  mealId: number;
  purchaseUnitDescription: string;
  purchaseUnitId: number;
  purchaseUnitPurchaseDescription: string;
  purchaseUnitPurchaseId: number;
}

export interface Meal {
  id: number;
  mealDate: string;
  mealInd: string;
  personId: number;
  personName: string;
  dishId: number;
  dishDescription: string;
  foodId: number;
  foodDescription: string;
  purchaseId: number;
  purchaseDescription: string;
}

export interface MealWithIngredients extends Meal {
  mealIngredients: MealIngredient[];
}

export const initialNewMealIngredient: MealIngredient = {
  id: -1,
  amount: 0,
  foodUnitDescription: "",
  foodUnitFoodDescription: "",
  foodUnitFoodId: -1,
  foodUnitId: -1,
  mealId: -1,
  purchaseUnitDescription: "",
  purchaseUnitId: -1,
  purchaseUnitPurchaseDescription: "",
  purchaseUnitPurchaseId: -1,
};
