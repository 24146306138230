import React from "react";
import {
  Plan2PlateButtonBaseProps,
  Plan2PlateIconButton,
} from "./plan2-plate-icon-button";
import { forwardRef } from "@chakra-ui/react";

export const DeleteButton = forwardRef(
  (props: Plan2PlateButtonBaseProps, ref) => {
    return (
      <Plan2PlateIconButton
        ref={ref}
        aria-label={"delete"}
        title={"Löschen"}
        variant={props.variant}
        isLoading={props.isLoading}
        icon={"delete"}
        onClick={props.onClick}
      />
    );
  },
);
