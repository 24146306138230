import { useParams } from "react-router-dom";
import { usePersonLoader } from "../loaders";
import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { useUpdate } from "../utils/use-update";
import { Person, PersonCreation } from "../data/person";
import { NeedDefinitionWithEditedFlags } from "../data/need";
import { getNeedForDate, needToNeedDefinition } from "../utils/person-utils";
import { formatIsoString, today } from "../utils/date-utils";
import { HStack, VStack } from "@chakra-ui/react";
import { StringInput } from "../components/string-input";
import { NeedEditor } from "../components/need-editor";
import { SaveButton } from "../components/buttons/save-button";
import { axiosInstance } from "../keycloak";
import { DeleteButton } from "../components/buttons/delete-button";
import { RevertButton } from "../components/buttons/revert-button";
import { isNullIndex } from "../utils/plan-utils";
import { LoadingComponent } from "../components/loading-component";
import { useUnloadAlert } from "../components/use-unload-alert";

const PersonEditPage = () => {
  const { personId: personIdParam } = useParams();
  const { loadPerson, personLookup } = usePersonLoader(true);

  const {
    editedObject: person,
    updateEditedObjectField: updatePersonField,
    updateEditedObject: updatePerson,
  } = useUpdate<Person>({
    id: -1,
    name: "",
    active: true,
    needs: [],
  });

  const [need, updateNeed] = useImmer<NeedDefinitionWithEditedFlags>({
    carbs: 0,
    carbsPercent: 0,
    fat: 0,
    fatPercent: 0,
    kcal: 0,
    personId: 0,
    protein: 0,
    proteinPercent: 0,
  });

  useEffect(() => {
    const personId = Number(personIdParam);

    const relevantPerson = personLookup[personId];
    if (relevantPerson == undefined) {
      return;
    }
    updatePerson(relevantPerson);
    updateNeed((needDraft) => {
      return {
        ...needDraft,
        ...needToNeedDefinition(
          getNeedForDate(relevantPerson.needs, formatIsoString(today())),
        ),
      };
    });
  }, [personIdParam, personLookup]);

  const save = async () => {
    const personToSave: PersonCreation = {
      ...person,
      need,
    };
    await axiosInstance.put<Person>(`/api/person`, personToSave);
    setPristine(true);
    return true;
  };

  const setActive = (active: boolean) => {
    setPristine(false);
    updatePerson((p) => {
      p.active = active;
    });
  };

  const { pristine, setPristine } = useUnloadAlert();

  return (
    <LoadingComponent loadData={[loadPerson]}>
      <VStack className="stretch-stack-with-padding">
        <StringInput
          fieldName="name"
          width="100%"
          value={person.name}
          updater={updatePersonField}
          handleBlur={() => {}}
          fieldDescription="Name"
        />
        <NeedEditor
          need={need}
          updateNeed={(updater) => {
            updateNeed(updater);
            setPristine(false);
          }}
        />
        <HStack justifyContent={"center"}>
          <SaveButton isDisabled={pristine} save={save} />
          {!isNullIndex(person.id) && person.active ? (
            <DeleteButton onClick={() => setActive(false)} />
          ) : !isNullIndex(person.id) ? (
            <RevertButton onClick={() => setActive(true)} />
          ) : undefined}
        </HStack>
      </VStack>
    </LoadingComponent>
  );
};

export default PersonEditPage;
