import React, { useCallback, useEffect, useState } from "react";

export interface LoadingComponentProps {
  loadData: (() => Promise<any>)[];
  children: React.ReactNode;
  shouldReload?: boolean;
  notEmptyCondition?: any[][];
}

export const LoadingComponent = (props: LoadingComponentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const deps = props.shouldReload != null ? [props.loadData] : [];
  const loadData = useCallback(async () => {
    await Promise.all(props.loadData.map((c) => c()));
    setIsLoading(false);
  }, deps);

  useEffect(() => {
    if (props.shouldReload == null || props.shouldReload) {
      loadData();
    }
  }, [loadData, props.shouldReload]);

  if (isLoading || (props.notEmptyCondition || []).find((obj) => !obj.length)) {
    return <div>Laden...</div>;
  } else {
    return <>{props.children}</>;
  }
};
