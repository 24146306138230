import React from "react";
import { useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { PurchaseEditor } from "../components/purchase-editor";
import { Purchase } from "../data/purchase";
import Food from "../data/food";
import { axiosInstance } from "../keycloak";
import { LoadingComponent } from "../components/loading-component";
import { VStack } from "@chakra-ui/react";
import { useDepartmentLoader, useShopLookupLoader } from "../loaders";
import {
  DepartmentLookupContext,
  ShopLookupWithRefreshContext,
} from "../contexts";

const PurchaseEditPage: React.FC = () => {
  const [purchase, updatePurchase] = useImmer<Purchase | undefined>(undefined);
  const [food, updateFood] = useImmer<Food | undefined>(undefined);
  const { purchaseId } = useParams();

  async function loadPurchase() {
    updatePurchase(
      (await axiosInstance.get<Purchase>(`/api/purchase/${purchaseId}`)).data,
    );
  }

  async function loadFood() {
    updateFood(
      (
        await axiosInstance.get<Food>(
          `/api/food/${
            (await axiosInstance.get<Purchase>(`/api/purchase/${purchaseId}`))
              .data.foodId
          }`,
        )
      ).data,
    );
  }

  const { loadShop, shopLookup } = useShopLookupLoader();
  const { departmentLookup, loadDepartment } = useDepartmentLoader();

  return (
    <LoadingComponent
      loadData={[loadPurchase, loadFood, loadDepartment, loadShop]}
    >
      <ShopLookupWithRefreshContext.Provider value={{ loadShop, shopLookup }}>
        <DepartmentLookupContext.Provider value={departmentLookup}>
          <VStack className="stretch-stack-with-padding">
            <PurchaseEditor
              initialPurchase={purchase!}
              isCreate={false}
              food={food!}
            />
          </VStack>
        </DepartmentLookupContext.Provider>
      </ShopLookupWithRefreshContext.Provider>
    </LoadingComponent>
  );
};

export default PurchaseEditPage;
