import { FoodUnit } from "./food-unit";
import { Availability } from "./availability";

export type BaseUnit = "G" | "ML";

export default interface Food {
  id: number;
  defaultPurchaseId: number;
  description: string;
  fat: number;
  carbs: number;
  protein: number;
  kcal?: number;
  baseUnit: BaseUnit;
  foodUnits: FoodUnit[];
  availabilities: Availability[];
  purchaseRelevant: boolean;
  vendor: string;
  canBeDeleted: boolean;
}
export const initialFood: Food = {
  id: -1,
  defaultPurchaseId: -1,
  baseUnit: "G",
  description: "",
  fat: 0,
  carbs: 0,
  protein: 0,
  kcal: 0,
  foodUnits: [],
  availabilities: [],
  purchaseRelevant: true,
  vendor: "",
  canBeDeleted: true,
};
