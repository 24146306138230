import React from "react";
import { NeedEditor } from "../components/need-editor";
import { useImmer } from "use-immer";
import { Person, PersonCreation } from "../data/person";
import { NeedDefinitionWithEditedFlags } from "../data/need";
import { HStack, VStack } from "@chakra-ui/react";
import { StringInput } from "../components/string-input";
import { useUpdate } from "../utils/use-update";
import { useNavigate } from "react-router-dom";
import { SaveButton } from "../components/buttons/save-button";
import { axiosInstance } from "../keycloak";
import { useUnloadAlert } from "../components/use-unload-alert";

export const PersonCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const { editedObject: person, updateEditedObjectField: updatePersonField } =
    useUpdate<Person>({
      id: -1,
      name: "",
      active: true,
      needs: [],
    });

  const [need, updateNeed] = useImmer<NeedDefinitionWithEditedFlags>({
    carbs: 0,
    carbsPercent: 0,
    fat: 0,
    fatPercent: 0,
    kcal: 0,
    personId: 0,
    protein: 0,
    proteinPercent: 0,
  });

  const save = async () => {
    const personToSave: PersonCreation = {
      ...person,
      need,
    };
    const createdPerson = (
      await axiosInstance.post<Person>(`/api/person`, personToSave)
    ).data;
    navigate(`/person/edit/${createdPerson.id}`);
    return true;
  };

  const { pristine, setPristine } = useUnloadAlert();

  return (
    <VStack className="stretch-stack-with-padding">
      <StringInput
        fieldName="name"
        width="100%"
        value={person.name}
        updater={updatePersonField}
        handleBlur={() => {}}
        fieldDescription="Name"
      />
      <NeedEditor
        need={need}
        updateNeed={(updater) => {
          updateNeed(updater);
          setPristine(false);
        }}
      />
      <HStack justifyContent={"center"}>
        <SaveButton isDisabled={pristine} save={save} />
      </HStack>
    </VStack>
  );
};
