export interface Intake {
  id: number;
  intakeDate: string;
  mealInd: string;
  amount: number;
  personId: number;
  mealId: number;
  purchaseUnitId: number;
  purchaseUnitDescription: string;
  purchaseUnitPurchaseId: number;
  purchaseUnitPurchaseDescription: string;
}

export const initialIntake = (
  intakeDate: string,
  mealId: number,
  mealInd: string,
  personId: number,
): Intake => {
  return {
    amount: 0,
    id: -1,
    intakeDate,
    mealId,
    mealInd,
    personId,
    purchaseUnitDescription: "",
    purchaseUnitId: -1,
    purchaseUnitPurchaseDescription: "",
    purchaseUnitPurchaseId: -1,
  };
};
