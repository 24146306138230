import { keys } from "lodash";

export interface NutriValues {
  fat: number;
  carbs: number;
  protein: number;
  kcal: number;
}

export const emptyNutriValue = {
  fat: 0,
  carbs: 0,
  protein: 0,
  kcal: 0,
};

export type NutriValueProperties = {
  priorityOrder: number;
  name: string;
  hasUpperBound: boolean;
  color: string;
  shortName: string;
};

export const nutriLookup: Record<keyof NutriValues, NutriValueProperties> = {
  kcal: {
    priorityOrder: 0,
    name: "Kalorien",
    hasUpperBound: true,
    color: "#1f499b",
    shortName: "Kcal",
  },
  fat: {
    priorityOrder: 1,
    name: "Fett",
    hasUpperBound: false,
    color: "#ff6c00",
    shortName: "F",
  },
  carbs: {
    priorityOrder: 2,
    name: "KH",
    hasUpperBound: true,
    color: "#009688",
    shortName: "KH",
  },
  protein: {
    priorityOrder: 3,
    name: "Protein",
    hasUpperBound: false,
    color: "#da0217",
    shortName: "P",
  },
};

export const nutriValueKeys = keys(nutriLookup).sort(
  (a, b) =>
    nutriLookup[a as keyof NutriValues].priorityOrder -
    nutriLookup[b as keyof NutriValues].priorityOrder,
) as (keyof NutriValues)[];

export function computeNutriSum(
  nutriValues: NutriValues[],
  nutriValueKey: "fat" | "carbs" | "protein" | "kcal",
) {
  return nutriValues
    .map((nutriValue) => nutriValue[nutriValueKey])
    .reduce((a, b) => a + b, 0);
}

export const isEmpty = (nutriValues: NutriValues[]) =>
  computeNutriSum(nutriValues, "kcal") == 0;
export type NutriValuesWithoutKcal = Omit<NutriValues, "kcal">;
