import React from "react";
import { Plan2PlateIconButton } from "./plan2-plate-icon-button";

export const AvailabilityButton = (props: { onClick: () => void }) => {
  return (
    <Plan2PlateIconButton
      aria-label={"editAvailability"}
      title={"Verfügbarkeit bearbeiten"}
      icon={"shopCart"}
      onClick={props.onClick}
    />
  );
};
