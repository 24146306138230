import { CreateToastFnReturn } from "@chakra-ui/react";
import React from "react";

export function showValidationMessage<Result, Err>(
  toast: CreateToastFnReturn,
  title: React.ReactNode,
  description?: React.ReactNode,
) {
  toast({
    description,
    title,
    status: "error",
    isClosable: true,
  });
}
