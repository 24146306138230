import {
  Box,
  Flex,
  HStack,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { values } from "lodash";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { AvailabilityWithEditedFlags } from "../data/availability";
import { useImmer } from "use-immer";
import {
  DepartmentLookupContext,
  ShopLookupWithRefreshContext,
} from "../contexts";
import { isDeleted, isNew } from "../utils/edit-utils";
import { SaveButton } from "./buttons/save-button";
import { AddButton } from "./buttons/add-button";
import { CancelButton } from "./buttons/cancel-button";
import { DeleteButton } from "./buttons/delete-button";
import { Plan2PlateDrawer, Plan2PlateDrawerHeader } from "./plan2-plate-drawer";
import { EditShopsDrawer } from "./edit-shops-drawer";
import { isNullIndex } from "../utils/plan-utils";
import { showValidationMessage } from "./show-validation-message";
import { Plan2PlateSelect } from "./plan2-plate-select";

export interface AvailabilitityDrawerProps {
  availabilities: AvailabilityWithEditedFlags[] | undefined;
  purchaseId: number | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (availabilities: AvailabilityWithEditedFlags[]) => Promise<boolean>;
  submitIsSave: boolean;
}

export const useAvailabilityDrawer = (
  onSubmit: (availabilities: AvailabilityWithEditedFlags[]) => Promise<boolean>,
  submitIsSave: boolean,
): AvailabilitityDrawerProps & {
  editAvailabilities: (
    foodUnitId: number,
    availabilities: AvailabilityWithEditedFlags[],
  ) => void;
} => {
  const [availabilities, updateAvailabilities] = useImmer<
    AvailabilityWithEditedFlags[] | undefined
  >(undefined);
  const [purchaseId, updatePurchaseId] = useImmer<number | undefined>(
    undefined,
  );

  const { onOpen, onClose, isOpen } = useDisclosure();
  const editAvailabilities = (
    purchaseId: number,
    availabilities: AvailabilityWithEditedFlags[],
  ) => {
    updateAvailabilities(availabilities);
    updatePurchaseId(purchaseId);
    onOpen();
  };
  return {
    editAvailabilities,
    purchaseId,
    availabilities,
    isOpen,
    onClose,
    onSubmit,
    submitIsSave,
  };
};

export const AvailabilityDrawer = (props: AvailabilitityDrawerProps) => {
  const leastDestructiveRef = useRef<HTMLButtonElement>(null);

  const [availabilities, updateAvailabilities] = useImmer<
    AvailabilityWithEditedFlags[] | undefined
  >(undefined);

  useEffect(() => {
    updateAvailabilities(props.availabilities);
  }, [props.isOpen]);

  const { shopLookup } = useContext(ShopLookupWithRefreshContext);
  const departmentLookup = useContext(DepartmentLookupContext);
  const selectedAvailabilityCombinations = useMemo(
    () => new Set((availabilities || []).map((a) => a.shopId)),
    [availabilities],
  );

  const removeAvailability = (index: number) => {
    updateAvailabilities((availabilitiesDraft) => {
      if (availabilitiesDraft != undefined) {
        const availability = availabilitiesDraft[index];
        if (isNew(availability)) {
          availabilitiesDraft.splice(index, 1);
        } else {
          availability.deleted = true;
        }
      }
    });
  };

  const addAvailability = () => {
    updateAvailabilities((availabilitiesDraft) => {
      if (availabilitiesDraft != undefined) {
        availabilitiesDraft.push({
          departmentId: -1,
          purchaseId: props.purchaseId!,
          id: -1,
          shopId: -1,
        });
      }
    });
  };
  const {
    isOpen: isOpenShopEditor,
    onOpen: onOpenShopEditor,
    onClose: onCloseShopEditor,
  } = useDisclosure();

  const toast = useToast();

  if (availabilities != undefined) {
    const onCancel = () => {
      props.onClose();
    };

    const handleSubmit = async () => {
      const incompleteItems = availabilities
        .filter((a) => isNullIndex(a.shopId) || isNullIndex(a.departmentId))
        .filter((a) => !isDeleted(a));
      if (incompleteItems.length) {
        showValidationMessage(toast, "Unvollständige Verfügbarkeiten");
        return false;
      }
      const success = await props.onSubmit(availabilities);
      if (success || !props.submitIsSave) {
        props.onClose();
      }
      return success;
    };
    return (
      <>
        <EditShopsDrawer
          isOpen={isOpenShopEditor}
          onClose={onCloseShopEditor}
        />
        <Plan2PlateDrawer
          onClose={props.onClose}
          open={props.isOpen}
          header={<Plan2PlateDrawerHeader heading={"Verfügbarkeiten"} />}
          body={
            <VStack>
              {availabilities.map((availability, index) =>
                availability.deleted ? undefined : (
                  <Box className="editor-box" key={index}>
                    <VStack alignItems={"stretch"} width={"100%"}>
                      <HStack key={index} width={"100%"}>
                        <Plan2PlateSelect
                          width={"100%"}
                          placeholder={"Geschäft"}
                          value={shopLookup![availability.shopId]}
                          onChange={(shop) => {
                            const selectedShopId = shop!.id;
                            updateAvailabilities((availabilityDraft) => {
                              if (availabilityDraft != undefined) {
                                availabilityDraft[index].shopId =
                                  selectedShopId;
                                availabilityDraft[index].edited = true;
                              }
                            });
                          }}
                          choices={values(shopLookup).filter(
                            (shop) =>
                              shop.id == availability.shopId ||
                              !selectedAvailabilityCombinations.has(shop.id),
                          )}
                        />
                        {isNullIndex(availability.shopId) && (
                          <AddButton onClick={onOpenShopEditor} />
                        )}
                        <DeleteButton
                          onClick={() => removeAvailability(index)}
                        />
                      </HStack>
                      <Plan2PlateSelect
                        width={"100%"}
                        placeholder={"Abteilung"}
                        value={departmentLookup![availability.departmentId]}
                        onChange={(department) => {
                          if (department) {
                            updateAvailabilities((availabilityDraft) => {
                              if (availabilityDraft != undefined) {
                                availabilityDraft[index].departmentId =
                                  department.id;
                                availabilityDraft[index].edited = true;
                              }
                            });
                          }
                        }}
                        choices={values(departmentLookup)}
                      />
                    </VStack>
                  </Box>
                ),
              )}
              <AddButton onClick={addAvailability} />
            </VStack>
          }
          footer={
            <Flex direction="column">
              <Flex justify="flex-end" paddingTop="10px" gap={3}>
                <CancelButton onClick={onCancel} />
                <SaveButton save={handleSubmit} ref={leastDestructiveRef}>
                  {props.submitIsSave ? "Speichern" : "Ok"}
                </SaveButton>
              </Flex>
            </Flex>
          }
        />
      </>
    );
  } else {
    return <></>;
  }
};
