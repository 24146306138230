import React, { useContext, useEffect, useState } from "react";
import {
  CircularProgress,
  CircularProgressLabel,
  HStack,
  keyframes,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AddButton } from "./buttons/add-button";
import { EditMealDrawer } from "./edit-meal-drawer";
import {
  Plan2PlateDrawer,
  Plan2PlateDrawerHeader,
  usePlan2PlateDisclosure,
} from "./plan2-plate-drawer";
import { CreateItemDrawer } from "./create-item-drawer";
import { SearchInput } from "./search-input";
import { useImmer } from "use-immer";
import { getPerformSearch } from "../utils/search-utils";
import { baseUrl } from "../config";
import { useNutriSlider } from "./nutri-slider";
import {
  IntakeWithEditedFlags,
  PlanCell,
  PlanCellCoordinates,
} from "../data/plan";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  computeResultType,
  getMealSourceKey,
  MealSource,
  MealSourceId,
} from "../data/meal-source";
import { formatCoordinates, getMealKeyForMeal } from "../utils/plan-utils";
import {
  DrawerHistoryContext,
  MealPlannerContext,
  PersonLookupContext,
} from "../contexts";
import { DetailSearchResult, isRecent } from "./detail-search-result";
import { mealInds } from "../utils/meal-ind-utils";
import { SearchResult } from "../data/search";
import {
  addDays,
  formatIsoString,
  isTodayOrPrevious,
  parseDate,
} from "../utils/date-utils";
import { isDeleted } from "../utils/edit-utils";
import { Draft } from "immer";
import { FastIntakeButton } from "./buttons/fast-intake-button";
import { deepEquals } from "../utils/equal-utils";
import { FloatingButton } from "./buttons/floating-button";
import { MealWithIngredients } from "../data/meal-with-ingredients";
import { showValidationMessage } from "./show-validation-message";
import { axiosInstance } from "../keycloak";

export function SearchForPlanCellDrawer(props: {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  planCellCoordinates: PlanCellCoordinates;
  planMode: "plan" | "intake";
}) {
  const [planCells, updatePlanCells] = useImmer<PlanCell[]>([]);
  useEffect(() => {
    if (!props.open) {
      updatePlanCells([]);
      setSearchTerm("");
      setSearchResultCount(0);
      updateSearchResults([]);
    } else {
      const endDate = formatIsoString(
        addDays(parseDate(props.planCellCoordinates.date), -1),
      );
      const startDate = formatIsoString(addDays(parseDate(endDate), -6));
      const indicatorParam =
        props.planMode == "intake" ? "&indicator=PURCHASE" : "";
      const queryParams = `mealInd=${props.planCellCoordinates.mealInd}&mealDateStart=${startDate}&mealDateEnd=${endDate}&personId=${props.planCellCoordinates.personId}${indicatorParam}`;
      const pathSuffix = props.planMode == "plan" ? "latest" : "latestintakes";
      axiosInstance
        .get<
          SearchResult<MealSource>
        >(`/api/mealsource/${pathSuffix}?${queryParams}`)
        .then((axiosResponse) => {
          const response = axiosResponse.data;
          setSearchResultCount(response.count);
          updateSearchResults(response.searchResults);
        });
    }
  }, [props.open]);
  const [searchTerm, setSearchTerm] = useState("");
  const [ean, setEan] = useState<number>();
  const [searchResults, updateSearchResults] = useImmer<MealSource[]>([]);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const nutriSliderProps = useNutriSlider("dish");
  const { performSearch, fetchMoreData } = getPerformSearch(
    props.planCellCoordinates.personId,
    nutriSliderProps,
    searchTerm,
    searchResults,
    updateSearchResults,
    setSearchResultCount,
    `${baseUrl}/api/mealsource/search`,
    10,
    undefined,
  );
  useEffect(() => {
    if (String(ean) == searchTerm) {
      performSearch(true);
    }
  }, [ean, searchTerm]);
  const personLookup = useContext(PersonLookupContext);

  const drawerHistoryContext = useContext(DrawerHistoryContext);
  const {
    isOpen: isOpenEditMeal,
    onOpen: onOpenEditMeal,
    onClose: onCloseEditMeal,
  } = usePlan2PlateDisclosure(drawerHistoryContext);
  const {
    isOpen: isOpenCreateItem,
    onOpen: onOpenCreateItem,
    onClose: onCloseCreateItem,
  } = usePlan2PlateDisclosure(drawerHistoryContext);

  async function addPlanCell(
    planCellCoordinates: PlanCellCoordinates,
    mealSourceId: MealSourceId,
  ) {
    const mealProps = `mealDate=${planCellCoordinates.date}&mealInd=${planCellCoordinates.mealInd}&personId=${planCellCoordinates.personId}`;

    const requestObj = {
      planCellCoordinates,
      mealSourceId,
    };

    async function computeMealWithIntakes(): Promise<PlanCell[]> {
      const mealsWithIngredients = (
        await axiosInstance.post<MealWithIngredients[]>(
          `/api/mealFromEntity`,
          requestObj,
        )
      ).data;
      return mealsWithIngredients.map((mealWithIngredients) => {
        return {
          indicator: "MealWithIntakes",
          meal: mealWithIngredients,
        };
      });
    }

    function pushIfNotExists(
      draft: Array<Draft<PlanCell>>,
      mealsWithIntakes: PlanCell[],
    ) {
      const key = (p: PlanCell) =>
        p.indicator == "MealWithIntakes"
          ? getMealKeyForMeal(p.meal)
          : { purchaseId: p.purchaseUnitPurchaseId };
      for (const mealWithIntakes of mealsWithIntakes) {
        if (!draft.find((d) => deepEquals(key(d), key(mealWithIntakes)))) {
          draft.push(mealWithIntakes);
        }
      }
    }

    if (props.planMode == "plan") {
      const mealWithIntakes = await computeMealWithIntakes();

      updatePlanCells((draft) => {
        pushIfNotExists(draft, mealWithIntakes);
      });
    } else {
      const intakes = (
        await axiosInstance.post<IntakeWithEditedFlags[]>(
          `/api/intakeFromEntity`,
          requestObj,
        )
      ).data;
      const planCells: PlanCell[] = intakes.map((intake) => {
        return {
          indicator: "JustIntake",
          ...intake,
        };
      });

      updatePlanCells((draft) => {
        pushIfNotExists(draft, planCells);
      });
    }
  }

  const mealIndDescription =
    mealInds[props.planCellCoordinates.mealInd]?.name || "";
  const planCellCount = planCells.filter((pC) => !isDeleted(pC)).length;

  const { onConsume, getTableDataForCoordinates } =
    useContext(MealPlannerContext);

  const tableData = getTableDataForCoordinates(props.planCellCoordinates);
  const hasMeals = tableData.find((pC) => pC.indicator == "MealWithIntakes");

  function fastIntake(date: string, mealInd: string, personId: number) {
    onConsume(date, mealInd, undefined, personId);
  }

  const pulse = keyframes`
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.3);
        }
        100% {
            transform: scale(1);
        }
    `;

  const [isPulsating, setIsPulsating] = useState(false);

  const circleColor = useColorModeValue("black", "white");
  const toast = useToast();

  return (
    <>
      <EditMealDrawer
        onClose={onCloseEditMeal}
        onSubmit={() => {
          onCloseEditMeal();
          props.onSubmit();
        }}
        open={isOpenEditMeal}
        mode={props.planMode}
        planCells={planCells.map((planCell) => {
          return { index: -1, planCell };
        })}
        updateParentCells={updatePlanCells}
      />
      <CreateItemDrawer
        onClose={onCloseCreateItem}
        open={isOpenCreateItem}
        createItem={(e) => addPlanCell(props.planCellCoordinates, e)}
      />
      <Plan2PlateDrawer
        onClose={props.onClose}
        open={props.open}
        header={
          <>
            <Plan2PlateDrawerHeader
              heading={`${formatCoordinates(props.planCellCoordinates, personLookup!)} ${props.planMode == "plan" ? "planen" : "verzehren"}`}
            />
            <CircularProgress
              size={"35px"}
              marginLeft={"auto"}
              value={100}
              max={100}
              color={circleColor}
              thickness={"5px"}
              onClick={planCellCount ? onOpenEditMeal : undefined}
              animation={isPulsating ? `${pulse} 1s ease-in-out` : "none"}
            >
              <CircularProgressLabel fontWeight={"bold"} fontSize={"small"}>
                {planCellCount}
              </CircularProgressLabel>
            </CircularProgress>
          </>
        }
        body={
          <>
            {!!planCells.length && (
              <FloatingButton
                leftIcon={"next"}
                onClick={onOpenEditMeal}
                caption={"Weiter"}
              />
            )}
            <VStack width={"100%"} height={"100%"}>
              <HStack width={"100%"}>
                <SearchInput
                  value={searchTerm}
                  setSearchTerm={setSearchTerm}
                  performSearch={performSearch}
                  placeholder={mealIndDescription + " suchen"}
                  onBarcodeScan={(ean) => {
                    if (ean) {
                      setEan(ean);
                      setSearchTerm(String(ean));
                    } else {
                      showValidationMessage(toast, "EAN wurde nicht erkannt");
                    }
                  }}
                />
                <AddButton onClick={onOpenCreateItem} />
                {props.planMode == "intake" &&
                  isTodayOrPrevious(props.planCellCoordinates.date) &&
                  hasMeals && (
                    <FastIntakeButton
                      onClick={async () => {
                        await drawerHistoryContext.clearHistory();
                        fastIntake(
                          props.planCellCoordinates.date,
                          props.planCellCoordinates.mealInd,
                          props.planCellCoordinates.personId,
                        );
                      }}
                    />
                  )}
              </HStack>
              <VStack
                id={"search-for-plan-cell-drawer-scroll-stack"}
                overflow={"auto"}
                width={"100%"}
                alignItems={"stretch"}
              >
                <InfiniteScroll
                  scrollableTarget={"search-for-plan-cell-drawer-scroll-stack"}
                  dataLength={searchResults.length}
                  next={fetchMoreData}
                  hasMore={searchResults.length < searchResultCount}
                  loader={<h4>Loading...</h4>}
                  style={{ width: "100%" }}
                >
                  <VStack width={"100%"}>
                    {searchResults.map((mealSource) => (
                      <DetailSearchResult
                        key={getMealSourceKey(mealSource)}
                        extraTags={mealSource.extraTags}
                        title={mealSource.description}
                        resultType={computeResultType(mealSource)}
                        recent={isRecent(mealSource)}
                        nutriValues={mealSource}
                        showNumbers={
                          mealSource.indicator == "DISH" ||
                          mealSource.indicator == "MEAL" ||
                          mealSource.indicator == "MEALS_FOR_DAY"
                        }
                        onClick={async () => {
                          const planCellCoordinates = props.planCellCoordinates;
                          await addPlanCell(planCellCoordinates, mealSource);

                          setIsPulsating(true);
                          setTimeout(() => {
                            setIsPulsating(false);
                          }, 800);
                        }}
                      />
                    ))}
                  </VStack>
                </InfiniteScroll>
              </VStack>
            </VStack>
          </>
        }
      />
    </>
  );
}

export type PlanMode = "plan" | "intake";
