import React from "react";
import {
  Plan2PlateButtonBaseProps,
  Plan2PlateIconButton,
} from "./plan2-plate-icon-button";

export const CancelButton = (props: Plan2PlateButtonBaseProps) => {
  return (
    <Plan2PlateIconButton
      {...props}
      aria-label={"cancel"}
      title={"Abbrechen"}
      icon={"cancel"}
    />
  );
};
