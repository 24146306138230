import { Box, HStack, VStack } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { StringInput } from "../components/string-input";
import { SingleFieldUpdater } from "../utils/use-update";
import { Draft, produce } from "immer";
import { isEdited, isNew } from "../utils/edit-utils";
import { Department, DepartmentWithEditedFlags } from "../data/department";
import { useDepartmentLoader } from "../loaders";
import { values } from "lodash";
import { useImmer } from "use-immer";
import { SaveButton } from "../components/buttons/save-button";
import { AddButton } from "../components/buttons/add-button";
import { axiosInstance } from "../keycloak";
import { LoadingComponent } from "../components/loading-component";
import { useUnloadAlert } from "../components/use-unload-alert";

const DepartmentEditPage = () => {
  const { departmentLookup, loadDepartment } = useDepartmentLoader();

  const loadData = useCallback(async () => {
    await loadDepartment();
  }, []);

  const [departments, updateDepartments] = useImmer<
    DepartmentWithEditedFlags[]
  >([]);
  useEffect(() => {
    updateDepartments(values(departmentLookup));
  }, [departmentLookup]);

  const saveDepartments = async () => {
    const newDepartments = departments.filter(isNew);
    const editedDepartments = departments.filter(isEdited);
    newDepartments.length > 0 &&
      (await axiosInstance.post<Department[]>(
        `/api/department`,
        newDepartments,
      ));
    editedDepartments.length > 0 &&
      (await axiosInstance.put<Department[]>(
        `/api/department`,
        editedDepartments,
      ));
    (newDepartments.length > 0 || editedDepartments.length > 0) &&
      (await loadData());
    return true;
  };

  const { pristine, setPristine } = useUnloadAlert();

  const createDepartment = () => {
    updateDepartments((departmentsDraft) => {
      if (departmentsDraft != undefined) {
        departmentsDraft.push({
          id: -1,
          description: "",
        });
        setPristine(false);
      }
    });
  };
  return (
    <LoadingComponent loadData={[loadDepartment]}>
      <VStack className="stretch-stack-with-padding">
        {departments.map((department, index) => {
          const heading = department.description || "Neue Abteilung";
          const departmentUpdater: SingleFieldUpdater<
            DepartmentWithEditedFlags
          > = (k, v) => {
            const updater = (
              departmentsDraft: Draft<DepartmentWithEditedFlags>[] | undefined,
            ) => {
              if (departmentsDraft != undefined) {
                const departmentsDraftElement = departmentsDraft[index];
                departmentsDraftElement[k] = v;
                if (!isNew(departmentsDraftElement)) {
                  departmentsDraftElement.edited = true;
                }
              }
            };
            updateDepartments(updater);
            setPristine(false);
            return produce(departments, updater)[index];
          };

          return (
            <Box className="editor-box" key={index}>
              <VStack>
                <StringInput
                  width={"100%"}
                  fieldName="description"
                  fieldDescription="Bezeichnung"
                  value={department.description}
                  updater={departmentUpdater}
                  handleBlur={() => {}}
                />
              </VStack>
            </Box>
          );
        })}
        <HStack justifyContent={"center"}>
          <AddButton onClick={createDepartment} />
          <SaveButton isDisabled={pristine} save={saveDepartments}></SaveButton>
        </HStack>
      </VStack>
    </LoadingComponent>
  );
};

export default DepartmentEditPage;
