import React, { ForwardedRef, ReactElement } from "react";
import { ObjectWithStringSignature } from "./component-types";
import { Plan2PlateInput, Plan2PlateInputProps } from "./plan2-plate-input";
import { forwardRef } from "@chakra-ui/react";

type StringKeys<T> = keyof { [K in keyof T]: T[K] extends string ? K : never };

export type StringInputProps<
  T extends ObjectWithStringSignature,
  K extends StringKeys<T> & string,
> = Omit<
  Plan2PlateInputProps<T, K, string>,
  "valueConverter" | "inputType" | "leftAddon" | "leftAddonLabel"
> & {
  fieldDescription?: string;
};
const StringInputInternal = <
  T extends ObjectWithStringSignature,
  K extends StringKeys<T> & string,
>(
  props: StringInputProps<T, K>,
  ref: ForwardedRef<any>,
): ReactElement => (
  <Plan2PlateInput
    {...props}
    ref={ref}
    inputType="string"
    valueConverter={(e) => e}
    leftAddonLabel={props.fieldDescription}
    placeholder={props.placeholder || props.fieldDescription}
  />
);

export const StringInput = forwardRef(StringInputInternal) as <
  T extends ObjectWithStringSignature,
  K extends StringKeys<T> & string,
>(
  props: StringInputProps<T, K> & { ref?: React.Ref<any> },
) => ReactElement;
