import React from "react";
import { useImmer } from "use-immer";
import { useNavigate, useParams } from "react-router-dom";
import { initialPurchase, Purchase } from "../data/purchase";
import { PurchaseEditor } from "../components/purchase-editor";
import Food from "../data/food";
import { axiosInstance } from "../keycloak";
import { LoadingComponent } from "../components/loading-component";
import { updatePurchaseWithFood } from "../utils/purchase-utils";
import { VStack } from "@chakra-ui/react";
import { useDepartmentLoader, useShopLookupLoader } from "../loaders";
import {
  DepartmentLookupContext,
  ShopLookupWithRefreshContext,
} from "../contexts";

const PurchaseCreatePage: React.FC = () => {
  const { foodId } = useParams();

  if (foodId == undefined) {
    throw new Error("Keine Food-Id angegeben");
  }

  const [purchase, updatePurchase] = useImmer<Purchase>(
    initialPurchase(Number(foodId)),
  );

  const navigate = useNavigate();
  const [food, updateFood] = useImmer<Food | undefined>(undefined);

  async function loadFood() {
    const food = (await axiosInstance.get<Food>(`/api/food/${purchase.foodId}`))
      .data;
    updatePurchase(updatePurchaseWithFood(purchase, food));
    updateFood(food);
  }

  const { loadShop, shopLookup } = useShopLookupLoader();
  const { departmentLookup, loadDepartment } = useDepartmentLoader();

  return (
    <LoadingComponent loadData={[loadFood, loadDepartment, loadShop]}>
      <ShopLookupWithRefreshContext.Provider value={{ loadShop, shopLookup }}>
        <DepartmentLookupContext.Provider value={departmentLookup}>
          <VStack className="stretch-stack-with-padding">
            <PurchaseEditor
              initialPurchase={purchase}
              isCreate={true}
              afterSave={(purchase) =>
                navigate(`/purchase/edit/${purchase.id}`)
              }
              food={food!}
            />
          </VStack>
        </DepartmentLookupContext.Provider>
      </ShopLookupWithRefreshContext.Provider>
    </LoadingComponent>
  );
};

export default PurchaseCreatePage;
