import React, { MouseEventHandler } from "react";
import { Box } from "@chakra-ui/react";
import { plan2plateIcons } from "../utils/icon-utils";

export function Plan2PlateIcon(props: {
  icon: keyof typeof plan2plateIcons;
  size?: "sm" | "xs";
  onClick?: MouseEventHandler<HTMLDivElement>;
}) {
  const sizes = {
    base: "38px",
    sm: "25px",
    xs: "18px",
  };

  const size = props.size ? sizes[props.size] : sizes.base;

  return (
    <Box
      minHeight={size}
      minWidth={size}
      height={size}
      width={size}
      onClick={props.onClick}
    >
      {plan2plateIcons[props.icon]}
    </Box>
  );
}
