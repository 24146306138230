import React from "react";
import { Plan2PlateIconButton } from "./plan2-plate-icon-button";
import { forwardRef } from "@chakra-ui/react";

export const FastIntakeButton = forwardRef(
  (props: { onClick: () => void }, ref) => {
    return (
      <Plan2PlateIconButton
        ref={ref}
        aria-label={"fastIntake"}
        title={"Schnell Verzehren"}
        icon={"fastIntake"}
        onClick={props.onClick}
      />
    );
  },
);
