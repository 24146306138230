import { Purchase, PurchaseUnit } from "../data/purchase";
import Food from "../data/food";
import { PurchaseLookup } from "../data/lookups";
import { values } from "lodash";

export function formatPurchaseDescription(purchase: Purchase | undefined) {
  return purchase
    ? purchase.brand
      ? `${purchase.brand} ${purchase.description}`
      : `${purchase.description}`
    : "";
}

export function updatePurchaseWithFood<T>(
  purchase: Purchase,
  food: Food,
): Purchase {
  return {
    ...purchase,
    description: food.description,
    kcal: food.kcal || purchase.kcal,
    carbs: food.carbs,
    protein: food.protein,
    fat: food.fat,
  };
}

export function getDefaultPurchaseUnit(purchase: Purchase) {
  const defaultPurchaseUnit = purchase.purchaseUnits.find((pu) => pu.isDefault);
  if (defaultPurchaseUnit == undefined) {
    throw new Error("Keine Default Purchase-Unit gefunden");
  }
  return defaultPurchaseUnit;
}

export const computePurchaseByDefaultUnitLookup = (
  purchaseLookup: PurchaseLookup,
) => {
  const result: { [purchaseId: number]: Purchase } = {};
  values(purchaseLookup)
    .filter((f) => f.purchaseRelevant)
    .forEach((purchase) => {
      const defaultPurchaseUnit = getDefaultPurchaseUnit(purchase);
      result[defaultPurchaseUnit.id] = purchase;
    });
  return result;
};

export function formatPurchaseUnitWithAmountDescription(
  purchase: Purchase,
  purchaseUnit: PurchaseUnit,
  amount: number,
) {
  return `${formatPurchaseDescription(purchase)} ${amount.toLocaleString()} x ${purchaseUnit.description}`;
}
