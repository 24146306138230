import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Purchase } from "../data/purchase";
import { SearchIcon } from "@chakra-ui/icons";
import { formatPurchaseDescription } from "../utils/purchase-utils";
import { SearchResultCard } from "../components/search-result-card";
import { NutriSlider, useNutriSlider } from "../components/nutri-slider";
import { getPerformSearch } from "../utils/search-utils";
import { useImmer } from "use-immer";
import InfiniteScroll from "react-infinite-scroll-component";

const PurchaseSearchPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, updateSearchResults] = useImmer<Purchase[]>([]);
  const [searchResultCount, setSearchResultCount] = useState(0);

  const nutriSliderProps = useNutriSlider("purchase");

  const { performSearch, fetchMoreData } = getPerformSearch(
    undefined,
    nutriSliderProps,
    searchTerm,
    searchResults,
    updateSearchResults,
    setSearchResultCount,
    `/api/purchase/search`,
  );

  useEffect(() => {
    performSearch(true);
  }, [
    nutriSliderProps.minFat,
    nutriSliderProps.maxFat,
    nutriSliderProps.minCarbs,
    nutriSliderProps.maxCarbs,
    nutriSliderProps.minProtein,
    nutriSliderProps.maxProtein,
    nutriSliderProps.minKcal,
    nutriSliderProps.maxKcal,
  ]);

  const navigate = useNavigate();

  const createPurchase = () => {
    navigate("/purchase/create");
  };
  return (
    <VStack className="stack-with-padding">
      <HStack width={"100%"}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type={"search"}
            placeholder="Suche nach Produkten"
            value={searchTerm}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                performSearch(true);
              }
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </HStack>
      <Accordion allowToggle={true} width={"100%"}>
        <AccordionItem>
          <AccordionButton>
            <Box as="span" flex={1} textAlign={"left"} fontWeight={"bold"}>
              {"Nährwerte"}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel motionProps={{ animateOpacity: false }}>
            <NutriSlider {...nutriSliderProps} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <InfiniteScroll
        dataLength={searchResults.length}
        next={fetchMoreData}
        hasMore={searchResults.length < searchResultCount}
        loader={<h4>Loading...</h4>}
        style={{ width: "100%" }}
      >
        <Flex flexWrap={"wrap"} width={"100%"}>
          {searchResults.map((purchase) => (
            <SearchResultCard
              key={purchase.id}
              onClick={() => navigate(`/purchase/edit/${purchase.id}`)}
            >
              {formatPurchaseDescription(purchase)}
            </SearchResultCard>
          ))}
        </Flex>
      </InfiniteScroll>
    </VStack>
  );
};

export default PurchaseSearchPage;
