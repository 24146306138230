import { RangeDatepicker, SingleDatepicker } from "chakra-dayzed-datepicker";
import {
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { Plan2PlateIcon } from "./plan2-plate-icon";
import { RangeDatepickerProps } from "chakra-dayzed-datepicker/src/range";
import { Plan2PlateIconButton } from "./buttons/plan2-plate-icon-button";

export type Plan2PlateDatepickerProps = {
  date: Date;
  updateDate: (date: Date) => void;
  zIndex?: number;
  minDate?: Date;
};

export function Plan2PlateDatepicker({
  date,
  updateDate,
  ...props
}: Plan2PlateDatepickerProps) {
  const calendarColor = useColorModeValue("#6e6c6c", "#b5b5b5");

  return (
    <InputGroup zIndex={props.zIndex || 3} width={"fit-content"}>
      <SingleDatepicker
        name="date-input"
        minDate={props.minDate}
        propsConfigs={{
          inputProps: {
            width: "135px",
          },
          dayOfMonthBtnProps: {
            defaultBtnProps: {
              _hover: {
                background: "teal.400",
              },
            },
            selectedBtnProps: {
              background: "#ffc14c",
            },
            todayBtnProps: {
              background: "silver",
            },
          },
        }}
        configs={{
          dateFormat: "dd.MM.yyyy",
          dayNames: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
          monthNames: [
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember",
          ],
          firstDayOfWeek: 1, // default is 0, the dayNames[0], which is Sunday if you don't specify your own dayNames,
        }}
        date={date}
        onDateChange={updateDate}
      />
      <InputRightElement
        color={calendarColor}
        children={<Plan2PlateIcon icon={"calendar"} size={"sm"} />}
      />
    </InputGroup>
  );
}

export type Plan2PlateRangeDatepickerProps = {
  selectedDates: RangeDatepickerProps["selectedDates"];
  onDateChange: RangeDatepickerProps["onDateChange"];
  zIndex?: number;
  minDate?: Date;
};

export function Plan2PlateRangeDatepicker({
  selectedDates,
  onDateChange,
  ...props
}: Plan2PlateRangeDatepickerProps) {
  const calendarColor = useColorModeValue("#6e6c6c", "#b5b5b5");

  return (
    <InputGroup zIndex={props.zIndex || 3} width={"fit-content"}>
      <RangeDatepicker
        id={"range-datepicker"}
        name="date-input"
        minDate={props.minDate}
        propsConfigs={{
          inputProps: {
            display: "none",
          },
          dayOfMonthBtnProps: {
            defaultBtnProps: {
              _hover: {
                background: "teal.400",
              },
            },
            selectedBtnProps: {
              background: "#ffc14c",
            },
            todayBtnProps: {
              background: "silver",
            },
          },
        }}
        configs={{
          dateFormat: "dd.MM.yyyy",
          dayNames: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
          monthNames: [
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember",
          ],
          firstDayOfWeek: 1, // default is 0, the dayNames[0], which is Sunday if you don't specify your own dayNames,
        }}
        selectedDates={selectedDates}
        onDateChange={onDateChange}
      />
      <Plan2PlateIconButton
        aria-label={"calendar"}
        icon={"calendar"}
        onClick={(e) => {
          // @ts-ignore
          e.target.parentNode.parentNode.parentNode
            .getElementsByTagName("input")[0]
            .dispatchEvent(new KeyboardEvent("keypress", { key: " " }));
        }}
      />
    </InputGroup>
  );
}
