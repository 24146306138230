import { SingleFieldUpdater } from "../utils/use-update";
import {
  DishModification,
  PersonPortionAmount,
  PersonRecipeUnitModification,
  RecipeUnitModification,
} from "../data/dish-modification";
import { Draft, produce } from "immer";
import { entries, sortBy, values } from "lodash";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { StringInput } from "./string-input";
import { NumberInput } from "./number-input";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useImmer } from "use-immer";
import {
  AvailabilityByPurchaseIdLookup,
  FoodLookup,
  PersonLookup,
  PurchaseLookup,
} from "../data/lookups";
import { FoodUnit } from "../data/food-unit";
import { isDeleted, isEdited, isNew } from "../utils/edit-utils";
import { getRenderKey } from "../utils/render-utils";
import {
  isNullIndex,
  nutriValuesFromPersonRecipe,
  nutriValuesFromPortion,
  nutriValueSumReducer,
} from "../utils/plan-utils";
import { getNeedForDate } from "../utils/person-utils";
import { formatIsoString, today } from "../utils/date-utils";
import { SaveButton } from "./buttons/save-button";
import {
  NutriBar,
  NutriBarWithKcalAndBullets,
  NutriBullets,
} from "./nutri-bar";
import { DeleteButton } from "./buttons/delete-button";
import { AvailabilityButton } from "./buttons/availability-button";
import { AddButton } from "./buttons/add-button";
import { axiosInstance } from "../keycloak";
import { useUnloadAlert } from "./use-unload-alert";
import { deepEquals } from "../utils/equal-utils";
import Food from "../data/food";
import { SearchForFoodDrawer } from "./search-for-food-drawer";
import { Plan2PlateSelect } from "./plan2-plate-select";
import { AllNutriValues } from "./all-nutri-values";
import {
  AvailabilityDrawer,
  useAvailabilityDrawer,
} from "./availability-drawer";
import { emptyNutriValue } from "../data/nutri-values";
import { usePlan2PlateDisclosure } from "./plan2-plate-drawer";
import { DrawerHistoryContext } from "../contexts";
import { showValidationMessage } from "./show-validation-message";
import { SimpleInput } from "./simple-input";
import { formatFoodDescription, getDefaultPurchase } from "../utils/food-utils";

export const DishEditor = ({
  isCreate,
  foodLookup,
  personLookup,
  purchaseLookup,
  initialDish,
  afterSave,
  availabilityByPurchaseIdLookup,
  loadAvailabilities,
}: DishEditorProps) => {
  const [editedDish, updateEditedDish] =
    useImmer<DishModification>(initialDish);

  const { pristine, setPristine } = useUnloadAlert();

  useEffect(() => {
    setPristine(deepEquals(initialDish, editedDish));
  }, [editedDish]);

  const toast = useToast();

  const availabilityDrawerProps = useAvailabilityDrawer(
    async (availabilities) => {
      const createdAvailabilities = availabilities.filter(isNew);
      const editedAvailabilities = availabilities.filter(isEdited);
      const deletedAvailabilities = availabilities.filter(isDeleted);
      await axiosInstance.post(`/api/availability`, createdAvailabilities);
      await axiosInstance.put(`/api/availability`, editedAvailabilities);
      await Promise.all(
        deletedAvailabilities.map(
          async (a) => await axiosInstance.delete(`/api/availability/${a.id}`),
        ),
      );
      await loadAvailabilities();
      return true;
    },
    true,
  );

  const defaultFoodUnitLookup = useMemo(() => {
    const result: { [foodId: number]: FoodUnit } = {};
    values(foodLookup).forEach((food) => {
      const defaultFoodUnit = food.foodUnits.find((fu) => fu.isDefault);
      result[food.id] = defaultFoodUnit!;
    });
    return result;
  }, []);

  const createEmptyRecipe = (food: Food): RecipeUnitModification => {
    return {
      id: -1,
      foodUnitId: -1,
      foodId: food.id,
      personRecipeUnitModificationDtos: sortBy(
        entries(personLookup),
        ([_, person]) => person.name,
      ).map(([personId, _]) => {
        return {
          personId: Number(personId),
          amount: 0,
        };
      }),
    };
  };

  const updateEditedDishField: SingleFieldUpdater<DishModification> = (
    k,
    v,
  ) => {
    const draftModifier = (dishDraft: Draft<DishModification>) => {
      dishDraft[k] = v;
    };
    updateEditedDish(draftModifier);
    return produce(editedDish, draftModifier);
  };

  const handleBlur = () => {};

  const selectableFoods = sortBy(values(foodLookup), "description");

  const addRecipe = (food: Food) => {
    updateEditedDish((dishDraft) => {
      dishDraft.recipeUnitModificationDtos.push(createEmptyRecipe(food));
    });
  };

  const newItemRef = useRef<any>(null);

  useEffect(() => {
    if (newItemRef.current) {
      newItemRef.current.focus();
    }
  }, [editedDish.recipeUnitModificationDtos.length]);

  const getDefaultPurchaseIdForFoodId = (foodId: number) =>
    getDefaultPurchase(foodLookup[foodId], purchaseLookup).id;

  const getRecipesWithoutAvailability = () => {
    return editedDish.recipeUnitModificationDtos.filter(
      (recipe) =>
        !(
          getDefaultPurchaseIdForFoodId(recipe.foodId) in
          availabilityByPurchaseIdLookup
        ),
    );
  };

  const saveDish = async () => {
    const recipesWithoutAvailability = getRecipesWithoutAvailability();
    if (editedDish.purchaseRelevant && recipesWithoutAvailability.length > 0) {
      showValidationMessage(
        toast,
        "Lebensmittel ist nicht verfügbar",
        `Für Folgende Lebensmittel sind keine Verfügbarkeiten angelegt: ${recipesWithoutAvailability.map((d) => foodLookup[d.foodId].description).join()}`,
      );
      return false;
    } else if (
      !editedDish.description ||
      !editedDish.recipeUnitModificationDtos.length
    ) {
      showValidationMessage(toast, "Unvollständiges Gericht");
      return false;
    } else {
      const axiosResponse = isCreate
        ? await axiosInstance.post<DishModification>(`/api/dish`, editedDish)
        : await axiosInstance.put<DishModification>(`/api/dish`, editedDish);
      afterSave && afterSave(axiosResponse.data);
      return true;
    }
  };
  const drawerHistoryContext = useContext(DrawerHistoryContext);
  const { isOpen, onOpen, onClose } =
    usePlan2PlateDisclosure(drawerHistoryContext);

  const nutriValuesPerPortion = editedDish.numberOfUnits
    ? editedDish.recipeUnitModificationDtos
        .map((recipeUnit) => {
          return nutriValuesFromPortion(
            recipeUnit,
            editedDish.numberOfUnits!,
            foodLookup,
          );
        })
        .reduce(nutriValueSumReducer, emptyNutriValue)
    : emptyNutriValue;

  return (
    <>
      <SearchForFoodDrawer
        onClose={onClose}
        open={isOpen}
        selectFood={(food) => {
          addRecipe(food);
        }}
      />
      <AvailabilityDrawer {...availabilityDrawerProps} />
      <VStack className="stretch-stack">
        <VStack spacing="4" width={"100%"} alignItems={"stretch"}>
          <StringInput
            fieldName="description"
            width="100%"
            value={editedDish.description}
            updater={updateEditedDishField}
            handleBlur={handleBlur}
            fieldDescription="Bezeichnung"
          />

          {isCreate && (
            <Checkbox
              colorScheme="teal"
              paddingRight="20px"
              isChecked={editedDish.isCookingBook}
              onChange={(e) => {
                updateEditedDishField("isCookingBook", e.target.checked);
                updateEditedDishField("purchaseRelevant", e.target.checked);
              }}
            >
              Aus Kochbuch
            </Checkbox>
          )}
          {editedDish.isCookingBook && (
            <NumberInput
              fieldDescription={"# Portionen"}
              fieldName="numberOfUnits"
              value={editedDish.numberOfUnits}
              updater={updateEditedDishField}
              handleBlur={handleBlur}
              width={"130px"}
            />
          )}
          <Checkbox
            colorScheme="teal"
            paddingRight="20px"
            isChecked={editedDish.purchaseRelevant}
            onChange={(e) => {
              updateEditedDishField("purchaseRelevant", e.target.checked);
            }}
          >
            Einkaufsliste-Relevant
          </Checkbox>
          {editedDish.recipeUnitModificationDtos.map(
            (recipeUnit, recipeUnitIndex) => {
              const updateEditedRecipeUnitField: SingleFieldUpdater<
                RecipeUnitModification
              > = (k, v) => {
                const draftModifier = (dishDraft: Draft<DishModification>) => {
                  dishDraft.recipeUnitModificationDtos[recipeUnitIndex][k] = v;
                };
                updateEditedDish(draftModifier);
                return produce(editedDish, draftModifier)
                  .recipeUnitModificationDtos[recipeUnitIndex];
              };

              const removeRecipe = () => {
                updateEditedDish((draft) => {
                  draft.recipeUnitModificationDtos.splice(recipeUnitIndex, 1);
                });
              };

              const editAvailability = () => {
                const currentRecipe =
                  editedDish.recipeUnitModificationDtos[recipeUnitIndex];
                const defaultPurchaseId = getDefaultPurchaseIdForFoodId(
                  currentRecipe.foodId,
                );
                availabilityDrawerProps.editAvailabilities(
                  defaultPurchaseId,
                  availabilityByPurchaseIdLookup[defaultPurchaseId] || [],
                );
              };

              const currentFood = foodLookup[recipeUnit.foodId];
              const currentFoodUnit = currentFood?.foodUnits.find(
                (fu) => fu.id == recipeUnit.foodUnitId,
              );

              const foodDescription = [
                formatFoodDescription(currentFood),
                currentFoodUnit?.description,
              ]
                .filter((v) => v != null)
                .join(", ");
              const recipeUnitIsNew = isNew(recipeUnit);

              const recipeDescription = `Zutat ${foodDescription}`;
              return (
                <Box
                  className="editor-box"
                  key={getRenderKey(recipeUnit, recipeUnitIndex)}
                >
                  <FormControl>
                    <Stack spacing="4">
                      <FormLabel
                        htmlFor={`portion-${recipeUnit.foodUnitId}`}
                        fontSize="lg"
                      >
                        {recipeDescription}
                      </FormLabel>
                      <NutriBar nutriValues={currentFood} />
                      <HStack>
                        {recipeUnitIsNew && (
                          <Plan2PlateSelect
                            value={currentFoodUnit}
                            onChange={(fu) =>
                              updateEditedRecipeUnitField(
                                "foodUnitId",
                                fu?.id || -1,
                              )
                            }
                            choices={currentFood.foodUnits || []}
                            placeholder={"Einheit"}
                          />
                        )}
                        {editedDish.isCookingBook && (
                          <SimpleInput
                            caption={"Menge"}
                            type={"number"}
                            value={recipeUnit.portionAmount}
                            onBlur={(e) => {
                              updateEditedRecipeUnitField(
                                "portionAmount",
                                Number(e.target.value || "0"),
                              );
                            }}
                            width={"100px"}
                          />
                        )}{" "}
                      </HStack>
                      {!editedDish.isCookingBook && (
                        <VStack alignItems={"start"}>
                          {recipeUnit.personRecipeUnitModificationDtos.map(
                            (
                              personRecipeUnitModification,
                              personRecipeUnitModificationIndex,
                            ) => {
                              const updateEditedPersonRecipeUnitModification: SingleFieldUpdater<
                                PersonRecipeUnitModification
                              > = (k, v) => {
                                const draftModifier = (
                                  dishDraft: Draft<DishModification>,
                                ) => {
                                  dishDraft.recipeUnitModificationDtos[
                                    recipeUnitIndex
                                  ].personRecipeUnitModificationDtos[
                                    personRecipeUnitModificationIndex
                                  ][k] = v;
                                };
                                updateEditedDish(draftModifier);
                                return produce(editedDish, draftModifier)
                                  .recipeUnitModificationDtos[recipeUnitIndex]
                                  .personRecipeUnitModificationDtos[
                                  personRecipeUnitModificationIndex
                                ];
                              };
                              const person =
                                personLookup[
                                  personRecipeUnitModification.personId
                                ];
                              const nutriBarFactor =
                                personRecipeUnitModification.amount *
                                (currentFoodUnit?.netWeight || 0);
                              return (
                                person.active && (
                                  <HStack
                                    key={personRecipeUnitModification.personId}
                                  >
                                    <SimpleInput
                                      caption={person.name}
                                      type={"number"}
                                      value={
                                        personRecipeUnitModification.amount
                                      }
                                      onBlur={(e) => {
                                        updateEditedPersonRecipeUnitModification(
                                          "amount",
                                          Number(e.target.value || "0"),
                                        );
                                      }}
                                      width={"80px"}
                                    />
                                    <NutriBullets
                                      nutriValues={currentFood}
                                      factor={nutriBarFactor}
                                    />
                                  </HStack>
                                )
                              );
                            },
                          )}
                        </VStack>
                      )}
                      <HStack justifyContent={"center"}>
                        {editedDish.purchaseRelevant &&
                          foodLookup[recipeUnit.foodId].purchaseRelevant && (
                            <AvailabilityButton onClick={editAvailability} />
                          )}
                        <DeleteButton onClick={removeRecipe} />
                      </HStack>
                    </Stack>
                  </FormControl>
                </Box>
              );
            },
          )}
          <HStack justifyContent={"center"}>
            <AddButton onClick={onOpen} />
          </HStack>
          <Accordion allowMultiple={true} width={"100%"}>
            {editedDish.isCookingBook ? (
              <VStack alignItems={"stretch"}>
                <NutriBarWithKcalAndBullets
                  nutriValues={nutriValuesPerPortion}
                  factor={100}
                />
              </VStack>
            ) : (
              values(personLookup).map((person) => {
                const nutriValues = editedDish.recipeUnitModificationDtos
                  .filter(
                    (basicRecipe) =>
                      !isNullIndex(basicRecipe.foodId) &&
                      !isNullIndex(basicRecipe.foodUnitId),
                  )
                  .map((basicRecipe) => {
                    const personRecipe =
                      basicRecipe.personRecipeUnitModificationDtos.find(
                        (personRecipe) => personRecipe.personId == person.id,
                      );
                    return nutriValuesFromPersonRecipe(
                      basicRecipe,
                      personRecipe,
                      foodLookup,
                    );
                  });
                const currentNeed = getNeedForDate(
                  person.needs,
                  formatIsoString(today()),
                );

                const accordionTitle = `Nährwerte für ${person.name}`;
                return (
                  person.active && (
                    <AccordionItem key={person.id}>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex={1}
                          textAlign={"left"}
                          fontWeight={"bold"}
                        >
                          {accordionTitle}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel motionProps={{ animateOpacity: false }}>
                        <AllNutriValues
                          nutriValues={nutriValues}
                          needForDate={currentNeed}
                          mode={"horizontal"}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  )
                );
              })
            )}
          </Accordion>
          {editedDish.isCookingBook && (
            <Box className="relative-flex-editor-box">
              <Text
                position={"absolute"}
                top={"-10px"}
                fontSize={"xs"}
                backgroundColor={"var(--chakra-colors-chakra-body-bg)"}
              >
                # Portionen
              </Text>
              <Flex flexWrap={"wrap"} gap={"10px"}>
                {editedDish.personPortions.map(
                  (portionAmount, portionAmountIndex) => {
                    const person = personLookup[portionAmount.personId];
                    const updatePortionAmountField: SingleFieldUpdater<
                      PersonPortionAmount
                    > = (k, v) => {
                      const draftModifier = (
                        dishDraft: Draft<DishModification>,
                      ) => {
                        dishDraft.personPortions[portionAmountIndex][k] = v;
                      };
                      updateEditedDish(draftModifier);
                      return produce(editedDish, draftModifier).personPortions[
                        portionAmountIndex
                      ];
                    };

                    return (
                      person?.active && (
                        <SimpleInput
                          caption={person.name}
                          type={"number"}
                          value={portionAmount.amount}
                          onBlur={(e) => {
                            updatePortionAmountField(
                              "amount",
                              Number(e.target.value || "0"),
                            );
                          }}
                          width={"80px"}
                        />
                      )
                    );
                  },
                )}
              </Flex>
            </Box>
          )}
          <HStack justifyContent={"center"}>
            <SaveButton
              isDisabled={pristine && !isCreate}
              save={saveDish}
            ></SaveButton>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export type DishEditorProps = {
  isCreate: boolean;
  foodLookup: FoodLookup;
  purchaseLookup: PurchaseLookup;
  personLookup: PersonLookup;
  initialDish: DishModification;
  availabilityByPurchaseIdLookup: AvailabilityByPurchaseIdLookup;
  loadAvailabilities: () => Promise<void>;
  afterSave?: (createdDish: DishModification) => void;
};
