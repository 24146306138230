import { NutriSliderProps } from "../components/nutri-slider";
import { DraftFunction } from "use-immer";
import { SearchResult, TextSearch } from "../data/search";
import { MealSource } from "../data/meal-source";
import { axiosInstance } from "../keycloak";

export function getPerformSearch<T>(
  activePerson: number | undefined,
  nutriSliderProps: NutriSliderProps,
  searchTerm: string,
  searchResults: T[],
  updateSearchResults: (arg: DraftFunction<T[]> | T[]) => void,
  setSearchResultCount: (
    value: ((prevState: number) => number) | number,
  ) => void,
  url: string,
  pageSize?: number,
  indicator?: MealSource["indicator"],
) {
  const performSearch = async (isNewSearch: boolean) => {
    const searchObject: TextSearch = {
      nutriSearchFilter: {
        activePerson,
        maxCarbs: nutriSliderProps.maxCarbs,
        maxFat: nutriSliderProps.maxFat,
        maxKcal: nutriSliderProps.maxKcal,
        maxProtein: nutriSliderProps.maxProtein,
        minCarbs: nutriSliderProps.minCarbs,
        minFat: nutriSliderProps.minFat,
        minKcal: nutriSliderProps.minKcal,
        minProtein: nutriSliderProps.minProtein,
      },
      searchText: searchTerm,
      pageSize,
      fromIndex: isNewSearch ? 0 : searchResults.length,
      indicator: indicator,
    };
    const response = await axiosInstance.post<SearchResult<T>>(
      url,
      searchObject,
    );
    if (isNewSearch) {
      updateSearchResults(response.data.searchResults);
    } else {
      updateSearchResults((searchResultsDraft) => {
        searchResultsDraft.push(
          ...(response.data.searchResults as unknown as any),
        );
      });
    }
    setSearchResultCount(response.data.count);
  };
  const fetchMoreData = () => {
    performSearch(false);
  };

  return { performSearch, fetchMoreData };
}
