import { DraftFunction, useImmer } from "use-immer";
import { SingleFieldUpdater } from "../utils/use-update";
import {
  Need,
  NeedDefinition,
  NeedDefinitionWithEditedFlags,
} from "../data/need";
import { Draft, produce } from "immer";
import {
  Box,
  Checkbox,
  FormLabel,
  HStack,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { NutritionInput } from "./nutrition-input";
import React from "react";
import { Plan2PlateIconButton } from "./buttons/plan2-plate-icon-button";
import { usePlan2PlateDisclosure } from "./plan2-plate-drawer";
import { useDrawerHistory } from "./drawer-history";
import { ComputeNeedDrawer } from "./compute-need-drawer";

export function getPercentFromAbsolute(
  n: Need,
  factor: number,
  absolute: number,
) {
  return ((absolute * factor) / n.kcal) * 100;
}

function getAbsoluteFromPercent(n: Need, factor: number, percent: number) {
  return (n.kcal * percent) / factor / 100;
}

function updatePercentNeeds<
  K extends "fatPercent" | "carbsPercent" | "proteinPercent",
>(
  k: K,
  v: NeedDefinitionWithEditedFlags[K],
  need: Draft<NeedDefinitionWithEditedFlags>,
  fixedField: FixedField,
) {
  const numericValue = v as number;
  const fixedValue = need[fixedField];
  const remainder = 100 - numericValue - fixedValue;
  switch (k) {
    case "fatPercent":
      if (fixedField == "proteinPercent") {
        need.carbsPercent = remainder;
        need.carbs = getAbsoluteFromPercent(need, 4, need.carbsPercent);
      } else {
        need.proteinPercent = remainder;
        need.protein = getAbsoluteFromPercent(need, 4, need.proteinPercent);
      }
      need.fat = getAbsoluteFromPercent(need, 9, numericValue);
      break;
    case "proteinPercent":
      if (fixedField == "fatPercent") {
        need.carbsPercent = remainder;
        need.carbs = getAbsoluteFromPercent(need, 4, need.carbsPercent);
      } else {
        need.fatPercent = remainder;
        need.fat = getAbsoluteFromPercent(need, 9, need.fatPercent);
      }
      need.protein = getAbsoluteFromPercent(need, 4, numericValue);
      break;
    case "carbsPercent":
      if (fixedField == "fatPercent") {
        need.proteinPercent = remainder;
        need.protein = getAbsoluteFromPercent(need, 4, need.proteinPercent);
      } else {
        need.fatPercent = remainder;
        need.fat = getAbsoluteFromPercent(need, 9, need.fatPercent);
      }
      need.carbs = getAbsoluteFromPercent(need, 4, numericValue);
      break;
  }
  need[k] = v;
}

type FixedField = "fatPercent" | "carbsPercent" | "proteinPercent";
type NeedEditorProps = {
  updateNeed: (updater: DraftFunction<NeedDefinitionWithEditedFlags>) => void;
  need: NeedDefinitionWithEditedFlags;
  personName?: string;
};

type NeedPreset = Pick<
  NeedDefinition,
  "fatPercent" | "carbsPercent" | "proteinPercent"
> & { fixedField: FixedField };

export function NeedEditor(props: NeedEditorProps) {
  const [fixedField, updateFixedField] = useImmer<FixedField>("carbsPercent");

  const drawerHistory = useDrawerHistory();

  const {
    isOpen: isOpenComputeBMR,
    onOpen: onOpenComputeBMR,
    onClose: onCloseComputeBMR,
  } = usePlan2PlateDisclosure(drawerHistory, "computeBMR");

  const needUpdater: SingleFieldUpdater<NeedDefinitionWithEditedFlags> = (
    k,
    v,
  ) => {
    const updater = (
      needsDraftElement: Draft<NeedDefinitionWithEditedFlags>,
    ) => {
      if (k != "personId" && k != "edited" && k != "deleted" && v != null) {
        const difference = (needsDraftElement[k] as number) - (v as number);
        if (Math.abs(difference) < 0.01) {
          return;
        }
      }
      needsDraftElement[k] = v;
      switch (k) {
        case "fat":
          updatePercentNeeds(
            "fatPercent",
            getPercentFromAbsolute(needsDraftElement, 9, v as number),
            needsDraftElement,
            fixedField,
          );
          break;
        case "carbs":
          updatePercentNeeds(
            "carbsPercent",
            getPercentFromAbsolute(needsDraftElement, 4, v as number),
            needsDraftElement,
            fixedField,
          );
          break;
        case "protein":
          updatePercentNeeds(
            "proteinPercent",
            getPercentFromAbsolute(needsDraftElement, 4, v as number),
            needsDraftElement,
            fixedField,
          );
          break;
        case "fatPercent":
          updatePercentNeeds(k, v as number, needsDraftElement, fixedField);
          break;
        case "carbsPercent":
          updatePercentNeeds(k, v as number, needsDraftElement, fixedField);
          break;
        case "proteinPercent":
          updatePercentNeeds(k, v as number, needsDraftElement, fixedField);
          break;
        case "kcal":
          needsDraftElement.fat = getAbsoluteFromPercent(
            needsDraftElement,
            9,
            needsDraftElement.fatPercent,
          );
          needsDraftElement.carbs = getAbsoluteFromPercent(
            needsDraftElement,
            4,
            needsDraftElement.carbsPercent,
          );
          needsDraftElement.protein = getAbsoluteFromPercent(
            needsDraftElement,
            4,
            needsDraftElement.proteinPercent,
          );
          break;
      }
      needsDraftElement.edited = true;
    };
    props.updateNeed(updater);
    return produce(props.need, updater);
  };

  const handleBlur = () => {};

  const leftAddonMinW = "90px";
  const heading = props.personName
    ? `Bedarf für ${props.personName}`
    : "Bedarf";
  const KETO: NeedPreset = {
    fatPercent: 75,
    carbsPercent: 5,
    proteinPercent: 20,
    fixedField: "carbsPercent",
  };
  const LOW_CARB: NeedPreset = {
    fatPercent: 60,
    carbsPercent: 20,
    proteinPercent: 20,
    fixedField: "carbsPercent",
  };
  const LOW_FAT: NeedPreset = {
    fatPercent: 20,
    carbsPercent: 55,
    proteinPercent: 25,
    fixedField: "fatPercent",
  };
  const RESTRICTION: NeedPreset = {
    fatPercent: 30,
    carbsPercent: 40,
    proteinPercent: 30,
    fixedField: "proteinPercent",
  };

  function setPresets(preset: NeedPreset) {
    const need = props.need;
    const updater = (needDraft: NeedDefinitionWithEditedFlags) => {
      needDraft.fat = getAbsoluteFromPercent(need, 9, preset.fatPercent);
      needDraft.carbs = getAbsoluteFromPercent(need, 4, preset.carbsPercent);
      needDraft.protein = getAbsoluteFromPercent(
        need,
        4,
        preset.proteinPercent,
      );
      needDraft.fatPercent = preset.fatPercent;
      needDraft.carbsPercent = preset.carbsPercent;
      needDraft.proteinPercent = preset.proteinPercent;
    };
    props.updateNeed(updater);
    updateFixedField(preset.fixedField);
  }

  return (
    <Box className="editor-box" key={props.need.personId}>
      <ComputeNeedDrawer
        open={isOpenComputeBMR}
        onClose={() => {
          onCloseComputeBMR();
        }}
        onSubmit={(bmr) => {
          needUpdater("kcal", bmr);
        }}
      />
      <Stack>
        <FormLabel htmlFor={`person-${props.need.personId}`} fontSize="lg">
          {heading}
        </FormLabel>
        <HStack>
          <Plan2PlateIconButton
            title={"Keto"}
            aria-label={"keto"}
            icon={"keto"}
            onClick={() => setPresets(KETO)}
          />
          <Plan2PlateIconButton
            title={"Low carb"}
            aria-label={"low carb"}
            icon={"lowCarb"}
            onClick={() => setPresets(LOW_CARB)}
          />
          <Plan2PlateIconButton
            title={"Low Fat"}
            aria-label={"low Fat"}
            icon={"lowFat"}
            onClick={() => setPresets(LOW_FAT)}
          />
          <Plan2PlateIconButton
            title={"Restriction"}
            aria-label={"restriction"}
            icon={"restriction"}
            onClick={() => setPresets(RESTRICTION)}
          />
        </HStack>
        <VStack width={"100%"} alignItems={"start"}>
          <HStack>
            <NutritionInput
              fieldName="kcal"
              fieldDescription="Kalorien"
              value={props.need.kcal}
              updater={needUpdater}
              handleBlur={handleBlur}
            />
            <Plan2PlateIconButton
              aria-label={"computeBMR"}
              title={"Grundumsatz berechnen"}
              icon={"calculator"}
              onClick={() => {
                onOpenComputeBMR();
              }}
            />
          </HStack>
          <HStack>
            <NutritionInput
              disabled={fixedField == "fatPercent"}
              fieldName="fat"
              fieldDescription="Fett"
              fieldUnit="g"
              value={props.need.fat}
              updater={needUpdater}
              handleBlur={handleBlur}
              leftAddonMinW={leftAddonMinW}
            />
            <NutritionInput
              disabled={fixedField == "fatPercent"}
              fieldName="fatPercent"
              fieldDescription="Fett"
              fieldUnit="%"
              value={props.need.fatPercent}
              updater={needUpdater}
              handleBlur={handleBlur}
              leftAddonMinW={leftAddonMinW}
            />
            <Checkbox
              colorScheme={"teal"}
              isChecked={fixedField == "fatPercent"}
              onChange={(e) =>
                updateFixedField(
                  e.target.checked ? "fatPercent" : "carbsPercent",
                )
              }
            />
          </HStack>
          <HStack>
            <NutritionInput
              disabled={fixedField == "carbsPercent"}
              fieldName="carbs"
              fieldDescription="KH"
              fieldUnit="g"
              value={props.need.carbs}
              updater={needUpdater}
              handleBlur={handleBlur}
              leftAddonMinW={leftAddonMinW}
            />
            <NutritionInput
              disabled={fixedField == "carbsPercent"}
              fieldName="carbsPercent"
              fieldDescription="KH"
              fieldUnit="%"
              value={props.need.carbsPercent}
              updater={needUpdater}
              handleBlur={handleBlur}
              leftAddonMinW={leftAddonMinW}
            />
            <Checkbox
              colorScheme={"teal"}
              isChecked={fixedField == "carbsPercent"}
              onChange={(e) =>
                updateFixedField(
                  e.target.checked ? "carbsPercent" : "fatPercent",
                )
              }
            />
          </HStack>
          <HStack>
            <NutritionInput
              disabled={fixedField == "proteinPercent"}
              fieldName="protein"
              fieldDescription="Protein"
              fieldUnit="g"
              value={props.need.protein}
              updater={needUpdater}
              handleBlur={handleBlur}
              leftAddonMinW={leftAddonMinW}
            />
            <NutritionInput
              disabled={fixedField == "proteinPercent"}
              fieldName="proteinPercent"
              fieldDescription="Protein"
              fieldUnit="%"
              value={props.need.proteinPercent}
              updater={needUpdater}
              handleBlur={handleBlur}
              leftAddonMinW={leftAddonMinW}
            />
            <Checkbox
              colorScheme={"teal"}
              isChecked={fixedField == "proteinPercent"}
              onChange={(e) =>
                updateFixedField(
                  e.target.checked ? "proteinPercent" : "carbsPercent",
                )
              }
            />
          </HStack>
        </VStack>
      </Stack>
    </Box>
  );
}
