import { Card } from "@chakra-ui/react";
import React from "react";

export const SearchResultCard = (props: {
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <Card py={"3px"} px={"5px"} margin={"4px"} onClick={props.onClick}>
      {props.children}
    </Card>
  );
};
