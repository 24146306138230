import {
  MealIngredient,
  MealWithIngredients,
} from "../data/meal-with-ingredients";
import { MealWithIntakes } from "../data/plan";
import { FoodLookup, PurchaseLookup } from "../data/lookups";
import { formatFoodUnitWithAmountDescription } from "./food-utils";
import { isNullIndex } from "./plan-utils";
import { formatPurchaseUnitWithAmountDescription } from "./purchase-utils";

export function getMealKey(meal: MealWithIngredients) {
  return meal.dishId
    ? `dish${meal.dishId}`
    : meal.foodId
      ? `food${meal.foodId}`
      : `purchase${meal.purchaseId}`;
}

function getMealIngredientDescriptionForFood(
  firstMealIngredient: MealIngredient,
  foodLookup: FoodLookup,
  foodUnitFoodId: number,
  mealIngredients: {
    meal: MealWithIntakes;
    mealIngredient: MealIngredient;
  }[],
) {
  const foodUnitId = firstMealIngredient.foodUnitId;
  const food = foodLookup[foodUnitFoodId];
  const foodUnit = food.foodUnits.find((fu) => fu.id == foodUnitId)!;
  const sumOfAmount = mealIngredients
    .map((mealIngredient) => mealIngredient.mealIngredient.amount)
    .reduce((a, b) => a + b, 0);
  return formatFoodUnitWithAmountDescription(food, foodUnit, sumOfAmount);
}

function getMealIngredientDescriptionForPurchase(
  firstMealIngredient: MealIngredient,
  purchaseLookup: PurchaseLookup,
  purchaseUnitPurchaseId: number,
  mealIngredients: {
    meal: MealWithIntakes;
    mealIngredient: MealIngredient;
  }[],
) {
  const purchaseUnitId = firstMealIngredient.purchaseUnitId;
  const purchase = purchaseLookup[purchaseUnitPurchaseId];
  const purchaseUnit = purchase.purchaseUnits.find(
    (pu) => pu.id == purchaseUnitId,
  )!;
  const sumOfAmount = mealIngredients
    .map((mealIngredient) => mealIngredient.mealIngredient.amount)
    .reduce((a, b) => a + b, 0);
  return formatPurchaseUnitWithAmountDescription(
    purchase,
    purchaseUnit,
    sumOfAmount,
  );
}

export function getMealIngredientDescription(
  mealIngredients: {
    meal: MealWithIntakes;
    mealIngredient: MealIngredient;
  }[],
  foodLookup: FoodLookup,
  purchaseLookup: PurchaseLookup,
) {
  const firstMealIngredient = mealIngredients[0].mealIngredient;
  const foodUnitFoodId = firstMealIngredient.foodUnitFoodId;
  return isNullIndex(foodUnitFoodId)
    ? getMealIngredientDescriptionForPurchase(
        firstMealIngredient,
        purchaseLookup,
        firstMealIngredient.purchaseUnitPurchaseId,
        mealIngredients,
      )
    : getMealIngredientDescriptionForFood(
        firstMealIngredient,
        foodLookup,
        foodUnitFoodId,
        mealIngredients,
      );
}
