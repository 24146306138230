import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Food from "../data/food";
import { SearchIcon } from "@chakra-ui/icons";
import { SearchResultCard } from "../components/search-result-card";
import { getPerformSearch } from "../utils/search-utils";
import { NutriSlider, useNutriSlider } from "../components/nutri-slider";
import { useImmer } from "use-immer";
import InfiniteScroll from "react-infinite-scroll-component";
import { AddButton } from "../components/buttons/add-button";
import { formatFoodDescription } from "../utils/food-utils";

const FoodSearchPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, updateSearchResults] = useImmer<Food[]>([]);
  const [searchResultCount, setSearchResultCount] = useState(0);

  const nutriSliderProps = useNutriSlider("food");

  const { performSearch, fetchMoreData } = getPerformSearch(
    undefined,
    nutriSliderProps,
    searchTerm,
    searchResults,
    updateSearchResults,
    setSearchResultCount,
    `/api/food/search`,
  );

  useEffect(() => {
    performSearch(true);
  }, [
    nutriSliderProps.minFat,
    nutriSliderProps.maxFat,
    nutriSliderProps.minCarbs,
    nutriSliderProps.maxCarbs,
    nutriSliderProps.minProtein,
    nutriSliderProps.maxProtein,
    nutriSliderProps.minKcal,
    nutriSliderProps.maxKcal,
  ]);

  const navigate = useNavigate();

  const createFood = () => {
    navigate("/food/create");
  };
  const editFood = (id: number) => {
    navigate(`/food/edit/${id}`);
  };

  return (
    <VStack className="stretch-stack-with-padding">
      <HStack width={"100%"}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type={"search"}
            placeholder="Suche nach Essen"
            value={searchTerm}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                performSearch(true);
              }
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </HStack>
      <Accordion allowToggle={true} width={"100%"}>
        <AccordionItem>
          <AccordionButton>
            <Box as="span" flex={1} textAlign={"left"} fontWeight={"bold"}>
              {"Nährwerte"}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel motionProps={{ animateOpacity: false }}>
            <NutriSlider {...nutriSliderProps} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <InfiniteScroll
        dataLength={searchResults.length}
        next={fetchMoreData}
        hasMore={searchResults.length < searchResultCount}
        loader={<h4>Loading...</h4>}
        style={{ width: "100%" }}
      >
        <Flex flexWrap={"wrap"} width={"100%"}>
          {searchResults.map((food) => (
            <SearchResultCard key={food.id} onClick={() => editFood(food.id)}>
              {formatFoodDescription(food)}
            </SearchResultCard>
          ))}
        </Flex>
      </InfiniteScroll>
      <HStack justifyContent={"center"}>
        <AddButton onClick={createFood} />
      </HStack>
    </VStack>
  );
};

export default FoodSearchPage;
