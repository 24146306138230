import React from "react";
import { AccordionButton, AccordionIcon, Box } from "@chakra-ui/react";

export function Plan2PlateAccordionHeading(props: {
  description: string;
  children?: React.ReactElement | React.ReactElement[];
}) {
  return (
    <h2>
      <AccordionButton>
        <Box as="span" flex={1} textAlign={"left"} fontWeight={"bold"}>
          {props.description}
        </Box>
        {props.children}
        <AccordionIcon />
      </AccordionButton>
    </h2>
  );
}
