import { VStack } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { useImmer } from "use-immer";
import { isEdited } from "../utils/edit-utils";
import { Need, NeedDefinitionWithEditedFlags } from "../data/need";
import { usePersonLoader } from "../loaders";
import { keys, sortBy, values } from "lodash";
import { NeedEditor } from "../components/need-editor";
import { needToNeedDefinition } from "../utils/person-utils";
import { SaveButton } from "../components/buttons/save-button";
import { axiosInstance } from "../keycloak";
import { LoadingComponent } from "../components/loading-component";
import { useUnloadAlert } from "../components/use-unload-alert";

const NeedEditPage = () => {
  const [needs, updateNeeds] = useImmer<
    NeedDefinitionWithEditedFlags[] | undefined
  >(undefined);
  const { loadPerson, personLookup } = usePersonLoader(true);

  async function loadNeeds() {
    const needs = (await axiosInstance.get<Need[]>(`/api/need`)).data;
    updateNeeds(needs.map(needToNeedDefinition));
  }

  const loadData = useCallback(async () => {
    await loadNeeds();
    await loadPerson();
  }, []);

  useEffect(() => {
    if (keys(personLookup).length > 0) {
      updateNeeds(sortBy(needs, (n) => personLookup[n.personId].name));
    }
  }, [personLookup, needs?.length]);

  const saveNeeds = async () => {
    const editedNeeds = needs!.filter(isEdited);
    await axiosInstance.put<Need[]>(`/api/need`, editedNeeds);
    await loadData();
    return true;
  };

  const { pristine, setPristine } = useUnloadAlert();
  return (
    <LoadingComponent
      loadData={[loadNeeds, loadPerson]}
      notEmptyCondition={[values(personLookup)]}
    >
      <VStack className="stack-with-padding">
        {(needs || []).map((need, index) => {
          const person = personLookup[need.personId];
          return (
            person?.active && (
              <NeedEditor
                key={need.personId}
                updateNeed={(updater) => {
                  updateNeeds((needs) => {
                    if (needs != undefined) {
                      updater(needs[index]);
                    }
                  });
                  setPristine(false);
                }}
                need={need}
                personName={person?.name}
              />
            )
          );
        })}
        <SaveButton isDisabled={pristine} save={saveNeeds} />
      </VStack>
    </LoadingComponent>
  );
};

export default NeedEditPage;
