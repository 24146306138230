import Food from "./food";
import { Person } from "./person";
import { Purchase } from "./purchase";
import { PersonDish } from "./dish";
import { Department } from "./department";
import { Shop } from "./shop";
import { Availability } from "./availability";
import { Stock } from "./stock";

export interface PersonLookup {
  [personId: number]: Person;
}

export interface FoodLookup {
  [foodId: number]: Food;
}

export interface PurchaseLookup {
  [purchaseId: number]: Purchase;
}

export interface PersonDishLookup {
  [personId: number]: PersonDish[];
}

export interface PersonDishByDishLookup {
  [dishId: number]: PersonDish;
}

export interface ShopLookup {
  [shopId: number]: Shop;
}

export interface DepartmentLookup {
  [departmentId: number]: Department;
}

export interface AvailabilityByPurchaseIdLookup {
  [purchaseId: number]: Availability[];
}

export interface AvailabilityByIdLookup {
  [id: number]: Availability;
}

export interface StockByFoodUnitIdLookup {
  [foodUnitId: number]: Stock;
}

export type WithId = { id: number };

export function isWithId(obj: object): obj is WithId {
  // @ts-ignore
  return Object.hasOwn(obj, "id") && typeof obj.id == "number";
}

export type LookupType<T> = {
  [id: number]: T;
};
