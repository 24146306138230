import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import React, { useState } from "react";
import {
  Icon,
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiChevronDown, FiChevronUp } from "react-icons/all";
import { uniq } from "lodash";
import { modes } from "../theme/tokens";

export type Plan2PlateAutcompleteMultipleProps<T> = {
  getDescription: (obj: T) => string;
  getDisplayString?: (obj: T) => string;
  choices: T[];
  onChange?: (obj: T[]) => void;
  selectColor?: string;
  itemColor?: (obj: T) => string;
  placeholder?: string;
  minWidth?: string;
  maxWidth?: string;
  value?: string[];
  width?: string;
};

export const Plan2PlateAutcompleteMultiple = <T,>(
  props: Plan2PlateAutcompleteMultipleProps<T>,
) => {
  const [inputValue, setInputValue] = useState<string>("");
  const choices = uniq(props.choices);
  const onChange = (e: string[]) => {
    props.onChange &&
      props.onChange(
        choices.filter((d) => e.includes(props.getDescription(d))),
      );
  };

  const backgroundColor = useColorModeValue(
    modes.light.bgPopup,
    modes.dark.bgPopup,
  );

  return (
    <div
      style={{
        width: props.width,
        minWidth: props.minWidth,
        maxWidth: props.maxWidth,
      }}
    >
      <AutoComplete
        openOnFocus
        onChange={onChange}
        value={props.value}
        multiple
      >
        {({ isOpen }) => (
          <>
            <InputGroup
              maxW={props.maxWidth}
              minW={props.minWidth}
              width={props.width}
            >
              <AutoCompleteInput
                color={props.selectColor}
                placeholder={props.placeholder}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              >
                {({ tags }) =>
                  tags.map((tag, tid) => (
                    <AutoCompleteTag
                      label={tag.label}
                      key={tid}
                      onRemove={tag.onRemove}
                    />
                  ))
                }
              </AutoCompleteInput>
              <InputRightElement
                children={<Icon as={isOpen ? FiChevronUp : FiChevronDown} />}
              />
            </InputGroup>
            <AutoCompleteList
              maxW={props.maxWidth}
              minW={props.minWidth}
              width={props.width}
              backgroundColor={backgroundColor}
              border={"inherit"}
              borderWidth="1px"
            >
              {choices.map((obj) => (
                <AutoCompleteItem
                  backgroundColor={backgroundColor}
                  color={
                    props.itemColor != undefined
                      ? props.itemColor(obj)
                      : undefined
                  }
                  key={props.getDescription(obj)}
                  value={props.getDescription(obj)}
                  onClick={(e) => setInputValue("")}
                >
                  {props.getDisplayString
                    ? props.getDisplayString(obj)
                    : props.getDescription(obj)}
                </AutoCompleteItem>
              ))}
            </AutoCompleteList>
          </>
        )}
      </AutoComplete>
    </div>
  );
};
