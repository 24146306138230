import { Button, HStack, StackProps } from "@chakra-ui/react";
import React from "react";
import { plan2plateIcons } from "../../utils/icon-utils";
import { Plan2PlateIcon } from "../plan2-plate-icon";
import { otherConsts } from "../../theme/tokens";

export function FloatingButton(props: {
  onClick: () => void;
  caption: string;
  position?: StackProps["position"];
  zIndex?: StackProps["zIndex"];
  leftIcon?: keyof typeof plan2plateIcons;
  isLoading?: boolean;
  maxW?: string;
}) {
  return (
    <HStack
      width={"100%"}
      left={"0px"}
      maxW={props.maxW || otherConsts.maxW}
      bottom={"16px"}
      position={props.position || "absolute"}
      zIndex={props.zIndex}
      justifyContent={"center"}
      visibility={"hidden"}
    >
      <Button
        paddingLeft={props.leftIcon && "8px"}
        leftIcon={props.leftIcon && <Plan2PlateIcon icon={props.leftIcon} />}
        visibility={"visible"}
        backgroundColor={"#009688"}
        color={"white"}
        variant="solid"
        isLoading={props.isLoading}
        onClick={props.onClick}
      >
        {props.caption}
      </Button>
    </HStack>
  );
}
