import { keys } from "lodash";
import { PersonLookup } from "./lookups";

export interface PersonRecipeUnitModification {
  id?: number;
  personId: number;
  amount: number;
}

export interface RecipeUnitModification {
  id: number;
  foodUnitId: number;
  foodId: number;
  portionAmount?: number;
  personRecipeUnitModificationDtos: PersonRecipeUnitModification[];
}

export interface PersonPortionAmount {
  personId: number;
  amount: number;
}

export interface DishModification {
  id?: number;
  description: string;
  purchaseRelevant: boolean;
  numberOfUnits?: number;
  isCookingBook: boolean;
  personPortions: PersonPortionAmount[];
  recipeUnitModificationDtos: RecipeUnitModification[];
}

export const emptyDish = (personLookup: PersonLookup): DishModification => {
  return {
    purchaseRelevant: false,
    description: "",
    recipeUnitModificationDtos: [],
    isCookingBook: false,
    personPortions: keys(personLookup).map((personId) => {
      return {
        personId: Number(personId),
        amount: 0,
      };
    }),
  };
};
