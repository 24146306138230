import { Box, FormLabel, HStack } from "@chakra-ui/react";
import React from "react";
import {
  nutriLookup,
  NutriValues,
  NutriValuesWithoutKcal,
} from "../data/nutri-values";
import { computeCalories } from "../utils/calories-util";
import { round } from "mathjs";

interface NutriBarProps {
  nutriValues: NutriValuesWithoutKcal | undefined;
}

export const NutriBar = (props: NutriBarProps) => {
  if (props.nutriValues == undefined) {
    return <></>;
  }
  const fatColor = nutriLookup["fat"].color;
  const carbsColor = nutriLookup["carbs"].color;
  const proteinColor = nutriLookup["protein"].color;
  const computedKcal = computeCalories(props.nutriValues);
  const breakpoint1 = ((props.nutriValues.fat * 9) / computedKcal) * 100;
  const breakpoint2 =
    ((props.nutriValues.carbs * 4) / computedKcal) * 100 + breakpoint1;

  return (
    <Box
      height={"10px"}
      backgroundImage={`linear-gradient(to right, ${fatColor} 0%, ${fatColor} ${breakpoint1}%, ${carbsColor} ${breakpoint1}%, ${carbsColor} ${breakpoint2}%, ${proteinColor} ${breakpoint2}%, ${proteinColor} 100% )`}
    />
  );
};

interface NutriBulletProps {
  nutriValues: NutriValuesWithoutKcal | undefined;
  factor: number | undefined;
}

export const NutriBullets = (props: NutriBulletProps) => {
  if (
    props.nutriValues == undefined ||
    props.factor == undefined ||
    props.factor == 0
  ) {
    return <></>;
  }
  const carbsValue = round((props.nutriValues.carbs / 100) * props.factor, 2);
  const fatValue = round((props.nutriValues.fat / 100) * props.factor, 2);
  const proteinValue = round(
    (props.nutriValues.protein / 100) * props.factor,
    2,
  );
  return (
    <HStack>
      <Box
        backgroundColor={nutriLookup["fat"].color}
        height={"7px"}
        width={"7px"}
      ></Box>
      <FormLabel marginRight={0} fontSize={"12px"} marginBottom={0}>
        {nutriLookup["fat"].shortName}: {fatValue}g
      </FormLabel>
      <Box
        backgroundColor={nutriLookup["carbs"].color}
        height={"7px"}
        width={"7px"}
      ></Box>
      <FormLabel marginRight={0} fontSize={"12px"} marginBottom={0}>
        {nutriLookup["carbs"].shortName}: {carbsValue}g
      </FormLabel>
      <Box
        backgroundColor={nutriLookup["protein"].color}
        height={"7px"}
        width={"7px"}
      ></Box>
      <FormLabel marginRight={0} fontSize={"12px"} marginBottom={0}>
        {nutriLookup["protein"].shortName}: {proteinValue}g
      </FormLabel>
    </HStack>
  );
};

export function KcalFormLabel(props: { kcal: number }) {
  return (
    <FormLabel fontSize={"12px"} m={0}>
      Kalorien: {round(props.kcal, 0)} kcal
    </FormLabel>
  );
}

export function NutriBarWithKcalAndBullets(props: {
  nutriValues: NutriValues;
  factor: number;
}) {
  return (
    <>
      <KcalFormLabel kcal={props.nutriValues.kcal} />
      <NutriBar
        nutriValues={{
          fat: props.nutriValues.fat,
          carbs: props.nutriValues.carbs,
          protein: props.nutriValues.protein,
        }}
      />
      <NutriBullets
        nutriValues={{
          fat: props.nutriValues.fat,
          carbs: props.nutriValues.carbs,
          protein: props.nutriValues.protein,
        }}
        factor={props.factor}
      />
    </>
  );
}
