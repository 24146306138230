import React from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { BarcodeScanner } from "./barcode-scanner";

export function SearchInput(props: {
  value: string | undefined;
  performSearch: (isNewSearch: boolean) => void;
  setSearchTerm: (v: string) => void;
  placeholder?: string;
  onBarcodeScan?: (value: number | undefined) => void;
}) {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon />
      </InputLeftElement>
      <Input
        type={"search"}
        value={props.value}
        placeholder={props.placeholder}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            props.performSearch(true);
          }
        }}
        onChange={(e) => props.setSearchTerm(e.target.value)}
      />
      {props.onBarcodeScan && (
        <InputRightElement>
          <BarcodeScanner onBarcodeScan={props.onBarcodeScan} />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
