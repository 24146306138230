import React from "react";
import { DishEditor } from "../components/dish-editor";
import { emptyDish } from "../data/dish-modification";
import {
  useAvailabilityByPurchaseIdLookupLoader,
  useDepartmentLoader,
  useFoodLookupLoader,
  usePersonLoader,
  usePurchaseLookupLoader,
  useShopLookupLoader,
} from "../loaders";
import {
  DepartmentLookupContext,
  DrawerHistoryContext,
  FoodLookupWithRefreshContext,
  ShopLookupWithRefreshContext,
} from "../contexts";
import { LoadingComponent } from "../components/loading-component";
import { Box } from "@chakra-ui/react";
import { useDrawerHistory } from "../components/drawer-history";
import { useNavigate } from "react-router-dom";

export const DishCreatePage: React.FC = () => {
  const { foodLookup, loadFood } = useFoodLookupLoader();
  const { loadPurchase, purchaseLookup } = usePurchaseLookupLoader();
  const { loadPerson, personLookup } = usePersonLoader(false);
  const { availabilityByPurchaseIdLookup, loadAvailabilities } =
    useAvailabilityByPurchaseIdLookupLoader();
  const { loadShop, shopLookup } = useShopLookupLoader();
  const { departmentLookup, loadDepartment } = useDepartmentLoader();

  const drawerHistory = useDrawerHistory();
  const navigate = useNavigate();

  return (
    <LoadingComponent
      loadData={[
        loadPerson,
        loadAvailabilities,
        loadShop,
        loadDepartment,
        loadFood,
        loadPurchase,
      ]}
    >
      <ShopLookupWithRefreshContext.Provider value={{ shopLookup, loadShop }}>
        <DepartmentLookupContext.Provider value={departmentLookup}>
          <FoodLookupWithRefreshContext.Provider
            value={{ foodLookup, loadFood }}
          >
            <DrawerHistoryContext.Provider value={drawerHistory}>
              <Box p={4}>
                <DishEditor
                  isCreate={true}
                  foodLookup={foodLookup}
                  purchaseLookup={purchaseLookup}
                  personLookup={personLookup}
                  initialDish={emptyDish(personLookup)}
                  availabilityByPurchaseIdLookup={
                    availabilityByPurchaseIdLookup
                  }
                  loadAvailabilities={loadAvailabilities}
                  afterSave={(dish) => navigate(`/dish/edit/${dish.id}`)}
                />
              </Box>
            </DrawerHistoryContext.Provider>
          </FoodLookupWithRefreshContext.Provider>
        </DepartmentLookupContext.Provider>
      </ShopLookupWithRefreshContext.Provider>
    </LoadingComponent>
  );
};
