import React, { useRef } from "react";
import { Plan2PlateIconButton } from "./buttons/plan2-plate-icon-button";
import { Input } from "@chakra-ui/react";
import { axiosInstance } from "../keycloak";

export const BarcodeScanner = (props: {
  onBarcodeScan?: (value: number | undefined) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <Plan2PlateIconButton
        icon={"barcode"}
        size={"sm"}
        title={"barcode"}
        aria-label={"barcode"}
        onClick={() => {
          inputRef.current?.click();
        }}
      />
      <Input
        ref={inputRef}
        display={"none"}
        type={"file"}
        capture={"environment"}
        onChange={async (e) => {
          const files = e.target.files;
          if (files) {
            for (let i = 0; i < files.length; i++) {
              const file = files.item(i);
              if (file) {
                const formData = new FormData();
                formData.set("file", file);
                const result = (await axiosInstance.post(`/api/scan`, formData))
                  .data;
                const eanNumber = Number(result);
                const ean = isNaN(eanNumber) ? undefined : eanNumber;
                props.onBarcodeScan && props.onBarcodeScan(ean);
              }
            }
          }
        }}
      />
    </>
  );
};
