import React, { ReactElement } from "react";
import { ObjectWithStringSignature } from "./component-types";
import { Plan2PlateInput, Plan2PlateInputProps } from "./plan2-plate-input";
import { forwardRef } from "@chakra-ui/react";

type NumericKeys<T> = keyof { [K in keyof T]: T[K] extends number ? K : never };

export type NumberInputProps<
  T extends ObjectWithStringSignature,
  K extends NumericKeys<T> & string,
> = Omit<
  Plan2PlateInputProps<T, K, number>,
  | "inputType"
  | "valueConverter"
  | "leftAddon"
  | "rightAddon"
  | "leftAddonLabel"
  | "rightAddonLabel"
> & {
  updater: (fieldname: K, value: number) => void;
  fieldDescription?: string;
  fieldUnit?: string;
  precision?: number;
};

const NumberInputInternal = <
  T extends ObjectWithStringSignature,
  K extends NumericKeys<T> & string,
>(
  props: NumberInputProps<T, K>,
  ref: React.ForwardedRef<any>,
): ReactElement => {
  return (
    <Plan2PlateInput
      {...props}
      ref={ref}
      value={props.value}
      valueFormatter={(value) =>
        props.precision ? (Number(value).toFixed(2) as unknown as T[K]) : value
      }
      isInvalid={props.isInvalid || props.value < 0}
      inputType={"number"}
      valueConverter={(v) => Number(v)}
      leftAddonLabel={props.fieldDescription}
      rightAddonLabel={props.fieldUnit}
      placeholder={props.placeholder || props.fieldDescription}
    />
  );
};

export const NumberInput = forwardRef(NumberInputInternal) as <
  T extends ObjectWithStringSignature,
  K extends NumericKeys<T> & string,
>(
  props: NumberInputProps<T, K> & { ref?: React.Ref<any> },
) => ReactElement;
