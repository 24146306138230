import { Plan2PlateDrawer, Plan2PlateDrawerHeader } from "./plan2-plate-drawer";
import React from "react";
import { VStack } from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";

export const SettingsDrawer = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Plan2PlateDrawer
      open={props.open}
      onClose={props.onClose}
      header={<Plan2PlateDrawerHeader heading={"Einstellungen"} />}
      body={
        <VStack className="stretch-stack">
          <ColorModeSwitcher justifySelf="flex-end" />
        </VStack>
      }
    />
  );
};
