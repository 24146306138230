import { Need, NeedDefinition, NeedTimeslice } from "../data/need";
import { parseDate } from "./date-utils";
import { getPercentFromAbsolute } from "../components/need-editor";

export function getNeedForDate(personNeeds: NeedTimeslice[], date: string) {
  return personNeeds.find(
    (need) =>
      parseDate(need.validFrom).getTime() <= parseDate(date).getTime() &&
      (need.validTo == undefined ||
        parseDate(need.validTo).getTime() > parseDate(date).getTime()),
  )!;
}

export const needToNeedDefinition = (n: Need): NeedDefinition => {
  const fatPercent = getPercentFromAbsolute(n, 9, n.fat);
  const carbsPercent = getPercentFromAbsolute(n, 4, n.carbs);
  const proteinPercent = getPercentFromAbsolute(n, 4, n.protein);
  return {
    ...n,
    fatPercent,
    carbsPercent,
    proteinPercent,
  };
};
