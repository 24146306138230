import { NeedTimeslice } from "../data/need";
import { NutriComponent } from "./nutri-component";
import { HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { emptyNutriValue, NutriValues } from "../data/nutri-values";
import { nutriValueSumReducer } from "../utils/plan-utils";

export function AllNutriValues(props: {
  nutriValues: NutriValues[];
  needForDate: NeedTimeslice;
  mode: "horizontal" | "vertical";
}) {
   
    const nutriValueSum = props.nutriValues.reduce(nutriValueSumReducer, emptyNutriValue);
  const components = (
    <>
      <NutriComponent
        variant={"linear"}
        name={"fat"}
        value={nutriValueSum.fat}
        need={props.needForDate.fat}
      />
      <NutriComponent
        variant={"linear"}
        name={"carbs"}
        value={nutriValueSum.carbs}
        need={props.needForDate.carbs}
      />
      <NutriComponent
        variant={"linear"}
        name={"protein"}
        value={nutriValueSum.protein}
        need={props.needForDate.protein}
      />
    </>
  );
  return (
    <VStack width={"100%"}>
      <NutriComponent
        variant={"circular"}
        name={"kcal"}
        value={nutriValueSum.kcal}
        need={props.needForDate.kcal}
      />
      {props.mode == "vertical" ? (
        <VStack maxW={"250px"} width={"100%"}>
          {components}
        </VStack>
      ) : (
        <HStack gap={"40px"} width={"100%"}>
          {components}
        </HStack>
      )}
    </VStack>
  );
}
