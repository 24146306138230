import React, { ForwardedRef, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  forwardRef,
  Input,
} from "@chakra-ui/react";
import { InputProps } from "@chakra-ui/input/dist/input";

export type SimpleInputProps = Pick<
  InputProps,
  | "value"
  | "isInvalid"
  | "placeholder"
  | "onBlur"
  | "onFocus"
  | "onKeyDown"
  | "type"
  | "name"
  | "width"
> & {
  caption?: string;
  disabled?: boolean;
};

const SimpleInputInternal = (
  props: SimpleInputProps,
  ref: ForwardedRef<any>,
) => {
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  return (
    <Box width={props.width}>
      <FormControl
        marginTop={props.caption != null ? "5px" : undefined}
        variant="floating"
        width={props.width || "fit-content"}
      >
        <Input
          ref={ref}
          isInvalid={props.isInvalid}
          width={props.width}
          isDisabled={props.disabled}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          value={value || ""}
          onChange={(e) => setValue(e.target.value)}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onKeyDown={props.onKeyDown}
        />
        {props.caption != null && (
          <FormLabel padding={"0px"}>{props.caption}</FormLabel>
        )}
      </FormControl>
    </Box>
  );
};

export const SimpleInput = forwardRef(SimpleInputInternal);
