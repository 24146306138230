export const colors = {
  black: "#000000",
  white: "#FFFFFF",
  gray: {
    50: "#f7fafc",
    100: "#edf2f7",
    200: "#e2e8f0",
    300: "#cbd5e0",
    400: "#a0aec0",
    500: "#718096",
    600: "#4a5568",
    700: "#2d3748",
    800: "#1a202c",
    900: "#171923",
  },
  teal: {
    50: "#e6fffa",
    100: "#b2f5ea",
    200: "#81e6d9",
    300: "#4fd1c5",
    400: "#38b2ac",
    500: "#319795",
    600: "#2c7a7b",
    700: "#285e61",
    800: "#234e52",
    900: "#1d4044",
  },
  accent: {
    blue: {
      light: "#3366cc",
      dark: "#729acf",
    },
    orange: {
      light: "#ff8c42",
      dark: "#ff9355",
    },
    teal: {
      light: "#00a79d",
      dark: "#00b3a4",
    },
    red: {
      light: "#e63946",
      dark: "#f06571",
    },
  },
};

export const modes = {
  light: {
    background: colors.white,
    text: colors.black,
    primary: colors.accent.blue.light,
    secondary: colors.gray[200],
    bgPopup: colors.white,
  },
  dark: {
    background: colors.gray[800],
    text: colors.white,
    primary: colors.accent.blue.dark,
    secondary: colors.gray[600],
    bgPopup: colors.gray[700],
  },
};

export const fontSizes = {
  xs: "0.75rem", // 12px
  sm: "0.875rem", // 14px
  md: "1rem", // 16px
  lg: "1.125rem", // 18px
  xl: "1.25rem", // 20px
  "2xl": "1.5rem", // 24px
  "3xl": "1.875rem", // 30px
  "4xl": "2.25rem", // 36px
  "5xl": "3rem", // 48px
  "6xl": "3.75rem", // 60px
  "7xl": "4.5rem", // 72px
  "8xl": "6rem", // 96px
  "9xl": "8rem", // 128px
};

export const otherConsts = {
  maxW: "800px",
  defaultPadding: "4",
  drawerPadding: "15px",
};
