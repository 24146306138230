import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  forwardRef,
  HStack,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Plan2PlateIcon } from "./plan2-plate-icon";
import { plan2plateIcons } from "../utils/icon-utils";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { DrawerHistoryContextType } from "../contexts";

export const usePlan2PlateDisclosure = (
  context: Pick<
    DrawerHistoryContextType,
    | "pushHistory"
    | "popHistory"
    | "hasHistory"
    | "history"
    | "setWindowHistoryStackSize"
  >,
  name?: string,
) => {
  const [identifier, setIdentifier] = useState(uuidv4());
  const navigate = useNavigate();

  const onOpen = () => {
    const uuid = uuidv4();
    setIdentifier(uuid);
    const state = { id: uuid, name };
    navigate("", { state: state });
    context.setWindowHistoryStackSize(window.history.length + 1);
    context.pushHistory(state);
  };

  const onClose = () => {
    if (!!context.history.length) {
      navigate(-1);
    }
    //    context.popHistory();
  };

  const isOpen = context.hasHistory(identifier);
  return { isOpen, onOpen, onClose };
};

export const Plan2PlateDrawer = forwardRef(
  (
    props: {
      onClose: () => void;
      open: boolean;
      header?: React.ReactElement;
      body?: React.ReactElement | null;
      footer?: React.ReactElement;
    },
    ref,
  ) => {
    return (
      <Drawer
        blockScrollOnMount={false}
        placement={"bottom"}
        onClose={props.onClose}
        isOpen={props.open}
        size={"full"}
      >
        <DrawerOverlay />
        <DrawerContent
          backgroundColor={"var(--chakra-colors-chakra-body-bg)"}
          paddingBottom={"10px"}
        >
          <DrawerHeader className="plan2plate-drawer">
            <HStack alignItems={"start"}>
              <DrawerCloseButton
                position={"relative"}
                top={"0px"}
                right={"0px"}
              />
              {props.header}
            </HStack>
          </DrawerHeader>
          <DrawerBody className="plan2plate-drawer">
            <VStack className="stretch-drawer-stack" ref={ref}>
              {props.body}
            </VStack>
          </DrawerBody>
          {props.footer && (
            <DrawerFooter className="plan2plate-drawer">
              {props.footer}
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    );
  },
);

export function Plan2PlateDrawerHeader(props: { heading: string }) {
  return <Box style={{ hyphens: "manual" }}>{props.heading}</Box>;
}

export function DrawerSelection(props: {
  onClick: () => void;
  icon: keyof typeof plan2plateIcons;
  heading: string;
  description: string;
}) {
  return (
    <Box className="editor-box" onClick={props.onClick}>
      <HStack>
        <Plan2PlateIcon icon={props.icon} />
        <VStack gap={0} alignItems={"start"}>
          <Box>{props.heading}</Box>
          <Box color={"grey"} fontSize={"small"}>
            {props.description}
          </Box>
        </VStack>
      </HStack>
    </Box>
  );
}
