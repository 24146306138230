import React from "react";
import { useParams } from "react-router-dom";
import { FoodEditor } from "../components/food-editor";
import {
  DepartmentLookupContext,
  PurchaseLookupContext,
  ShopLookupWithRefreshContext,
} from "../contexts";
import {
  useDepartmentLoader,
  useFoodLoader,
  usePurchaseLookupLoader,
  useShopLookupLoader,
} from "../loaders";
import { LoadingComponent } from "../components/loading-component";
import { VStack } from "@chakra-ui/react";

const FoodEditPage: React.FC = () => {
  const { foodId } = useParams();
  const foodIdNumeric = Number(foodId);
  const { food, loadFood } = useFoodLoader(foodIdNumeric);
  const { loadShop, shopLookup } = useShopLookupLoader();
  const { departmentLookup, loadDepartment } = useDepartmentLoader();
  const { loadPurchase, purchaseLookup } =
    usePurchaseLookupLoader(foodIdNumeric);
  return (
    <LoadingComponent
      loadData={[loadFood, loadShop, loadDepartment, loadPurchase]}
    >
      <ShopLookupWithRefreshContext.Provider value={{ loadShop, shopLookup }}>
        <DepartmentLookupContext.Provider value={departmentLookup}>
          <PurchaseLookupContext.Provider value={purchaseLookup}>
            <VStack className="stretch-stack-with-padding">
              <FoodEditor initialFood={food!} isCreate={false} />
            </VStack>
          </PurchaseLookupContext.Provider>
        </DepartmentLookupContext.Provider>
      </ShopLookupWithRefreshContext.Provider>
    </LoadingComponent>
  );
};

export default FoodEditPage;
