import { CreateToastFnReturn, forwardRef, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { Plan2PlateIconButton } from "./plan2-plate-icon-button";

export const wrapSave = async (
  doSave: () => Promise<boolean>,
  toast: CreateToastFnReturn,
  setLoading: (loading: boolean) => void,
) => {
  setLoading(true);
  try {
    const success = await doSave();

    if (success) {
      toast({
        title: "Speichern erfolgreich",
        status: "success",
        isClosable: true,
      });
    }
  } catch (e) {
    toast({
      title: "Fehler beim Speichern",
      status: "error",
      isClosable: true,
    });
    throw e;
  } finally {
    setLoading(false);
  }
};

export const SaveButton = forwardRef(
  (
    props: {
      save: () => Promise<boolean>;
      width?: string;
      children?: React.ReactNode;
      isDisabled?: boolean;
    },
    ref,
  ) => {
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const { save, ...withoutSave } = props;

    return (
      <Plan2PlateIconButton
        {...withoutSave}
        ref={ref}
        isLoading={loading}
        aria-label={"save"}
        title={"Speichern"}
        backgroundColor={"#009688"}
        style={{ stroke: "white" }}
        icon={"check"}
        onClick={() => {
          wrapSave(save, toast, setLoading);
        }}
      />
    );
  },
);
