import React, { useContext, useEffect } from "react";
import { ShopLookupWithRefreshContext } from "../contexts";
import { useImmer } from "use-immer";
import { Shop, ShopWithEditedFlags } from "../data/shop";
import { sortBy, values } from "lodash";
import { Box, Checkbox, HStack, useToast, VStack } from "@chakra-ui/react";
import { isEdited, isNew } from "../utils/edit-utils";
import { showValidationMessage } from "./show-validation-message";
import { useUnloadAlert } from "./use-unload-alert";
import { SingleFieldUpdater } from "../utils/use-update";
import { Draft, produce } from "immer";
import { StringInput } from "./string-input";
import { NumberInput } from "./number-input";
import { AtSignIcon } from "@chakra-ui/icons";
import { AddButton } from "./buttons/add-button";
import { SaveButton } from "./buttons/save-button";
import { axiosInstance } from "../keycloak";

function CheckboxIcon({
  isChecked,
  isIndeterminate,
  as: Element,
  ...rest
}: {
  isChecked?: boolean;
  isIndeterminate?: boolean;
  as: React.ElementType;
}) {
  return <Element {...rest} />;
}

export function ShopEditor(props: { onSave?: () => void }) {
  const { loadShop, shopLookup } = useContext(ShopLookupWithRefreshContext);
  const [shops, updateShops] = useImmer<ShopWithEditedFlags[] | undefined>(
    undefined,
  );

  useEffect(() => {
    updateShops(sortBy(values(shopLookup), (s) => s.description.toLowerCase()));
  }, [shopLookup]);

  const toast = useToast();

  const saveShops = async () => {
    const newShops = shops!.filter(isNew);
    const editedShops = shops!.filter(isEdited);
    const isIncomplete = (shop: ShopWithEditedFlags) =>
      !shop.description?.trim();
    if (newShops.find(isIncomplete) || editedShops.find(isIncomplete)) {
      showValidationMessage(toast, "Unvollständige Geschäfte");
      return false;
    }
    newShops.length > 0 &&
      (await axiosInstance.post<Shop[]>(`/api/shop`, newShops));
    editedShops.length > 0 &&
      (await axiosInstance.put<Shop[]>(`/api/shop`, editedShops));
    (newShops.length > 0 || editedShops.length > 0) && (await loadShop());
    props.onSave && props.onSave();
    return true;
  };
  const { pristine, setPristine } = useUnloadAlert();

  const createShop = () => {
    updateShops((shopsDraft) => {
      if (shopsDraft != undefined) {
        shopsDraft.push({
          id: -1,
          description: "",
          priority: 1,
          online: false,
        });
        setPristine(false);
      }
    });
  };
  return (
    <VStack className="stretch-stack">
      {(shops || []).map((shop, index) => {
        const heading = shop.description || "Neues Geschäft";
        const shopUpdater: SingleFieldUpdater<ShopWithEditedFlags> = (k, v) => {
          const updater = (
            shopsDraft: Draft<ShopWithEditedFlags>[] | undefined,
          ) => {
            if (shopsDraft != undefined) {
              const shopsDraftElement = shopsDraft[index];
              shopsDraftElement[k] = v;
              if (!isNew(shopsDraftElement)) {
                shopsDraftElement.edited = true;
              }
            }
          };
          updateShops(updater);
          setPristine(false);
          return produce(shops || [], updater)[index];
        };

        return (
          <Box className="editor-box" key={index}>
            <VStack>
              <HStack width={"100%"} justifyContent={"stretch"}>
                <StringInput
                  width={"100%"}
                  fieldName="description"
                  fieldDescription="Name"
                  value={shop.description}
                  updater={shopUpdater}
                  handleBlur={() => {}}
                />
                <NumberInput
                  fieldName={"priority"}
                  fieldDescription={"Prio"}
                  width={"80px"}
                  value={shop.priority}
                  updater={shopUpdater}
                />
                <Checkbox
                  colorScheme="teal"
                  isChecked={shop.online}
                  width={"fit-content"}
                  icon={<CheckboxIcon as={AtSignIcon} />}
                  size="18px"
                  iconSize="18px"
                  title={"Online"}
                  onChange={(e) => {
                    shopUpdater("online", e.target.checked);
                  }}
                />
              </HStack>
            </VStack>
          </Box>
        );
      })}
      <HStack justifyContent={"center"}>
        <AddButton onClick={createShop} />
        <SaveButton isDisabled={pristine} save={saveShops} />
      </HStack>
    </VStack>
  );
}
