import { isNullIndex } from "./plan-utils";
import { isWithId, WithId } from "../data/lookups";

export type WithDeletedAndEdited = {
  deleted?: boolean;
  edited?: boolean;
};

export function isNew(withId: WithId) {
  return isNullIndex(withId.id);
}

export function isEdited(withEdited: WithDeletedAndEdited | undefined) {
  return withEdited?.edited && (!isWithId(withEdited) || !isNew(withEdited));
}

export function isDeleted(withDeleted: WithDeletedAndEdited | undefined) {
  return (
    withDeleted?.deleted && (!isWithId(withDeleted) || !isNew(withDeleted))
  );
}
