import {
  Plan2PlateDrawer,
  Plan2PlateDrawerHeader,
  usePlan2PlateDisclosure,
} from "./plan2-plate-drawer";
import React, { useContext, useEffect, useState } from "react";
import { SearchInput } from "./search-input";
import { SearchResultCard } from "./search-result-card";
import { Flex, HStack, Tag, VStack } from "@chakra-ui/react";
import { CreateFoodDrawer } from "./create-food-drawer";
import { AddButton } from "./buttons/add-button";
import Food from "../data/food";
import { PurchaseEditor } from "./purchase-editor";
import { initialPurchase, Purchase } from "../data/purchase";
import { updatePurchaseWithFood } from "../utils/purchase-utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { useImmer } from "use-immer";
import { getPerformSearch } from "../utils/search-utils";
import { baseUrl } from "../config";
import { useNutriSlider } from "./nutri-slider";
import {
  DrawerHistoryContext,
  PurchaseLookupWithRefreshContext,
} from "../contexts";
import { formatFoodDescription } from "../utils/food-utils";

export function CreatePurchaseDrawer({
  onClose,
  onSubmit,
  open,
  food,
}: {
  onClose: () => void;
  open: boolean;
  onSubmit: (p: Purchase) => void;
  food?: Food;
}) {
  const drawerHistoryContext = useContext(DrawerHistoryContext);
  const {
    isOpen: isOpenCreateFood,
    onOpen: onOpenCreateFood,
    onClose: onCloseCreateFood,
  } = usePlan2PlateDisclosure(drawerHistoryContext);

  useEffect(() => {
    if (!open) {
      setSelectedFood(food);
      setSearchTerm("");
      updateSearchResults([]);
      setSearchResultCount(0);
    } else {
      setSelectedFood(food);
    }
  }, [open, food]);

  const [selectedFood, setSelectedFood] = useState<Food>();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, updateSearchResults] = useImmer<Food[]>([]);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const { loadPurchase } = useContext(PurchaseLookupWithRefreshContext);

  const nutriSliderProps = useNutriSlider("food");

  const { performSearch, fetchMoreData } = getPerformSearch(
    undefined,
    nutriSliderProps,
    searchTerm,
    searchResults,
    updateSearchResults,
    setSearchResultCount,
    `${baseUrl}/api/food/search`,
  );

  return (
    <>
      <CreateFoodDrawer
        onClose={onCloseCreateFood}
        open={isOpenCreateFood}
        onSubmit={setSelectedFood}
      />
      <Plan2PlateDrawer
        onClose={onClose}
        open={open}
        header={<Plan2PlateDrawerHeader heading={"Produkt erstellen"} />}
        body={
          <>
            {!selectedFood ? (
              <>
                <HStack width={"100%"}>
                  <SearchInput
                    value={searchTerm}
                    performSearch={performSearch}
                    setSearchTerm={setSearchTerm}
                    placeholder={"Suche nach Lebensmittel"}
                  />
                  <AddButton onClick={onOpenCreateFood} />
                </HStack>
                <VStack
                  id={"create-purchase-scroll-stack"}
                  overflow={"auto"}
                  width={"100%"}
                  alignItems={"stretch"}
                >
                  <InfiniteScroll
                    scrollableTarget={"create-purchase-scroll-stack"}
                    dataLength={searchResults.length}
                    next={fetchMoreData}
                    hasMore={searchResults.length < searchResultCount}
                    loader={<h4>Loading...</h4>}
                    style={{ width: "100%" }}
                  >
                    <Flex flexWrap={"wrap"} width={"100%"}>
                      {searchResults.map((searchResult) => (
                        <SearchResultCard
                          key={1}
                          onClick={() => setSelectedFood(searchResult)}
                        >
                          {searchResult.description}
                        </SearchResultCard>
                      ))}
                    </Flex>
                  </InfiniteScroll>
                </VStack>
              </>
            ) : (
              <>
                <Tag width={"fit-content"}>
                  {formatFoodDescription(selectedFood)}
                </Tag>
                <PurchaseEditor
                  initialPurchase={updatePurchaseWithFood(
                    initialPurchase(selectedFood.id),
                    selectedFood,
                  )}
                  isCreate={true}
                  food={selectedFood}
                  afterSave={async (p) => {
                    await loadPurchase();
                    onClose();
                    onSubmit(p);
                  }}
                />
              </>
            )}
          </>
        }
      />
    </>
  );
}
