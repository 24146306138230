import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import {
  formatGermanDateShort,
  formatIsoString,
  today,
  todayForPlan,
} from "../utils/date-utils";
import { ShoppingListRequirement } from "../data/shopping-list-requirement";
import {
  useAvailabilityByPurchaseIdLookupLoader,
  useFoodLookupLoader,
  usePurchaseLookupLoader,
  useShopLookupLoader,
} from "../loaders";
import { useImmer } from "use-immer";
import { NumberInput } from "../components/number-input";
import { Draft, produce } from "immer";
import { Plan2PlateAutcompleteMultiple } from "../components/plan2-plate-autcomplete-multiple";
import {
  entries,
  flatMap,
  groupBy,
  keys,
  map,
  sortBy,
  uniq,
  values,
} from "lodash";
import { Plan2PlateDatepicker } from "../components/plan2-plate-datepicker";
import { ShoppingListCreation } from "../data/shopping-list-creation";
import { useNavigate } from "react-router-dom";
import { mealInds } from "../utils/meal-ind-utils";
import { plan2plateIcons } from "../utils/icon-utils";
import { Plan2PlateAccordionHeading } from "../components/plan2-plate-accordion";
import { axiosInstance } from "../keycloak";
import { AddButton } from "../components/buttons/add-button";
import { SearchForFoodDrawer } from "../components/search-for-food-drawer";
import { useDrawerHistory } from "../components/drawer-history";
import { usePlan2PlateDisclosure } from "../components/plan2-plate-drawer";
import Food from "../data/food";
import {
  DrawerHistoryContext,
  FoodLookupWithRefreshContext,
} from "../contexts";
import { Plan2PlateIcon } from "../components/plan2-plate-icon";
import { SimpleInput } from "../components/simple-input";
import { useUnloadAlert } from "../components/use-unload-alert";
import { LoadingComponent } from "../components/loading-component";
import {
  computePurchaseByDefaultUnitLookup,
  formatPurchaseUnitWithAmountDescription,
  getDefaultPurchaseUnit,
} from "../utils/purchase-utils";

const ShoppingListCreatePage = () => {
  const { setPristine } = useUnloadAlert();

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(todayForPlan());
  const updateStartDate = (startDate: Date) => {
    startDate.setHours(startDate.getHours() + 12, 0, 0);
    startDate.setUTCHours(0, 0, 0);
    setStartDate(startDate);
  };
  const updateEndDate = (endDate: Date) => {
    endDate.setHours(endDate.getHours() + 12, 0, 0);
    endDate.setUTCHours(0, 0, 0);
    setEndDate(endDate);
    setPristine(false);
  };
  const endDateInitial = todayForPlan();
  endDateInitial.setDate(endDateInitial.getDate() - 1);
  const [endDate, setEndDate] = useState(endDateInitial);

  const [startMealInd, setStartMealInd] = useState<string>("F");
  const [endMealInd, setEndMealInd] = useState<string>("A");

  const toast = useToast();
  const isValidTimerangeSelected = endDate.getTime() >= startDate.getTime();
  const loadShoppingListRequirements = useCallback(() => {
    if (isValidTimerangeSelected) {
      const startDateIso = formatIsoString(startDate);
      const endDateIso = formatIsoString(endDate);
      const startMealIndUrlPart = startMealInd
        ? `&startMealInd=${startMealInd}`
        : "";
      const endMealIndUrlPart = endMealInd ? `&endMealInd=${endMealInd}` : "";
      axiosInstance
        .get<
          ShoppingListRequirement[]
        >(`/api/shopping-list-requirement?startDate=${startDateIso}&endDate=${endDateIso}${startMealIndUrlPart}${endMealIndUrlPart}`)
        .then((response) => {
          if (!response.data.length) {
            toast({
              title:
                "Keine einkaufsrelevanten Einträge für den gewählten Zeitraum gefunden",
              status: "info",
              isClosable: true,
            });
          }
          setShoppingListRequirements(response.data);
        });
    }
  }, [startDate, endDate, startMealInd, endMealInd]);

  const [shoppingListRequirements, setShoppingListRequirements] = useImmer<
    ShoppingListRequirement[]
  >([]);
  const [shoppingListRequirementsSorted, setShoppingListRequirementsSorted] =
    useImmer<ShoppingListRequirement[]>([]);
  const [extraRequirements, setExtraRequirements] = useImmer<
    ShoppingListRequirement[]
  >([]);

  useEffect(() => {
    loadShoppingListRequirements();
  }, [loadShoppingListRequirements]);

  const { foodLookup, loadFood } = useFoodLookupLoader();
  const { loadShop, shopLookup } = useShopLookupLoader();
  const { loadPurchase, purchaseLookup } = usePurchaseLookupLoader();
  const { availabilityByPurchaseIdLookup, loadAvailabilities } =
    useAvailabilityByPurchaseIdLookupLoader();

  const loadData = useCallback(async () => {
    await loadFood();
    await loadShop();
    await loadAvailabilities();
    await loadPurchase();
  }, []);

  const purchaseByDefaultUnitLookup =
    computePurchaseByDefaultUnitLookup(purchaseLookup);

  useEffect(() => {
    setShoppingListRequirementsSorted(
      sortBy(
        shoppingListRequirements,
        (s) => purchaseByDefaultUnitLookup[s.purchaseUnitId].description,
      ),
    );
  }, [shoppingListRequirements]);

  const [selectedShops, setSelectedShops] = useImmer<number[]>([]);

  const relevantPurchaseIds = !!keys(purchaseByDefaultUnitLookup).length
    ? shoppingListRequirementsSorted
        .filter((r) => r.amount > 0)
        .map((r) => purchaseByDefaultUnitLookup[r.purchaseUnitId].id)
    : [];
  const relevantAvailabilities = flatMap(
    relevantPurchaseIds,
    (purchaseId) => availabilityByPurchaseIdLookup[purchaseId] || [],
  );
  const availabilityByShopId = groupBy(relevantAvailabilities, (a) => a.shopId);

  const purchaseablePurchaseUnitIds = uniq(
    map(
      flatMap(selectedShops, (s) => availabilityByShopId[s] || []),
      (a) => getDefaultPurchaseUnit(purchaseLookup[a.purchaseId]).id,
    ),
  );

  const missingShoppingListRequirements = shoppingListRequirementsSorted.filter(
    (r) =>
      !purchaseablePurchaseUnitIds.includes(r.purchaseUnitId) && r.amount > 0,
  );

  const submitShoppingList = async () => {
    const request: ShoppingListCreation = {
      selectedShops: selectedShops,
      shoppingListRequirementDtos: shoppingListRequirementsSorted,
    };
    await axiosInstance.post(`/api/shopping-list`, request);
    navigate("/shopping-list");
  };

  const drawerHistoryCtx = useDrawerHistory();
  const { isOpen, onClose, onOpen } = usePlan2PlateDisclosure(drawerHistoryCtx);

  function addRequirement(food: Food) {
    if (food) {
      const requirement: ShoppingListRequirement = {
        amount: 0,
        amountInStock: 0,
        purchaseUnitId: getDefaultPurchaseUnit(
          purchaseLookup[food.defaultPurchaseId],
        ).id,
        mealDetails: [],
      };
      setShoppingListRequirements((reqsDraft) => {
        reqsDraft.push(requirement);
      });
    }
  }

  return (
    <LoadingComponent loadData={[loadData]}>
      <DrawerHistoryContext.Provider value={drawerHistoryCtx}>
        <FoodLookupWithRefreshContext.Provider value={{ foodLookup, loadFood }}>
          <SearchForFoodDrawer
            onClose={onClose}
            open={isOpen}
            selectFood={(food) => {
              addRequirement(food);
            }}
          />
          <VStack className="stack-with-padding" alignItems={"start"}>
            <HStack justifyContent={"flex-start"}>
              <Plan2PlateDatepicker
                date={startDate}
                minDate={today()}
                updateDate={updateStartDate}
                zIndex={4}
              />
              {entries(mealInds)
                .filter(([_, mealIndData]) => mealIndData.stockRelevant)
                .map(([mealInd, mealIndData]) => (
                  <IconButton
                    key={mealInd}
                    borderColor={"teal"}
                    borderWidth={mealInd == startMealInd ? "2px" : undefined}
                    aria-label={"mealInd"}
                    icon={plan2plateIcons[mealIndData.icon]}
                    variant="ghost"
                    onClick={() => {
                      setStartMealInd(mealInd);
                    }}
                  />
                ))}
            </HStack>
            <HStack justifyContent={"flex-start"}>
              <Plan2PlateDatepicker
                date={endDate}
                minDate={today()}
                updateDate={updateEndDate}
                zIndex={3}
              />
              {entries(mealInds)
                .filter(([_, mealIndData]) => mealIndData.stockRelevant)
                .map(([mealInd, mealIndData]) => (
                  <IconButton
                    key={mealInd}
                    borderColor={"teal"}
                    borderWidth={mealInd == endMealInd ? "2px" : undefined}
                    aria-label={"mealInd"}
                    icon={plan2plateIcons[mealIndData.icon]}
                    variant="ghost"
                    onClick={() => {
                      setEndMealInd(mealInd);
                    }}
                  />
                ))}
            </HStack>
            {isValidTimerangeSelected && (
              <>
                <Accordion allowMultiple={true} width={"100%"}>
                  {shoppingListRequirementsSorted.map((req, index) => {
                    const purchase =
                      purchaseByDefaultUnitLookup[req.purchaseUnitId];
                    const defaultPurchaseUnit =
                      getDefaultPurchaseUnit(purchase);
                    const requirementUpdater = (
                      k: "amount",
                      v: ShoppingListRequirement["amount"],
                    ) => {
                      const updater = (
                        requirementsDraft:
                          | Draft<ShoppingListRequirement>[]
                          | undefined,
                      ) => {
                        if (requirementsDraft != undefined) {
                          const requirementsDraftElement =
                            requirementsDraft[index];
                          requirementsDraftElement[k] = v;
                        }
                      };
                      setShoppingListRequirementsSorted(updater);
                      return produce(shoppingListRequirementsSorted, updater)[
                        index
                      ];
                    };
                    const description = formatPurchaseUnitWithAmountDescription(
                      purchase,
                      getDefaultPurchaseUnit(purchase),
                      req.amount,
                    );

                    function formatAmountWithDescription(
                      description: string,
                      amount: number,
                      key: React.Key,
                      disabled: boolean,
                    ) {
                      return (
                        <HStack key={key}>
                          <Text width={"100px"} paddingRight="10px">
                            {description}
                          </Text>
                          <NumberInput
                            fieldDescription={defaultPurchaseUnit.description}
                            width={"120px"}
                            fieldName={"amount"}
                            updater={requirementUpdater}
                            value={amount}
                            disabled={disabled}
                          ></NumberInput>
                        </HStack>
                      );
                    }

                    return (
                      <AccordionItem key={defaultPurchaseUnit.id}>
                        <Plan2PlateAccordionHeading description={description}>
                          <Plan2PlateIcon
                            icon={"delete"}
                            onClick={(e) => {
                              setShoppingListRequirementsSorted((s) => {
                                s.splice(index, 1);
                              });
                              e.preventDefault();
                            }}
                          />
                        </Plan2PlateAccordionHeading>
                        <AccordionPanel motionProps={{ animateOpacity: false }}>
                          <VStack alignItems="start">
                            {req.mealDetails.map((mealDetail) =>
                              formatAmountWithDescription(
                                formatGermanDateShort(mealDetail.mealDate),
                                mealDetail.amount,
                                mealDetail.mealDate,
                                true,
                              ),
                            )}
                            <HStack key={"shop"}>
                              <Text width={"100px"} paddingRight="10px">
                                Zum Kaufen
                              </Text>
                              <SimpleInput
                                caption={defaultPurchaseUnit.description}
                                width={"120px"}
                                type={"number"}
                                onBlur={(e) => {
                                  requirementUpdater(
                                    "amount",
                                    Number(e.target.value || "0"),
                                  );
                                }}
                                value={req.amount}
                              ></SimpleInput>
                            </HStack>
                          </VStack>
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
                  <HStack justifyContent={"center"}>
                    <AddButton onClick={onOpen} />
                  </HStack>
                </Accordion>
              </>
            )}
            {isValidTimerangeSelected && (
              <>
                <Divider borderColor="lightgray" borderWidth="1px" />
                {!!shoppingListRequirements.length && (
                  <Plan2PlateAutcompleteMultiple
                    minWidth={"200"}
                    width="100%"
                    value={values(shopLookup)
                      .filter((s) => selectedShops.includes(s.id))
                      .map((s) => s.description)}
                    placeholder={"Geschäft"}
                    onChange={(shops) => {
                      setSelectedShops(shops.map((s) => s.id));
                    }}
                    getDescription={(s) => s.description}
                    getDisplayString={(s) => {
                      const availabilities = availabilityByShopId[s.id] || [];
                      const missingAvailabilities =
                        missingShoppingListRequirements.filter((r) =>
                          availabilities.find(
                            (a) =>
                              getDefaultPurchaseUnit(
                                purchaseLookup[a.purchaseId],
                              ).id == r.purchaseUnitId,
                          ),
                        );
                      return `${s.description}: ${missingAvailabilities.length}/${relevantPurchaseIds.length}`;
                    }}
                    choices={values(shopLookup)}
                  />
                )}
                {missingShoppingListRequirements.length > 0 && (
                  <Box className="editor-box">
                    <Stack spacing={"4"}>
                      <Text fontSize="md" fontWeight={"bold"}>
                        In ausgewählten Geschäften nicht vorhanden
                      </Text>
                      {missingShoppingListRequirements.map((req) => {
                        const purchase =
                          purchaseByDefaultUnitLookup[req.purchaseUnitId];
                        const content = formatPurchaseUnitWithAmountDescription(
                          purchase,
                          getDefaultPurchaseUnit(purchase),
                          req.amount,
                        );
                        return (
                          <Text key={`missing-${req.purchaseUnitId}`}>
                            {content}
                          </Text>
                        );
                      })}
                    </Stack>
                  </Box>
                )}
                <Button
                  isDisabled={selectedShops.length == 0}
                  onClick={submitShoppingList}
                >
                  Einkaufen
                </Button>
              </>
            )}
          </VStack>
        </FoodLookupWithRefreshContext.Provider>
      </DrawerHistoryContext.Provider>
    </LoadingComponent>
  );
};

export default ShoppingListCreatePage;
