import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { sortBy } from "lodash";
import React, { useEffect } from "react";

type LookupType = {
  id: number;
  description: string;
};

export function Plan2PlateSelect<T extends LookupType>(props: {
  value: T | undefined;
  onChange: (value: T | undefined) => void;
  choices: T[];
  placeholder?: string;
  width?: string;
  getDescription?: (i: T) => string;
  caption?: string;
  withoutSort?: boolean;
  autoselectSingle?: boolean;
}) {
  const getDescription = props.getDescription || ((i: T) => i.description);

  useEffect(() => {
    if (props.autoselectSingle && props.choices.length == 1 && !props.value) {
      props.onChange(props.choices[0]);
    }
  }, [props.autoselectSingle, props.choices]);
  return (
    <FormControl
      marginTop={props.caption != null ? "5px" : undefined}
      variant="floating"
      width={props.width || "fit-content"}
    >
      <Select
        placeholder={props.value ? undefined : props.placeholder}
        value={props.value?.id}
        width={props.width || "130px"}
        onChange={(e) => {
          if (!!e.target.value) {
            const selectedId = Number(e.target.value);
            props.onChange(props.choices.find((c) => c.id == selectedId));
          } else {
            props.onChange(undefined);
          }
        }}
      >
        {(props.withoutSort
          ? props.choices
          : sortBy(props.choices, (c) => getDescription(c))
        )
          .filter((i) => i.id >= 0)
          .map((i) => (
            <option key={i.id} value={i.id}>
              {getDescription(i)}
            </option>
          ))}
      </Select>
      {props.caption != null && (
        <FormLabel padding={"0px"}>{props.caption}</FormLabel>
      )}
    </FormControl>
  );
}
