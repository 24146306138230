import {
  DrawerSelection,
  Plan2PlateDrawer,
  Plan2PlateDrawerHeader,
  usePlan2PlateDisclosure,
} from "./plan2-plate-drawer";
import React, { useContext } from "react";
import { CreateFoodDrawer } from "./create-food-drawer";
import { CreatePurchaseDrawer } from "./create-purchase-drawer";
import { CreateDishDrawer } from "./create-dish-drawer";
import { MealSourceId } from "../data/meal-source";
import { DrawerHistoryContext } from "../contexts";

export function CreateItemDrawer(props: {
  onClose: () => void;
  open: boolean;
  createItem: (e: MealSourceId) => void;
}) {
  const drawerHistoryContext = useContext(DrawerHistoryContext);
  const {
    isOpen: isOpenCreateFood,
    onOpen: onOpenCreateFood,
    onClose: onCloseCreateFood,
  } = usePlan2PlateDisclosure(drawerHistoryContext);
  const {
    isOpen: isOpenCreatePurchase,
    onOpen: onOpenCreatePurchase,
    onClose: onCloseCreatePurchase,
  } = usePlan2PlateDisclosure(drawerHistoryContext);
  const {
    isOpen: isOpenCreateDish,
    onOpen: onOpenCreateDish,
    onClose: onCloseCreateDish,
  } = usePlan2PlateDisclosure(drawerHistoryContext);

  return (
    <>
      <CreateFoodDrawer
        onClose={onCloseCreateFood}
        open={isOpenCreateFood}
        onSubmit={(f) => {
          props.onClose();
          props.createItem({
            id: f.id,
            indicator: "FOOD",
          });
        }}
      />
      <CreatePurchaseDrawer
        onClose={onCloseCreatePurchase}
        open={isOpenCreatePurchase}
        onSubmit={(p) => {
          props.onClose();
          props.createItem({
            id: p.id,
            indicator: "PURCHASE",
          });
        }}
      />
      <CreateDishDrawer
        onClose={onCloseCreateDish}
        open={isOpenCreateDish}
        onSubmit={(d) => {
          props.onClose();
          props.createItem({
            id: d.id!,
            indicator: "DISH",
          });
        }}
      />
      <Plan2PlateDrawer
        onClose={props.onClose}
        open={props.open}
        header={
          <Plan2PlateDrawerHeader heading={"Was möchtest du erstellen"} />
        }
        body={
          <>
            <DrawerSelection
              onClick={onOpenCreateFood}
              icon="food"
              heading={"Lebensmittel"}
              description={
                "Erfasse einzelne Nahrungsmittel, einschließlich fertiger Gerichte, die nicht selbst zubereitet wurden"
              }
            />
            <DrawerSelection
              onClick={onOpenCreatePurchase}
              icon="purchase"
              heading={"Produkt"}
              description={
                "Erfasse konkrete Artikel, die im Supermarkt oder anderen Geschäften erhältlich sind"
              }
            />
            <DrawerSelection
              onClick={onOpenCreateDish}
              icon="dish"
              heading={"Gericht"}
              description={
                "Erstelle ein individuelles Rezept, indem du eine Liste von Lebensmitteln und deren Mengen festlegst"
              }
            />
          </>
        }
      />
    </>
  );
}
