import Food from "../data/food";
import { PurchaseLookup } from "../data/lookups";
import { FoodUnit } from "../data/food-unit";

export function formatFoodDescription(food: Food | undefined) {
  return food
    ? food.vendor
      ? `${food.vendor} ${food.description}`
      : `${food.description}`
    : "";
}

export function getDefaultPurchase(food: Food, purchaseLookup: PurchaseLookup) {
  return purchaseLookup[food.defaultPurchaseId];
}

export function formatFoodUnitWithAmountDescription(
  food: Food,
  foodUnit: FoodUnit,
  amount: number,
) {
  return `${formatFoodDescription(food)} ${amount.toLocaleString()} x ${foodUnit.description}`;
}
