import React, { useContext, useEffect, useState } from "react";
import { PlanMode } from "./search-for-plan-cell-drawer";
import {
  DrawerSelection,
  Plan2PlateDrawer,
  Plan2PlateDrawerHeader,
  usePlan2PlateDisclosure,
} from "./plan2-plate-drawer";
import { PlanCellWithIndex } from "../data/plan";
import { formatPlanCellDescription } from "../utils/plan-utils";
import {
  DrawerHistoryContext,
  MealPlannerContext,
  PersonLookupContext,
  PurchaseLookupContext,
} from "../contexts";
import { EditMealDrawer } from "./edit-meal-drawer";
import { useImmer } from "use-immer";

export function EditPlanCellDrawer(props: {
  planCell: PlanCellWithIndex | undefined;
  onClose: () => void;
  open: boolean;
}) {
  const drawerHistoryContext = useContext(DrawerHistoryContext);
  const { isOpen, onOpen, onClose } =
    usePlan2PlateDisclosure(drawerHistoryContext);
  const personLookup = useContext(PersonLookupContext);
  const [planMode, setPlanMode] = useState<PlanMode>("plan");
  const [planCell, updatePlanCell] = useImmer(props.planCell);
  useEffect(() => {
    updatePlanCell(props.planCell);
  }, [props.planCell]);
  const purchaseLookup = useContext(PurchaseLookupContext);
  const { submitPlanCells } = useContext(MealPlannerContext);

  return (
    <>
      {planCell && (
        <EditMealDrawer
          mode={planMode}
          onClose={onClose}
          open={isOpen}
          onSubmit={props.onClose}
          planCells={[planCell]}
        />
      )}
      <Plan2PlateDrawer
        onClose={props.onClose}
        open={props.open}
        header={
          <Plan2PlateDrawerHeader
            heading={
              planCell
                ? formatPlanCellDescription(planCell?.planCell, purchaseLookup!)
                : ""
            }
          />
        }
        body={
          <>
            {!props.planCell?.planCell.deleted &&
              props.planCell?.planCell?.indicator != "JustIntake" &&
              !props.planCell?.planCell.intakes && (
                <DrawerSelection
                  onClick={() => {
                    setPlanMode("plan");
                    onOpen();
                  }}
                  icon="mealPlan"
                  heading={"Umplanen"}
                  description={"Passe den Ernährungsplan für diesen Eintrag an"}
                />
              )}
            {!props.planCell?.planCell.deleted && (
              <DrawerSelection
                onClick={() => {
                  setPlanMode("intake");
                  onOpen();
                }}
                icon="intake"
                heading={"Verzehren"}
                description={
                  "Erfasse genau, welche Mengen und Einheiten du tatsächlich gegessen hast"
                }
              />
            )}
            <DrawerSelection
              onClick={() => {
                planCell &&
                  submitPlanCells([
                    {
                      ...planCell,
                      planCell: {
                        ...planCell.planCell,
                        deleted: !planCell.planCell.deleted,
                      },
                    },
                  ]);
                props.onClose();
              }}
              icon={planCell?.planCell.deleted ? "undo" : "delete"}
              heading={
                planCell?.planCell.deleted ? "Wiederherstellen" : "Löschen"
              }
              description={
                planCell?.planCell.deleted
                  ? "Stelle diesen Eintrag wieder her, wenn er versehentlich gelöscht wurde oder wenn du ihn erneut planen möchtest"
                  : "Entferne diesen Eintrag aus deinem Plan oder deiner Verzehrhistorie. Ungespeicherte Einträge werden unwiderruflich gelöscht"
              }
            />
          </>
        }
      />
    </>
  );
}
