import React from "react";
import { forwardRef, IconButton } from "@chakra-ui/react";
import { IconButtonProps } from "@chakra-ui/button/dist/icon-button";
import { plan2plateIcons } from "../../utils/icon-utils";

type Plan2PlateIconButtonProps = Omit<IconButtonProps, "icon"> & {
  icon: keyof typeof plan2plateIcons;
};
export const Plan2PlateIconButton = forwardRef(
  (props: Plan2PlateIconButtonProps, ref) => {
    return (
      <IconButton
        ref={ref}
        {...props}
        icon={plan2plateIcons[props.icon]}
        variant={props.variant || "ghost"}
        backgroundColor={
          props.backgroundColor || "var(--chakra-colors-chakra-body-bg)"
        }
        padding={"0"}
      />
    );
  },
);
export type Plan2PlateButtonBaseProps = Pick<
  IconButtonProps,
  "onClick" | "variant" | "isLoading"
>;
