import React from "react";
import { Plan2PlateIconButton } from "./plan2-plate-icon-button";

export const AddButton = (props: { onClick: () => void }) => {
  return (
    <Plan2PlateIconButton
      aria-label={"add"}
      title={"Hinzufügen"}
      icon={"plus"}
      onClick={props.onClick}
    />
  );
};
