import { Availability } from "./data/availability";
import Food from "./data/food";
import { Shop } from "./data/shop";
import { Department } from "./data/department";
import {
  AvailabilityByIdLookup,
  AvailabilityByPurchaseIdLookup,
  DepartmentLookup,
  FoodLookup,
  LookupType,
  PersonDishByDishLookup,
  PersonDishLookup,
  PersonLookup,
  PurchaseLookup,
  ShopLookup,
  StockByFoodUnitIdLookup,
  WithId,
} from "./data/lookups";
import { Person } from "./data/person";
import { useCallback, useState } from "react";
import { entries, fromPairs, groupBy } from "lodash";
import { Stock } from "./data/stock";
import { ShoppingList } from "./data/shopping-list";
import { axiosInstance } from "./keycloak";
import { Purchase } from "./data/purchase";
import { PersonDish } from "./data/dish";
import { DishModification } from "./data/dish-modification";

export const useFoodLoader = (foodId: number) => {
  const [food, setFood] = useState<Food>();
  const fetch = axiosGet<Food>(`/api/food/${foodId}`);
  const loadFood = useCallback(async () => {
    setFood(await fetch());
  }, [setFood]);
  return { food, loadFood };
};

export const useAvailabilityLoader = (foodId: number) => {
  const [availabilities, setAvailabilities] = useState<Availability[]>();
  const fetch = axiosGet<Availability[]>(`/api/availability/food/${foodId}`);
  const loadAvailabilities = useCallback(async () => {
    setAvailabilities(await fetch());
  }, [setAvailabilities]);
  return { availabilities, loadAvailabilities };
};
export const useStockByFoodUnitIdLoader = () => {
  const [stocks, setStocks] = useState<StockByFoodUnitIdLookup>();
  const fetch = axiosGet<Stock[]>(`/api/stock`);
  const loadStocks = useCallback(async () => {
    setStocks(createLookup(await fetch(), (s) => s.foodUnitId));
  }, [setStocks]);
  return { stocks, loadStocks };
};

export const useAvailabilityByPurchaseIdLookupLoader = () => {
  const [availabilityByPurchaseIdLookup, setAvailabilityByPurchaseIdLookup] =
    useState<AvailabilityByPurchaseIdLookup>({});

  const fetch = axiosGet<Availability[]>(`/api/availability`);
  const loadAvailabilities = useCallback(async () => {
    setAvailabilityByPurchaseIdLookup(groupBy(await fetch(), "purchaseId"));
  }, [setAvailabilityByPurchaseIdLookup]);
  return { availabilityByPurchaseIdLookup, loadAvailabilities };
};
export const useAvailabilityByIdLookupLoader = () => {
  const [availabilityByIdLookup, setAvailabilityByIdLookup] =
    useState<AvailabilityByIdLookup>({});

  const fetch = axiosGet<Availability[]>(`/api/availability`);
  const loadAvailabilities = useCallback(async () => {
    setAvailabilityByIdLookup(createByIdLookup(await fetch()));
  }, [setAvailabilityByIdLookup]);
  return { availabilityByIdLookup, loadAvailabilities };
};

export const useShopLookupLoader = () => {
  const [shopLookup, setShopLookup] = useState<ShopLookup>({});
  const fetch = axiosGet<Shop[]>(`/api/shop`);
  const loadShop = useCallback(async () => {
    setShopLookup(createByIdLookup(await fetch()));
  }, [setShopLookup]);
  return { shopLookup, loadShop };
};

export const useDepartmentLoader = () => {
  const [departmentLookup, setDepartmentLookup] = useState<DepartmentLookup>(
    {},
  );
  const fetch = axiosGet<Department[]>(`/api/department`);
  const loadDepartment = useCallback(async () => {
    setDepartmentLookup(createByIdLookup(await fetch()));
  }, [setDepartmentLookup]);
  return { departmentLookup, loadDepartment };
};
export const useFoodLookupLoader = () => {
  const [foodLookup, setFoodLookup] = useState<FoodLookup>({});
  const fetch = axiosGet<Food[]>(`/api/food`);
  const loadFood = useCallback(async () => {
    const foodLookup = createByIdLookup(await fetch());
    setFoodLookup(foodLookup);
    return foodLookup;
  }, [setFoodLookup]);
  return { foodLookup, loadFood };
};
export const usePurchaseLookupLoader = (foodId?: number) => {
  const [purchaseLookup, setPurchaseLookup] = useState<PurchaseLookup>({});
  const queryParams = foodId ? `?foodId=${foodId}` : "";
  const fetch = axiosGet<Purchase[]>(`/api/purchase${queryParams}`);
  const loadPurchase = useCallback(async () => {
    const purchaseLookup = createByIdLookup(await fetch());
    setPurchaseLookup(purchaseLookup);
    return purchaseLookup;
  }, [setPurchaseLookup]);
  return { purchaseLookup, loadPurchase };
};
export const usePersonLoader = (includeInactive: boolean) => {
  const [personLookup, setPersonLookup] = useState<PersonLookup>({});
  const queryParams = includeInactive ? "?inactive=true" : "";
  const fetch = axiosGet<Person[]>(`/api/person${queryParams}`);
  const loadPerson = useCallback(async () => {
    setPersonLookup(createByIdLookup(await fetch()));
  }, [setPersonLookup]);
  return { personLookup, loadPerson };
};
export const usePersonDishLookupLoader = () => {
  const [personDishByDishLookup, setPersonDishByDishLookup] =
    useState<PersonDishByDishLookup>({});
  const [personDishLookup, setPersonDishLookup] = useState<PersonDishLookup>(
    {},
  );
  const fetch = axiosGet<PersonDish[]>(`/api/person-dish`);
  const loadPersonDishByDish = useCallback(async () => {
    const data = await fetch();
    const byDish = createByIdLookup(data);
    const byPerson = fromPairs(
      entries(
        groupBy(data, (personDish) => {
          if (personDish.personRecipeUnitDtos.length == 0) {
            return -1;
          }
          return personDish.personRecipeUnitDtos[0].personId;
        }),
      ).map(([a, b]) => [Number(a), b]),
    ) as unknown as PersonDishLookup;
    setPersonDishByDishLookup(byDish);
    setPersonDishLookup(byPerson);
  }, [setPersonDishByDishLookup, setPersonDishLookup]);
  return { personDishByDishLookup, loadPersonDishByDish, personDishLookup };
};

export const useShoppingListLoader = () => {
  const [shoppingList, setShoppingList] = useState<ShoppingList[]>([]);
  const fetch = axiosGet<ShoppingList[]>(`/api/shopping-list`);
  const loadShoppingList = useCallback(async () => {
    setShoppingList(await fetch());
  }, [setShoppingList]);
  return { shoppingList, loadShoppingList };
};

export const axiosGet = <T>(url: string) => {
  return async () => {
    return (await axiosInstance.get<T>(url)).data;
  };
};

function createLookup<T>(data: T[], getKey: (entity: T) => number) {
  const newLookup: LookupType<T> = {};
  data.forEach((entity) => {
    newLookup[getKey(entity)] = entity;
  });
  return newLookup;
}

function createByIdLookup<T extends WithId>(data: T[]) {
  return createLookup(data, (d) => d.id);
}

export async function loadDishModification(
  dishId: string | number | undefined,
) {
  return (await axiosInstance.get<DishModification>(`/api/dish/${dishId}`))
    .data;
}
