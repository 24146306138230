import { Plan2PlateDrawer, Plan2PlateDrawerHeader } from "./plan2-plate-drawer";
import React, { useContext } from "react";
import { DishEditor } from "./dish-editor";
import {
  AvailabilityByPurchaseIdLookupContext,
  FoodLookupWithRefreshContext,
  PersonLookupContext,
  PurchaseLookupWithRefreshContext,
} from "../contexts";
import { DishModification, emptyDish } from "../data/dish-modification";

export function EditDishDrawer(props: {
  onClose: () => void;
  open: boolean;
  onSubmit: (d: DishModification) => void | Promise<void>;
  dish?: DishModification;
}) {
  const { foodLookup, loadFood } = useContext(FoodLookupWithRefreshContext);
  const { purchaseLookup, loadPurchase } = useContext(
    PurchaseLookupWithRefreshContext,
  );
  const { availabilityByPurchaseIdLookup, loadAvailabilities } = useContext(
    AvailabilityByPurchaseIdLookupContext,
  );
  const personLookup = useContext(PersonLookupContext)!;
  return (
    <Plan2PlateDrawer
      onClose={props.onClose}
      open={props.open}
      header={<Plan2PlateDrawerHeader heading={"Gericht bearbeiten"} />}
      body={
        <DishEditor
          availabilityByPurchaseIdLookup={availabilityByPurchaseIdLookup}
          foodLookup={foodLookup!}
          purchaseLookup={purchaseLookup!}
          initialDish={props.dish || emptyDish(personLookup)}
          isCreate={false}
          loadAvailabilities={loadAvailabilities}
          personLookup={personLookup}
          afterSave={(createdDish: DishModification) => {
            props.onClose();
            props.onSubmit(createdDish);
          }}
        />
      }
    />
  );
}
