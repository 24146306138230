import { createContext } from "react";
import {
  DepartmentLookup,
  FoodLookup,
  PersonDishLookup,
  PersonLookup,
  PurchaseLookup,
} from "./data/lookups";
import {
  useAvailabilityByPurchaseIdLookupLoader,
  useFoodLookupLoader,
  usePersonDishLookupLoader,
  usePurchaseLookupLoader,
  useShopLookupLoader,
} from "./loaders";
import { PlanCell, PlanCellCoordinates, PlanCellWithIndex } from "./data/plan";
import { HistoryState, MealKey } from "./utils/plan-utils";

type WithRefresh<T> = {
  value: T;
  refresh: () => void;
};

export const FoodLookupContext = createContext<FoodLookup | undefined>(
  undefined,
);
export const FoodLookupWithRefreshContext = createContext<
  ReturnType<typeof useFoodLookupLoader>
>({
  foodLookup: {},
  loadFood: async () => {
    return {};
  },
});
export const PurchaseLookupContext = createContext<PurchaseLookup | undefined>(
  undefined,
);
export const PurchaseLookupWithRefreshContext = createContext<
  ReturnType<typeof usePurchaseLookupLoader>
>({
  purchaseLookup: {},
  loadPurchase: async () => {
    return {};
  },
});
export const PersonLookupContext = createContext<PersonLookup | undefined>(
  undefined,
);
export const PersonDishLookupContext = createContext<
  PersonDishLookup | undefined
>(undefined);

export const PersonDishByDishLookupContext = createContext<
  ReturnType<typeof usePersonDishLookupLoader>
>({
  personDishByDishLookup: {},
  loadPersonDishByDish: async () => {},
  personDishLookup: {},
});
export const ShopLookupWithRefreshContext = createContext<
  ReturnType<typeof useShopLookupLoader>
>({
  shopLookup: {},
  loadShop: async () => {},
});
export const DepartmentLookupContext = createContext<
  DepartmentLookup | undefined
>(undefined);
export const AvailabilityByPurchaseIdLookupContext = createContext<
  ReturnType<typeof useAvailabilityByPurchaseIdLookupLoader>
>({
  availabilityByPurchaseIdLookup: {},
  loadAvailabilities: async () => {},
});
export type DrawerHistoryContextType = {
  history: HistoryState[];
  pushHistory: (state: HistoryState) => void;
  popHistory: () => void;
  clearHistory: () => Promise<void>;
  hasHistory: (state: HistoryState["id"]) => boolean;
  setWindowHistoryStackSize: (size: number) => void;
};
const emptyDrawerHistoryContext: DrawerHistoryContextType = {
  history: [],
  pushHistory: () => {},
  popHistory: () => {},
  clearHistory: async () => {},
  hasHistory: () => false,
  setWindowHistoryStackSize: () => {},
};
export const DrawerHistoryContext = createContext<DrawerHistoryContextType>(
  emptyDrawerHistoryContext,
);
export type MealPlannerContextType = {
  submitPlanCells: (planCells: PlanCellWithIndex[]) => void;
  onConsume: (
    date: string,
    mealInd: string,
    mealKey: MealKey | undefined,
    personId: number | undefined,
  ) => void;
  getTableDataForCoordinates: (coords: PlanCellCoordinates) => PlanCell[];
};

export const MealPlannerContext = createContext<MealPlannerContextType>({
  submitPlanCells: () => {},
  onConsume: () => {},
  getTableDataForCoordinates: () => [],
  ...emptyDrawerHistoryContext,
});
