import React from "react";
import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

interface PopoverInfoProps {
  headerText: string;
  bodyContent: React.ReactNode;
}

const PopoverInfo: React.FC<PopoverInfoProps> = ({
  headerText,
  bodyContent,
}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label={headerText + "-info"}
          variant="ghost"
          size="xs"
          icon={<InfoOutlineIcon />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">{headerText}</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <VStack maxH="250px" overflowY="auto" alignItems={"start"}>
            {bodyContent}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverInfo;
