import { NutriValues } from "../data/nutri-values";
import { Box, HStack, Tag, VStack } from "@chakra-ui/react";
import { KcalFormLabel, NutriBar, NutriBullets } from "./nutri-bar";
import React from "react";
import { MealSource } from "../data/meal-source";
import { differenceInDays, parseDate, today } from "../utils/date-utils";
import { Plan2PlateIcon } from "./plan2-plate-icon";

export function DetailSearchResult(props: {
  extraTags?: string[];
  title: string;
  resultType: "Mahlzeit" | "Lebensmittel" | "Gericht" | "Produkt";
  recent: boolean;
  nutriValues?: NutriValues;
  showNumbers: boolean;
  onClick: () => void | Promise<void>;
}) {
  return (
    <Box className="editor-box" onClick={props.onClick}>
      <VStack alignItems={"stretch"} width={"100%"}>
        <Box>{props.title}</Box>
        <HStack overflowX={"auto"}>
          <Tag flexShrink="0">{props.resultType}</Tag>
          {props.recent && <Plan2PlateIcon icon="history" size={"sm"} />}
          {props.extraTags?.map((extraTag, index) => (
            <Tag key={index} flexShrink="0">
              {extraTag}
            </Tag>
          ))}
        </HStack>
        {props.nutriValues && (
          <>
            {props.showNumbers && (
              <KcalFormLabel kcal={props.nutriValues.kcal} />
            )}
            <NutriBar nutriValues={props.nutriValues} />
            {props.showNumbers && (
              <NutriBullets nutriValues={props.nutriValues} factor={100} />
            )}
          </>
        )}
      </VStack>
    </Box>
  );
}

export function isRecent(searchResult: MealSource) {
  return differenceInDays(parseDate(searchResult.lastPlanned), today()) <= 7;
}
