import { VStack } from "@chakra-ui/react";
import React from "react";
import { LoadingComponent } from "../components/loading-component";
import { useShopLookupLoader } from "../loaders";
import { ShopLookupWithRefreshContext } from "../contexts";
import { ShopEditor } from "../components/shop-editor";

const ShopEditPage = () => {
  const { loadShop, shopLookup } = useShopLookupLoader();

  return (
    <LoadingComponent loadData={[loadShop]}>
      <ShopLookupWithRefreshContext.Provider value={{ loadShop, shopLookup }}>
        <VStack className={"stretch-stack-with-padding"}>
          <ShopEditor />
        </VStack>
      </ShopLookupWithRefreshContext.Provider>
    </LoadingComponent>
  );
};

export default ShopEditPage;
