import React from "react";
import { FoodEditor } from "../components/food-editor";
import { useNavigate } from "react-router-dom";
import { useDepartmentLoader, useShopLookupLoader } from "../loaders";
import {
  DepartmentLookupContext,
  ShopLookupWithRefreshContext,
} from "../contexts";
import { LoadingComponent } from "../components/loading-component";
import { initialFood } from "../data/food";
import { VStack } from "@chakra-ui/react";

const FoodCreatePage: React.FC = () => {
  const navigate = useNavigate();

  const { loadShop, shopLookup } = useShopLookupLoader();
  const { departmentLookup, loadDepartment } = useDepartmentLoader();

  return (
    <LoadingComponent loadData={[loadDepartment, loadShop]}>
      <ShopLookupWithRefreshContext.Provider value={{ loadShop, shopLookup }}>
        <DepartmentLookupContext.Provider value={departmentLookup}>
          <VStack className="stretch-stack-with-padding">
            <FoodEditor
              initialFood={initialFood}
              isCreate={true}
              afterSave={(food) => navigate(`/food/edit/${food.id}`)}
            />
          </VStack>
        </DepartmentLookupContext.Provider>
      </ShopLookupWithRefreshContext.Provider>
    </LoadingComponent>
  );
};

export default FoodCreatePage;
