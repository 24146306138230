import React, { useContext, useState } from "react";
import {
  PlanMode,
  SearchForPlanCellDrawer,
} from "./search-for-plan-cell-drawer";
import {
  DrawerSelection,
  Plan2PlateDrawer,
  Plan2PlateDrawerHeader,
  usePlan2PlateDisclosure,
} from "./plan2-plate-drawer";
import { PlanCellCoordinates } from "../data/plan";
import { formatCoordinates } from "../utils/plan-utils";
import { DrawerHistoryContext, PersonLookupContext } from "../contexts";

export function AddPlanCellDrawer(props: {
  planCellCoordinates: PlanCellCoordinates | undefined;
  onClose: () => void;
  open: boolean;
}) {
  const drawerHistoryContext = useContext(DrawerHistoryContext);
  const { isOpen, onOpen, onClose } = usePlan2PlateDisclosure(
    drawerHistoryContext,
    "search-for-plan-cell",
  );
  const personLookup = useContext(PersonLookupContext);
  const [planMode, setPlanMode] = useState<PlanMode>("plan");

  return (
    <>
      {props.planCellCoordinates && (
        <SearchForPlanCellDrawer
          onClose={onClose}
          open={isOpen}
          onSubmit={() => {
            onClose();
            props.onClose();
          }}
          planCellCoordinates={props.planCellCoordinates}
          planMode={planMode}
        />
      )}
      <Plan2PlateDrawer
        onClose={props.onClose}
        open={props.open}
        header={
          <Plan2PlateDrawerHeader
            heading={
              props.planCellCoordinates
                ? `${formatCoordinates(props.planCellCoordinates, personLookup!)}`
                : ""
            }
          />
        }
        body={
          <>
            <DrawerSelection
              onClick={() => {
                setPlanMode("plan");
                onOpen();
              }}
              icon={"mealPlan"}
              heading={"Planen"}
              description={"Erstelle deinen persönlichen Ernährungsplan"}
            />
            <DrawerSelection
              onClick={() => {
                setPlanMode("intake");
                onOpen();
              }}
              icon="intake"
              heading={"Verzehren"}
              description={"Erfasse, was du tatsächlich gegessen hast"}
            />
          </>
        }
      />
    </>
  );
}
