import { Plan2PlateDrawer, Plan2PlateDrawerHeader } from "./plan2-plate-drawer";
import React, { useContext } from "react";
import { FoodEditor } from "./food-editor";
import Food, { initialFood } from "../data/food";
import { FoodLookupWithRefreshContext } from "../contexts";
import { VStack } from "@chakra-ui/react";

export function CreateFoodDrawer(props: {
  onClose: () => void;
  open: boolean;
  onSubmit: (createdFood: Food) => void;
}) {
  const { loadFood } = useContext(FoodLookupWithRefreshContext);
  return (
    <Plan2PlateDrawer
      onClose={props.onClose}
      open={props.open}
      header={<Plan2PlateDrawerHeader heading={"Lebensmittel erstellen"} />}
      body={
        <VStack>
          <FoodEditor
            initialFood={initialFood}
            isCreate
            afterSave={async (createdFood) => {
              await loadFood();
              props.onClose();
              props.onSubmit(createdFood);
            }}
          />
        </VStack>
      }
    />
  );
}
