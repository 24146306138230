import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { colors, fontSizes, modes, otherConsts } from "./tokens";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-20px)",
  width: "max-content",
};

const theme = extendTheme({
  config,
  colors,
  breakpoints: {
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg:
          props.colorMode === "dark"
            ? modes.dark.background
            : modes.light.background,
        color: props.colorMode === "dark" ? modes.dark.text : modes.light.text,
        fontSize: fontSizes.md,
      },
      h1: {
        fontSize: fontSizes["4xl"],
      },
      h2: {
        fontSize: fontSizes["3xl"],
      },
      h3: {
        fontSize: fontSizes["2xl"],
      },
      h4: {
        fontSize: fontSizes.xl,
      },
      // stacks for pages
      ".stack-with-padding": {
        width: "100%",
        maxW: otherConsts.maxW,
        padding: otherConsts.defaultPadding,
      },
      ".stretch-stack": {
        width: "100%",
        maxW: otherConsts.maxW,
        alignItems: "stretch !important",
      },
      ".stretch-stack-with-padding": {
        width: "100%",
        maxW: otherConsts.maxW,
        alignItems: "stretch !important",
        padding: otherConsts.defaultPadding,
      },
      // stack for drawer
      ".stretch-drawer-stack": {
        width: "100%",
        height: "100%",
        maxW: otherConsts.maxW,
        alignItems: "stretch !important",
        justifyContent: "stretch",
      },
      // box for editors
      ".editor-box": {
        width: "100%",
        maxW: otherConsts.maxW,
        borderWidth: "1px",
        borderRadius: "lg",
        boxShadow: "md",
        padding: otherConsts.defaultPadding,
      },
      ".relative-flex-editor-box": {
        width: "100%",
        maxW: otherConsts.maxW,
        borderWidth: "1px",
        borderRadius: "lg",
        boxShadow: "md",
        padding: otherConsts.defaultPadding,
        position: "relative",
        marginTop: "10px",
        display: "flex",
      },
      ".relative-editor-box": {
        width: "100%",
        maxW: otherConsts.maxW,
        borderWidth: "1px",
        borderRadius: "lg",
        boxShadow: "md",
        padding: otherConsts.defaultPadding,
        position: "relative",
      },
      ".relative-editor-box-without-shadow": {
        width: "100%",
        maxW: otherConsts.maxW,
        position: "relative",
      },
      // drawer
      ".plan2plate-drawer": {
        maxW: otherConsts.maxW,
        paddingX: otherConsts.drawerPadding + " !important",
      },
      // scrollbar
      ".chakra-modal__content-container": {
        height: "100dvh !important",
      },
      ".chakra-slide": {
        height: "100dvh !important",
      },
      "::-webkit-scrollbar": {
        display: "none",
      },
      // icons
      ".plan2plate-icon": {
        fill: "none",
        stroke: props.colorMode === "dark" ? modes.dark.text : modes.light.text,
        strokeMiterlimit: "10",
        strokeWidth: "1px",
      },
      ".white-stroke": {
        stroke: "white",
      },
      ".thick-stroke": {
        strokeWidth: "1.5px",
      },
      ".thin-stroke": {
        strokeWidth: "0.8px",
      },
      ".round-linecap": {
        strokeLinecap: "round",
        strokeLinejoin: "round",
      },
      ".square-linecap": {
        strokeLinecap: "square",
      },
      ".icon-text": {
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "bold",
        fontStretch: "normal",
        fontSize: "7px",
        fontFamily: "Arial",
        direction: "rtl",
        fill: props.colorMode === "dark" ? modes.dark.text : modes.light.text,
        strokeWidth: "0.8px",
        strokeLinejoin: "round",
        paintOrder: "stroke fill markers",
      },
      ".filled ": {
        fill:
          props.colorMode === "dark"
            ? modes.light.background
            : modes.dark.background,
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke:
          props.colorMode === "dark"
            ? modes.dark.background
            : modes.light.background,
        strokeDasharray: "none",
        strokeOpacity: 1,
      },
      // progress
      ".chakra-progress__track": {
        stroke:
          (props.colorMode === "dark" ? "#2b3549" : "#edf2f7") + " !important",
      },
    }),
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "var(--chakra-colors-chakra-body-bg)",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
});

export default theme;
