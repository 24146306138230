import { ObjectWithStringSignature } from "./component-types";
import React, {
  ForwardedRef,
  KeyboardEventHandler,
  ReactElement,
  useEffect,
} from "react";
import {
  Box,
  FormControl,
  FormLabel,
  forwardRef,
  Input,
} from "@chakra-ui/react";
import { useImmer } from "use-immer";

export interface Plan2PlateInputProps<
  T extends ObjectWithStringSignature,
  K extends keyof T & string,
  V extends number | string,
> {
  fieldName: K;
  leftAddon?: boolean;
  leftAddonLabel?: string;
  leftAddonMinW?: string;
  rightAddon?: boolean;
  rightAddonLabel?: string;
  rightAddonMinW?: string;
  placeholder?: string;
  value: T[K];
  updater: (fieldname: K, value: V) => T;
  handleBlur?: (newValue: T) => void;
  inputType: V extends string ? "string" : V extends number ? "number" : never;
  valueConverter: (inputValue: string) => V;
  width?: string;
  padding?: string;
  disabled?: boolean;
  valueFormatter?: (value: T[K]) => string;
  isInvalid?: boolean;
  onKeyDown?: KeyboardEventHandler<any>;
}

const Plan2PlateInputInternal = <
  T extends ObjectWithStringSignature,
  K extends keyof T & string,
  V extends number | string,
>(
  {
    fieldName,
    leftAddon,
    leftAddonLabel,
    leftAddonMinW,
    rightAddon,
    rightAddonLabel,
    rightAddonMinW,
    placeholder,
    value,
    updater,
    handleBlur,
    inputType,
    valueConverter,
    width,
    padding,
    disabled,
    ...props
  }: Plan2PlateInputProps<T, K, V>,
  ref: ForwardedRef<any>,
): ReactElement => {
  const valueFormatter = props.valueFormatter || ((v: T[K]) => `${v}`);
  const [currentValue, updateCurrentValue] = useImmer(valueFormatter(value));
  useEffect(() => {
    updateCurrentValue(valueFormatter(value));
  }, [value]);
  const caption =
    leftAddonLabel != null
      ? rightAddonLabel != null
        ? `${leftAddonLabel} (${rightAddonLabel})`
        : leftAddonLabel
      : null;
  return (
    <Box width={width}>
      <FormControl
        marginTop={caption != null ? "5px" : undefined}
        variant="floating"
        width={width || "fit-content"}
      >
        <Input
          ref={ref}
          isInvalid={props.isInvalid}
          width={width}
          disabled={disabled}
          type={inputType}
          name={fieldName}
          placeholder={placeholder}
          value={currentValue || ""}
          onChange={(e) => updateCurrentValue(() => e.target.value)}
          onBlur={() => {
            const updatedValue = valueConverter(currentValue);
            const updatedObj = updater(fieldName, updatedValue);
            updateCurrentValue(valueFormatter(updatedValue as T[K]));
            handleBlur && handleBlur(updatedObj);
          }}
          onFocus={(e) => e.target.select()}
          onKeyDown={props.onKeyDown}
        />
        {caption != null && <FormLabel padding={"0px"}>{caption}</FormLabel>}
      </FormControl>
    </Box>
  );
};

export const Plan2PlateInput = forwardRef(Plan2PlateInputInternal) as <
  T extends ObjectWithStringSignature,
  K extends keyof T & string,
  V extends number | string,
>(
  props: Plan2PlateInputProps<T, K, V> & { ref?: React.Ref<any> },
) => ReactElement;
