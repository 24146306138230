import React from "react";
import { Plan2PlateIconButton } from "./plan2-plate-icon-button";

export const RevertButton = (props: { onClick: () => void }) => {
  return (
    <Plan2PlateIconButton
      aria-label={"undo"}
      title={"Wiederherstellen"}
      icon={"undo"}
      onClick={props.onClick}
    />
  );
};
