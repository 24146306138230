import { PlanCellCoordinates } from "./plan";
import { WithId } from "./lookups";
import { NutriValues } from "./nutri-values";

export type MealSource = {
  extraTags?: string[];
  lastPlanned: string;
  description: string;
} & MealSourceId &
  NutriValues;

export type MealSourceId =
  | ({
      indicator: "FOOD" | "PURCHASE" | "DISH" | "MEAL";
    } & WithId)
  | ({
      indicator: "MEALS_FOR_DAY";
    } & PlanCellCoordinates);

export function computeResultType(mealSource: MealSource) {
  return mealSource.indicator == "DISH"
    ? "Gericht"
    : mealSource.indicator == "PURCHASE"
      ? "Produkt"
      : mealSource.indicator == "MEAL"
        ? "Gericht"
        : mealSource.indicator == "FOOD"
          ? "Lebensmittel"
          : "Mahlzeit";
}

export function getMealSourceKey(searchResult: MealSource) {
  return [
    searchResult.indicator,
    searchResult.indicator == "MEALS_FOR_DAY"
      ? searchResult.description
      : searchResult.id,
  ].join();
}
