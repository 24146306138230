import React from "react";
import { useImmer } from "use-immer";
import { useParams } from "react-router-dom";
import { DishEditor } from "../components/dish-editor";
import { DishModification } from "../data/dish-modification";
import {
  loadDishModification,
  useAvailabilityByPurchaseIdLookupLoader,
  useDepartmentLoader,
  useFoodLookupLoader,
  usePersonLoader,
  usePurchaseLookupLoader,
  useShopLookupLoader,
} from "../loaders";
import {
  DepartmentLookupContext,
  DrawerHistoryContext,
  FoodLookupWithRefreshContext,
  ShopLookupWithRefreshContext,
} from "../contexts";
import { LoadingComponent } from "../components/loading-component";
import { Box } from "@chakra-ui/react";
import { useDrawerHistory } from "../components/drawer-history";

const DishEditPage = () => {
  const { foodLookup, loadFood } = useFoodLookupLoader();
  const { loadPurchase, purchaseLookup } = usePurchaseLookupLoader();
  const { loadPerson, personLookup } = usePersonLoader(true);
  const { availabilityByPurchaseIdLookup, loadAvailabilities } =
    useAvailabilityByPurchaseIdLookupLoader();

  const [dish, updateDish] = useImmer<DishModification | undefined>(undefined);
  const { dishId } = useParams();

  const { loadShop, shopLookup } = useShopLookupLoader();
  const { departmentLookup, loadDepartment } = useDepartmentLoader();

  async function loadDish() {
    const dish = await loadDishModification(dishId);
    updateDish(dish);
  }

  const drawerHistory = useDrawerHistory();

  return (
    <LoadingComponent
      loadData={[
        loadPerson,
        loadFood,
        loadPurchase,
        loadAvailabilities,
        loadShop,
        loadDepartment,
        loadDish,
      ]}
    >
      <ShopLookupWithRefreshContext.Provider value={{ loadShop, shopLookup }}>
        <DepartmentLookupContext.Provider value={departmentLookup}>
          <FoodLookupWithRefreshContext.Provider
            value={{ foodLookup, loadFood }}
          >
            <DrawerHistoryContext.Provider value={drawerHistory}>
              <Box p={4}>
                <DishEditor
                  availabilityByPurchaseIdLookup={
                    availabilityByPurchaseIdLookup
                  }
                  loadAvailabilities={loadAvailabilities}
                  initialDish={dish!}
                  isCreate={false}
                  foodLookup={foodLookup}
                  purchaseLookup={purchaseLookup}
                  personLookup={personLookup}
                />
              </Box>
            </DrawerHistoryContext.Provider>
          </FoodLookupWithRefreshContext.Provider>
        </DepartmentLookupContext.Provider>
      </ShopLookupWithRefreshContext.Provider>
    </LoadingComponent>
  );
};

export default DishEditPage;
