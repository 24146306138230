import { ObjectWithStringSignature } from "../components/component-types";
import { useImmer } from "use-immer";

export type SingleFieldUpdater<T extends ObjectWithStringSignature> = <
  K extends keyof T,
>(
  key: K,
  value: T[K],
) => T;

export function useUpdate<T extends ObjectWithStringSignature>(
  objectToUpdate: T,
) {
  const [editedObject, updateEditedObject] = useImmer<T>(objectToUpdate);

  const updateEditedObjectField = <K extends keyof T>(k: K, v: T[K]) => {
    updateEditedObject((objectToUpdate) => {
      // @ts-ignore
      objectToUpdate[k] = v;
    });
    return {
      ...objectToUpdate,
      [k]: v,
    };
  };

  return { editedObject, updateEditedObjectField, updateEditedObject };
}
