import { plan2plateIcons } from "./icon-utils";

export interface MealInd {
  priorityOrder: number;
  name: string;
  icon: keyof typeof plan2plateIcons;
  stockRelevant: boolean;
}

// priorityOrder: see MealIndUtil.java
export const mealInds: Record<string, MealInd> = {
  F: {
    priorityOrder: 0,
    name: "Frühstück",
    icon: "breakfast",
    stockRelevant: true,
  },
  M: {
    priorityOrder: 1,
    name: "Mittagessen",
    icon: "lunch",
    stockRelevant: true,
  },
  A: {
    priorityOrder: 2,
    name: "Abendessen",
    icon: "dinner",
    stockRelevant: true,
  },
  S: {
    priorityOrder: 3,
    name: "Snack",
    icon: "snack",
    stockRelevant: false,
  },
};

export function compareMealInds(
  a: keyof typeof mealInds,
  b: keyof typeof mealInds,
): number {
  const priorityOrderA = mealInds[a].priorityOrder;
  const priorityOrderB = mealInds[b].priorityOrder;

  if (priorityOrderA < priorityOrderB) {
    return -1;
  } else if (priorityOrderA > priorityOrderB) {
    return 1;
  } else {
    return 0;
  }
}
