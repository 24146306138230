import { Availability } from "./availability";

export interface PurchaseUnit extends PurchaseUnitCreation {
  id: number;
  isDefault: boolean;
}

export interface Purchase extends PurchaseCreation {
  id: number;
  isDefault: boolean;
  purchaseRelevant: boolean;
  availabilities: Availability[];
  purchaseUnits: PurchaseUnit[];
}

export interface PurchaseCreation {
  description: string;
  brand?: string;
  ean?: number;
  fat: number;
  carbs: number;
  protein: number;
  kcal: number;
  foodId: number;
  purchaseUnits: PurchaseUnitCreation[];
}

export interface PurchaseUnitCreation {
  description: string;
  netWeight: number;
  foodUnitId: number;
  isDefault: boolean;
}

export const initialPurchase = (foodId: number): Purchase => {
  return {
    carbs: 0,
    description: "",
    fat: 0,
    foodId,
    id: 0,
    isDefault: false,
    availabilities: [],
    kcal: 0,
    protein: 0,
    purchaseUnits: [],
    purchaseRelevant: false,
  };
};
