import { nutriLookup, NutriValues } from "../data/nutri-values";
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { round } from "mathjs";
import { TypographyProps } from "@chakra-ui/styled-system";

export type NutriComponentProps = {
  name: keyof NutriValues;
  value: number;
  need: number;
  variant: "circular" | "linear";
  fontSize?: TypographyProps["fontSize"];
  width?: string;
};

export const NutriComponent = (props: NutriComponentProps) => {
  const { hasUpperBound, name, color, shortName } = nutriLookup[props.name];
  const needRatio = props.value / props.need;

  const fontWeight = hasUpperBound && needRatio >= 1 ? "700" : undefined;

  const backgroundColor = useColorModeValue("#edf2f7", "#2b3549");
  const labelColor = useColorModeValue("#6e6c6c", "#b5b5b5");

  return props.variant == "circular" ? (
    <VStack width={"100%"} maxW={"68px"} gap={0}>
      <Box fontWeight={fontWeight} fontSize={props.fontSize || "sm"}>
        {name}
      </Box>
      <CircularProgress value={props.value} max={props.need} color={color}>
        <CircularProgressLabel
          fontWeight={fontWeight}
          fontSize={"xs"}
          color={labelColor}
        >
          {round(props.value, 0)}
        </CircularProgressLabel>
      </CircularProgress>
    </VStack>
  ) : (
    <VStack width={"100%"} gap={0} alignItems={"stretch"}>
      <HStack justifyContent={"start"}>
        <Box fontWeight={fontWeight} fontSize={props.fontSize || "sm"}>
          {name}
        </Box>
      </HStack>
      <Box backgroundColor={backgroundColor} height={1.5}>
        <Box
          height={"100%"}
          backgroundColor={color}
          width={`${Math.min(needRatio, 1) * 100}%`}
        ></Box>
      </Box>
      <HStack justifyContent={"end"}>
        <Box
          fontWeight={fontWeight}
          fontSize={props.fontSize || "2xs"}
          color={labelColor}
        >
          {round(props.value, 0)}/{round(props.need, 0)}
        </Box>
      </HStack>
    </VStack>
  );
};
