import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SimpleDish } from "../data/dish";
import { SearchIcon } from "@chakra-ui/icons";
import { SearchResultCard } from "../components/search-result-card";
import InfiniteScroll from "react-infinite-scroll-component";
import { useImmer } from "use-immer";
import { NutriSlider, useNutriSlider } from "../components/nutri-slider";
import { usePersonLoader } from "../loaders";
import { sortBy, values } from "lodash";
import { getPerformSearch } from "../utils/search-utils";
import { AddButton } from "../components/buttons/add-button";

const DishSearchPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, updateSearchResults] = useImmer<SimpleDish[]>([]);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const nutriSliderProps = useNutriSlider("dish");
  const { loadPerson, personLookup } = usePersonLoader(false);

  const loadData = useCallback(async () => {
    await loadPerson();
    await performSearch(true);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const [activePerson, setActivePerson] = useState<number | undefined>(
    undefined,
  );
  const { performSearch, fetchMoreData } = getPerformSearch(
    activePerson,
    nutriSliderProps,
    searchTerm,
    searchResults,
    updateSearchResults,
    setSearchResultCount,
    `/api/dish/search`,
  );

  const navigate = useNavigate();

  const createDish = () => {
    navigate("/dish/create");
  };

  useEffect(() => {
    performSearch(true);
  }, [
    nutriSliderProps.minFat,
    nutriSliderProps.maxFat,
    nutriSliderProps.minCarbs,
    nutriSliderProps.maxCarbs,
    nutriSliderProps.minProtein,
    nutriSliderProps.maxProtein,
    nutriSliderProps.minKcal,
    nutriSliderProps.maxKcal,
  ]);

  const sortedPersons = sortBy(values(personLookup), "name");
  return (
    <VStack className="stretch-stack-with-padding" alignItems={"stretch"}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          type={"search"}
          placeholder="Suche nach Gericht"
          value={searchTerm}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              performSearch(true);
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      {personLookup != undefined && sortedPersons.length > 0 && (
        <Accordion
          width={"100%"}
          onChange={(expandedIndex) => {
            setActivePerson(sortedPersons[expandedIndex as number].id);
            nutriSliderProps.setMinKcal(0);
            nutriSliderProps.setMaxKcal(undefined);
            nutriSliderProps.setMinFat(0);
            nutriSliderProps.setMaxFat(undefined);
            nutriSliderProps.setMinCarbs(0);
            nutriSliderProps.setMaxCarbs(undefined);
            nutriSliderProps.setMinProtein(0);
            nutriSliderProps.setMaxProtein(undefined);
          }}
        >
          {sortedPersons.map((person) => (
            <AccordionItem key={person.id}>
              <AccordionButton>
                <Box as="span" flex={1} textAlign={"left"} fontWeight={"bold"}>
                  {`Nährwerte für ${person.name}`}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel motionProps={{ animateOpacity: false }}>
                <NutriSlider
                  key={person.id}
                  {...nutriSliderProps}
                  personId={person.id}
                />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
      <InfiniteScroll
        dataLength={searchResults.length}
        next={fetchMoreData}
        hasMore={searchResults.length < searchResultCount}
        loader={<h4>Loading...</h4>}
        style={{ width: "100%" }}
      >
        <Flex flexWrap={"wrap"} width={"100%"}>
          {searchResults.map((dish) => (
            <SearchResultCard
              key={dish.id}
              onClick={() => navigate(`/dish/edit/${dish.id}`)}
            >
              {dish.description}
            </SearchResultCard>
          ))}
        </Flex>
      </InfiniteScroll>
      <HStack justifyContent={"center"}>
        <AddButton onClick={createDish} />
      </HStack>
    </VStack>
  );
};

export default DishSearchPage;
