import React, { ReactElement } from "react";
import { ObjectWithStringSignature } from "./component-types";
import { NumberInput, NumberInputProps } from "./number-input";
import Food, { BaseUnit } from "../data/food";

type NumericKeys<T> = keyof { [K in keyof T]: T[K] extends number ? K : never };
export type FieldUnit =
  | `${`${`${"g" | "kcal"}/100` | ""}${ReturnType<typeof getLowerCaseBaseUnit>}`}`
  | "%"
  | "kcal";

export function getLowerCaseBaseUnit(baseUnit: BaseUnit) {
  return baseUnit == "G" ? "g" : "ml";
}

export function getFieldUnit(editedFood: Food, unit: "g" | "kcal"): FieldUnit {
  const base = getLowerCaseBaseUnit(editedFood.baseUnit);
  return `${unit}/100${base}`;
}

export type NutritionInputProps<
  T extends ObjectWithStringSignature,
  K extends NumericKeys<T> & string,
> = Omit<
  NumberInputProps<T, K>,
  "leftAddon" | "rightAddon" | "precision" | "width"
> & {
  fieldUnit?: FieldUnit;
};

export const NutritionInput = <
  T extends ObjectWithStringSignature,
  K extends NumericKeys<T> & string,
>(
  props: NutritionInputProps<T, K>,
): ReactElement => {
  return (
    <NumberInput
      {...{
        ...props,
        value: (props.value || 0) as T[K],
        precision: 2,
        width: "95px",
      }}
    />
  );
};
