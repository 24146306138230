import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { usePersonLoader } from "../loaders";
import { values } from "lodash";
import { SearchResultCard } from "../components/search-result-card";
import { useNavigate } from "react-router-dom";
import { LoadingComponent } from "../components/loading-component";

export const PersonSearchPage = () => {
  const { loadPerson, personLookup } = usePersonLoader(true);
  const navigate = useNavigate();

  function editPerson(personId: number): void {
    navigate(`/person/edit/${personId}`);
  }

  const inactivePersons = values(personLookup)
    .filter((p) => !p.active)
    .map((person) => (
      <SearchResultCard key={person.id} onClick={() => editPerson(person.id)}>
        {person.name}
      </SearchResultCard>
    ));
  return (
    <LoadingComponent loadData={[loadPerson]}>
      <VStack className="stack-with-padding">
        <Flex flexWrap={"wrap"} width={"100%"}>
          {values(personLookup)
            .filter((p) => p.active)
            .map((person) => (
              <SearchResultCard
                key={person.id}
                onClick={() => editPerson(person.id)}
              >
                {person.name}
              </SearchResultCard>
            ))}
        </Flex>
        {inactivePersons.length && (
          <Accordion defaultIndex={[]} allowToggle={true} width={"100%"}>
            <AccordionItem>
              <AccordionButton>
                <Box as="span" flex={1} textAlign={"left"} fontWeight={"bold"}>
                  {"Inaktive Personen"}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel motionProps={{ animateOpacity: false }}>
                <Flex flexWrap={"wrap"} width={"100%"}>
                  {inactivePersons}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )}
      </VStack>
    </LoadingComponent>
  );
};
